import React, { Component } from "react";
import {
  MDBBtn,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBModal,
  toast,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBInput,
} from "mdbreact";
import axios from "../../../../axios";
import { DateInput } from "semantic-ui-calendar-react";
import { withTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
import MessagesData from "../../../../components/VenueMgmt/Messages/Datatables/MessagesData";
import Bandwidth from "../../../../components/VenueMgmt/Bandwidth/Bandwidth";
import SubscriberKeypoolsData from "../../../../components/NetworkMgmt/Subscribers/Datatables/SubscriberKeypoolsData";
import KeyManage from "../../../../components/SubscriberMgmt/KeyInformation/KeyManage";
import SubscriberTransactionsData from "../../../../components/NetworkMgmt/Subscribers/Datatables/SubscriberTransactionsData";
import AddKey from "../../../../components/NetworkMgmt/Forms/AddKey/AddKey";
import ManageDevicesData from "../../../../components/SubscriberMgmt/ManageDevices/Datatables/ManageDevicesData";
import DeviceDetails from "../../../../components/SubscriberMgmt/DeviceInformation/DeviceDetails";
import parse from "html-react-parser";
import SelectSearch, { fuzzySearch } from "react-select-search";
import countryInfo from "country-locale-map";
import getSymbolFromCurrency from "currency-symbol-map";
import _ from "lodash";
import brandsData from "../../../../brands/common/brands.json";
import JSONPretty from "react-json-pretty";
class ModifyResident extends Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayChangeEnd = this.handleDayChangeEnd.bind(this);
    this.toggleCheckboxValueSMS = this.toggleCheckboxValueSMS.bind(this);
    this.state = {
      Email: "",
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      phoneNumber: this.props.phoneNumber,
      selectedLanguage: this.props.language,
      selectedBuilding: this.props.building,
      selectedFloor: this.props.floor,
      floor: this.props.floor,
      building: this.props.building,
      receivetext: this.props.receivetext,
      receivephone: this.props.receivephone,
      dateStart:
        this.props.startDate !== null
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(this.props.startDate))
          : "",
      oldDateStart: this.props.startDate,
      dateEnd:
        this.props.endDate !== ""
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(this.props.endDate))
          : undefined,
      oldDateEnd:
        this.props.endDate !== ""
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(this.props.endDate))
          : undefined,
      unitID: this.props.unitID,
      unitID_internal: this.props.unitID_internal,
      recentResidents: this.props.recentResidents,
      venueID: this.props.venueID,
      loading: false,
      loadingDelete: false,
      loadingMessages: true,
      ssid: this.props.SSID,
      activeItem: "1",
      activeItemRefund: "1",
      selectedServicePlan: this.props.servicePlan,
      loadingResend: false,
      availablePlans: this.props.availablePlans,
      vlan: this.props.vlan,
      status: this.props.status,
      languageOptions: [
        { value: "en-us", name: "English" },
        { value: "es-xl", name: "Spanish" },
      ],
      managedBySSO: false,
      subscriberManagedviaSSO: this.props.subscriberManagedviaSSO,
      realpageSyncDate: this.props.realpageSyncDate,
      paymentFailed: this.props.paymentFailed,
      StripeClientSecret: this.props.StripeClientSecret,
      stripeClientParentSecret: this.props.stripeClientParentSecret,
      other: false,
      reason: [],
      radio: [],
      subscriberToken: this.props.subscriberToken,
      RealPageUnitID: this.props.RealPageUnitID,
      modalRefund: false,
      currencyCode: countryInfo.getCurrencyByAlpha3(this.props.country),
      subscriptionDiscount: -Math.abs(this.props.subscriptionDiscount) / 100,
      venuePriceAdjustment:
        getSymbolFromCurrency(
          countryInfo.getCurrencyByAlpha3(this.props.country)
        ) + (-Math.abs(this.props.venueDiscount) / 100).toFixed(2),
      venuePriceAdjustmentNumber: this.props.venueDiscount,
      contactpref_sms: this.props.contactpref_sms,
      contactpref_email: this.props.contactpref_email,
      dateAdded: this.props.dateAdded,
      selectedServicePlanPrice: this.props.selectedServicePlanPrice,
      selectedServicePlanPriceNumber: (
        this.props.selectedServicePlanPrice / 100
      ).toFixed(2),
      setupIntentID: this.props.setupIntentID,
      refundAmount: 0,
      transactions: [],
      subcriberWifiPassword: ".",
      password: "."
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }
  componentDidMount() {
    this.getVenueDetails(this.props.venueID);
    this.getMessages(this.props.venueID);
    this.getSubscriberKeys();
    this.getTransactions();
    this.getLogs();
  }
  validateEmail = (email) => {
    this.setState({ loading: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: this.props.venueID,
    };
    axios
      .post("/venuemgmtconsole/subscribers/validateEmailAddress", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          firstName: response.data.GivenName,
          lastName: response.data.FamilyName,
          phoneNumber: response.data["PhoneNumber.E164"],
          selectedLanguage: response.data.locale,
          invalidPhone: undefined,
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ error: true });
        this.setState({ loading: false });
      });
  };
  getVenueDetails = (index) => {
    sessionStorage.setItem("venueIDSubscriber", index);
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({
      unitData: undefined,
      expiringData: undefined,
      searchData: undefined,
      value: "",
    });
    axios
      .post("/venuemgmtconsole/venues/listVenueDetails", body, {
        headers: {},
      })
      .then((response) => {
        var sortedKeys = _(response.data[index]["Keypools"])
          .map(function (v, k) {
            // insert the key into the object
            return _.merge({}, v, { key: k });
          })
          .sortBy("DisplayOrder") // sort by name
          .value();
        this.setState({
          unitData:
            response.data[index]["Units-Building-Floor-List-Subscribers"],
          venueServicePlans: response.data[index]["AvailableServicePlans"],
          availablePlans: response.data[index]["AvailableServicePlans"],
          keyPoolList: sortedKeys,
          venueName: response.data[index]["VenueName"],
          vlan: response.data[index]["vlan"],
          // venuePriceAdjustment: getSymbolFromCurrency(this.state.currencyCode) + (response.data[index]["ShoppingCart"]["ShoppingCartPriceAdjustment." + this.state.currencyCode] / 100).toFixed(2),
          // venuePriceAdjustmentNumber: response.data[index]["ShoppingCart"]["ShoppingCartPriceAdjustment." + this.state.currencyCode]
        });
        let units = Object.keys(
          response.data[index]["Units-Building-Floor-List-Subscribers"]
        );
        let buildings = [];
        let objUnits = {};
        // for (let i = 0; i < units.length; i++) {
        // 	buildings.push(Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]]));
        // 	// console.log(buildings)
        // 	for (let j = 0; j < buildings[i].length; j++) {
        // 		// console.log(buildings[j])
        // 		let floor = Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]]);
        // 		for (let k = 0; k < floor.length; k++) {
        // 			objUnits[i] = response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i]];
        // 		}
        // 	}
        // }
        for (let i = 0; i < units.length; i++) {
          buildings.push(
            Object.keys(
              response.data[index]["Units-Building-Floor-List-Subscribers"][
                units[i]
              ]
            )
          );
          for (let j = 0; j < buildings[i].length; j++) {
            let floor = Object.keys(
              response.data[index]["Units-Building-Floor-List-Subscribers"][
                units[i]
              ][buildings[i][j]]
            );
            for (let k = 0; k < floor.length; k++) {
              // console.log(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["Subscriptions"])
              // 				if (
              // 					response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["PrimaryUnitID_internal"] === ""
              // 				  &&
              // 				  (
              // 				  (
              // 					  (
              // 					response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["allowOverlappingSubscriptionWiredUnit"]===false
              // 					  )
              // 					  &&
              // 					  (
              // 						Object.keys(response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["Subscriptions"]).length === 0
              // 					  )
              // 				  )
              // 				  ||
              // 				  (
              // 					response.data[index]["Units-Building-Floor-List-Subscribers"][units[i]][buildings[i][j]][floor[k]]["allowOverlappingSubscriptionWiredUnit"]===true
              // 				  )
              // 				  )
              //   )
              // 				{
              objUnits[i] =
                response.data[index]["Units-Building-Floor-List-Subscribers"][
                  units[i]
                ][buildings[i]];
              //   }
            }
          }
        }
        let optionTemplateUnits = [];
        Object.keys(objUnits).map((oneKey, i) => {
          optionTemplateUnits.push({
            value: units[oneKey],
            name: units[oneKey],
          });
          return true;
        });
        this.setState({
          selectedUnit: this.state.selectedUnit,
          optionTemplateBuilding: [],
          // selectedBuilding: undefined,
          // selectedFloor: undefined
        });
        let optionTemplateBuilding = [];
        this.setState({
          optionTemplateBuilding: [],
        });
        Object.keys(
          response.data[index]["Units-Building-Floor-List-Subscribers"][
            this.state.unitID
          ]
        ).map((oneKey, i) => {
          if (oneKey === "DEFAULT") {
            optionTemplateBuilding.push({ value: oneKey, name: oneKey });
          } else {
            optionTemplateBuilding.push({ value: oneKey, name: oneKey });
          }
          return true;
        });
        this.setState({
          optionTemplateBuilding: optionTemplateBuilding,
        });
        let optionTemplateFloor = [];
        Object.keys(
          response.data[index]["Units-Building-Floor-List-Subscribers"][
            this.state.unitID
          ][this.state.building]
        ).map((oneKey, i) => {
          if (oneKey === "DEFAULT") {
            optionTemplateFloor.push({ value: oneKey, name: oneKey });
          } else {
            optionTemplateFloor.push({ value: oneKey, name: oneKey });
          }
          return true;
        });
        this.setState({
          optionTemplateFloor: optionTemplateFloor,
        });
        let optionTemplateServicePlan = [];
        let currentBillingCycleRecurrance;
        let currentBillingCycleHours;
        Object.keys(response.data[index]["AvailableServicePlans"]).map(
          (oneKey, i) => {
            if (
              this.props.servicePlanID ===
              response.data[index]["AvailableServicePlans"][oneKey][
                "ServicePlanID"
              ]
            ) {
              currentBillingCycleRecurrance =
                response.data[index]["AvailableServicePlans"][oneKey][
                  "BillingCycleRecurrence"
                ];
              currentBillingCycleHours =
                response.data[index]["AvailableServicePlans"][oneKey][
                  "BillingCycleHours"
                ];
            }
            return true;
          }
        );
        Object.keys(response.data[index]["AvailableServicePlans"]).map(
          (oneKey, i) => {
            if (
              this.props.servicePlanID ===
              response.data[index]["AvailableServicePlans"][oneKey][
                "ServicePlanID"
              ]
            ) {
              this.setState({
                selectedServicePlanIndex: oneKey,
                // selectedServicePlanPrice: getSymbolFromCurrency(this.state.currencyCode) + ((response.data[index]["AvailableServicePlans"][
                // 	oneKey
                // ]["BillingCyclePrice." + this.state.currencyCode]) ).toFixed(2),
                // selectedServicePlanPriceNumber: (((response.data[index]["AvailableServicePlans"][
                // 	oneKey
                // ]["BillingCyclePrice." + this.state.currencyCode])) / 100).toFixed(2),
                // netBillingPrice: ((response.data[index]["AvailableServicePlans"][
                // 	oneKey
                // ]["BillingCyclePrice." + this.state.currencyCode]) / 100 - (response.data[index]["ShoppingCart"]["ShoppingCartPriceAdjustment." + this.state.currencyCode]) / 100 - (this.state.subscriptionDiscount) / 100).toFixed(2),
                selectedServicePlanID: this.props.servicePlanID,
                netBillingPrice: (
                  (this.props.selectedServicePlanPrice +
                    this.props.venueDiscount +
                    this.state.subscriptionDiscount * 100) /
                  100
                ).toFixed(2),
              });
              currentBillingCycleRecurrance =
                response.data[index]["AvailableServicePlans"][oneKey][
                  "BillingCycleRecurrence"
                ];
            }
            if (
              currentBillingCycleRecurrance ===
                response.data[index]["AvailableServicePlans"][oneKey][
                  "BillingCycleRecurrence"
                ] &&
              currentBillingCycleHours ===
                response.data[index]["AvailableServicePlans"][oneKey][
                  "BillingCycleHours"
                ]
            ) {
              optionTemplateServicePlan.push({
                value: oneKey,
                name: response.data[index]["AvailableServicePlans"][oneKey][
                  "ServicePlanName"
                ],
              });
            }
            return true;
          }
        );
        if (optionTemplateServicePlan.length === 0) {
          Object.keys(response.data[index]["AvailableServicePlans"]).map(
            (oneKey, i) => {
              optionTemplateServicePlan.push({
                value: oneKey,
                name: response.data[index]["AvailableServicePlans"][oneKey][
                  "ServicePlanName"
                ],
              });
            }
          );
        }
        this.setState({
          optionTemplateUnits: optionTemplateUnits,
          optionTemplateServicePlan: optionTemplateServicePlan,
        });
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  };
  handleSelectChangeUnit = (value) => {
    this.setState({
      selectedUnit: value,
      optionTemplateBuilding: [],
      selectedBuilding: undefined,
      selectedFloor: undefined,
      building: undefined,
      floor: undefined,
      updatedUnit: true,
    });
    let optionTemplateBuilding = [];
    this.setState({
      optionTemplateBuilding: [],
    });
    Object.keys(this.state.unitData[value]).map((oneKey, i) => {
      if (oneKey === "DEFAULT") {
        optionTemplateBuilding.push({ value: oneKey, name: oneKey });
      } else {
        optionTemplateBuilding.push({ value: oneKey, name: oneKey });
      }
      return true;
    });
    this.setState({
      optionTemplateBuilding: optionTemplateBuilding,
    });
  };
  handleSelectChangeBuilding = (value) => {
    if (value !== null) {
      this.setState({
        selectedBuilding: value,
      });
      let optionTemplateFloor = [];
      Object.keys(this.state.unitData[this.state.selectedUnit][value]).map(
        (oneKey, i) => {
          if (oneKey === "DEFAULT") {
            optionTemplateFloor.push({ value: oneKey, name: oneKey });
          } else {
            optionTemplateFloor.push({ value: oneKey, name: oneKey });
          }
          return true;
        }
      );
      this.setState({
        optionTemplateFloor: optionTemplateFloor,
      });
    }
  };
  handleSelectChangeFloor = (value) => {
    if (value !== null) {
      this.setState({ selectedFloor: value });
      this.setState({
        unitID_internal:
          this.state.unitData[this.state.selectedUnit][
            this.state.selectedBuilding
          ][value]["UnitID_internal"],
      });
    }
  };
  getMessages = (index) => {
    this.setState({ messages: undefined });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      EmailAddress: this.state.email,
    };
    axios
      .post("/venuemgmtconsole/subscribers/listMessages", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          messages: response.data.messages,
          loadingMessages: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
      });
    if (this.state.messages !== undefined) {
      this.setState({ loadingMessages: false });
    }
  };
  getSubscriberKeys = () => {
    this.setState({ subscriberKeys: undefined });
    if (window.location.pathname === "/networkdashboard") {
      let body = {
        AccessToken: sessionStorage.getItem("AccessToken"),
        EmailAddress: this.state.email,
      };
      axios
        .post("/networkoperatorconsole/subscribers/listSubscriberKeys", body, {
          headers: {},
        })
        .then((response) => {
          var sortedKeys = _(response.data.KeysInKeyPools)
            .map(function (v, k) {
              // insert the key into the object
              return _.merge({}, v, { key: k });
            })
            .sortBy("DisplayOrder") // sort by name
            .value();
          this.setState({
            subscriberKeys: sortedKeys,
            subscriberToken: response.data.SubscriberToken,
            loadingKeys: false,
            managedBySSO: response.data.SubscriberManagedviaSSO,
            devices: response.data.AllDevices,
            cognitoStatus: response.data.CognitoStatus,
            status: response.data.Status,
            emailVerified: response.data.email_verified,
            phoneVerified: response.data.phone_number_verified,
            externalSubscriberID: response.data.externalSubscriberID,
            stripeCustomerID: response.data.StripeCustomerID,
            subscriberManagedviaSSO: response.data.SubscriberManagedviaSSO,
            contactpref_sms: response.data.contactpref_sms,
            contactpref_email: response.data.contactpref_email,
            dateAdded: response.data["dateAddedUTC.ISO8601"],
          });
        })
        .catch((error) => {
          this.setState({
            error: true,
          });
        });
    }
  };
  getTransactions = () => {
    this.setState({
      // transactions: undefined,
    });
    if (this.state.StripeClientSecret !== "") {
      let body = {
        AccessToken: sessionStorage.getItem("AccessToken"),
        EmailAddress: this.state.email,
        VenueID: sessionStorage.getItem("venueID"),
      };
      axios
        .post("/venuemgmtconsole/subscribers/listPayments", body, {
          headers: {},
        })
        .then((response) => {
          setTimeout(() => {
            if (response.data.PaymentCount !== 0) {
              this.setState({
                mostRecentInvoice:
                  response.data.Payments[response.data.PaymentCount - 1][
                    "InvoiceDateTimeUTC.ISO8601"
                  ],
                invoiceDateRefund:
                  response.data.Payments[response.data.PaymentCount - 1][
                    "InvoiceDateTimeUTC.ISO8601"
                  ],
              });
            }
            this.setState({
              transactions: response.data.Payments,
            });
          }, 1000);
          let billing_cycle_end;
          let fixedBillingCycleHours = 0;
          const d = new Date(
            response.data.Payments[response.data.PaymentCount - 1][
              "InvoiceDateTimeUTC.ISO8601"
            ]
          );
          if (
            response.data.Payments[response.data.PaymentCount - 1][
              "BillingCycleHours"
            ] > 671
          ) {
            // console.log(response.data.Payments[(response.data.PaymentCount - 1)]["BillingCycleHours"])
            billing_cycle_end = new Date(
              d.getFullYear(),
              d.getMonth() + 1,
              d.getDate(),
              d.getHours(),
              d.getMinutes(),
              d.getSeconds(),
              d.getMilliseconds()
            );
            fixedBillingCycleHours =
              (billing_cycle_end.getTime() - d.getTime()) / (1000 * 60 * 60);
            // console.log(fixedBillingCycleHours)
          } else {
            fixedBillingCycleHours =
              response.data.Payments[response.data.PaymentCount - 1][
                "BillingCycleHours"
              ];
            billing_cycle_end = new Date(
              d.getTime() +
                response.data.Payments[response.data.PaymentCount - 1][
                  "BillingCycleHours"
                ] *
                  60 *
                  60 *
                  1000
            );
          }
          this.setState({
            billingCycleEnd: billing_cycle_end,
          });
          this.setState({
            // refundAmount: (response.data.Payments[(response.data.PaymentCount - 1)]["AmountProcessed.USD"] / 100).toFixed(2),
            refundAmountMax: (
              response.data.Payments[response.data.PaymentCount - 1][
                "AmountProcessed.USD"
              ] / 100
            ).toFixed(2),
            currencySymbol:
              response.data.Payments[response.data.PaymentCount - 1][
                "CurrencySymbol"
              ],
            currency:
              response.data.Payments[response.data.PaymentCount - 1][
                "Currency"
              ],
            invoiceDate:
              response.data.Payments[response.data.PaymentCount - 1][
                "InvoiceDateTimeUTC.ISO8601"
              ],
            billingCycleHours: fixedBillingCycleHours,
            stripeClientSecret:
              response.data.Payments[response.data.PaymentCount - 1][
                "StripePaymentIntentId"
              ],
            endRequestDate: undefined,
          });
        })
        .catch((error) => {
          this.setState({ error: true });
        });
    }
  };
  getLogs = () => {
    if (window.location.pathname === "/networkdashboard") {
      let body = {
        AccessToken: sessionStorage.getItem("AccessToken"),
        EmailAddress: this.state.email,
      };
      axios
        .post("/networkoperatorconsole/subscribers/listSubscriberLog", body, {
          headers: {},
        })
        .then((response) => {
          let json;
          this.setState({
            subscriberLog: response.data.log,
          });
        })
        .catch((error) => {
          this.setState({ error: true });
        });
    }
  };
  toggleConfirmRemoveKey = (key) => {
    this.setState({
      modalConfirmRemoveKey: !this.state.modalConfirmRemoveKey,
    });
  };
  removeKeyConfirm = (key, keynetwork) => {
    this.toggleConfirmRemoveKey();
    this.setState({
      toggleConfirmRemoveKey: true,
      ConfirmRemoveKey: !this.state.modalConfirmRemoveKey,
      keySSID: this.state.subscriberKeys[key]["ssid"],
      keytag: this.state.subscriberKeys[key][keynetwork]["KeyTag"],
      keyID: keynetwork,
    });
  };
  closeShowWifiPassword = () => {
    this.setState({ modalShowWifiPassword: !this.state.modalShowWifiPassword });
  };
  toggleShowWifiPassword = (key, keynetwork) => {
    this.setState({
      key: key,
      modalShowWifiPassword: !this.state.modalShowWifiPassword,
      subcriberWifiPassword: this.state.subscriberKeys[key][keynetwork]["psk"],
      keyID: keynetwork,
    });
  };
  toggleConfirmShowWifiPassword = (key, keynetwork) => {
    this.setState({
      modalConfirmShowWifiPassword: !this.state.modalConfirmShowWifiPassword,
    });
  };
  toggleChangeWifiPassword = () => {
   this.setState({
    modalChangeWifiPassword: !this.state.modalChangeWifiPassword,
   })
  }
  openChangeWifiPassword = (key, keynetwork, ssidName, keyTag, keyID) => {
   console.log(key)
   if (this.state.subscriberKeys && this.state.subscriberKeys[key] && this.state.subscriberKeys[key][keynetwork]) {
    this.setState({
      keyID: keyID,
      modalChangeWifiPassword: !this.state.modalChangeWifiPassword,
      ssidName: ssidName,
      subcriberWifiPassword: this.state.subscriberKeys[key][keynetwork]["psk"],
      keyID: keynetwork,
    });
  } else {
    // Handle the case where the data is missing. 
    // You could display an error message, log it, or take other actions.
    console.error("Subscriber keys are not available for this key/network."); 
  }
  };
  validateForm() {
    if(this.state.password!==null){
          	return this.state.password.split(".")[0].length >= 1;
          }
  }
  setPassword = (password, token) => {
    let scrubbedPassword = password.replace(/\./g, "");
    console.log("🚀 ~ file: KeyInformation.js:245 ~ KeyInformation ~ newpassword:", scrubbedPassword)
    this.setState({ password: scrubbedPassword });
    this.setState({ token: token });
  };
  submitEditPassword = () => {
    const { t } = this.props;
    if (this.state.password !== null) {
      let body = {
        AccessToken: sessionStorage.getItem("AccessToken"),
        CurrentKeyID: this.state.keyID,
        UserPartForNewKey: this.state.password.split(".")[0],
      };
      axios
        .post("/subscriberconsole/resetKey", body, {
          headers: {},
        })
        .then((response) => {
          this.setState({
            modalEditPassword: !this.state.modalEditPassword,
          });
          toast.success(t("profiledetails.passwordupdated"), {
            closeButton: false,
          });
          this.getSubscriberKeys(
            this.state.email
          );
        })
        .catch((error) => {
          this.setState({ error: true });
          toast.success(t("profiledetails.passwordnotupdated"), {
            closeButton: false,
          });
        });
    } else {
      toast.error(
        "We encountered an error. Please reload the page and try again.",
        {
          closeButton: false,
        }
      );
    }
  };
  showWifiPasswordConfirm = (key, keynetwork) => {
    this.toggleConfirmShowWifiPassword();
  };
  submitRemoveKey = (event) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      SubscriberToken: this.state.subscriberToken,
      KeyID: this.state.keyID,
    };
    this.setState({ loadingRemoveKey: true });
    axios
      .post("/networkoperatorconsole/subscribers/removeKey", body, {
        headers: {},
      })
      .then((response) => {
        toast.success("Removed Key!", {
          closeButton: false,
        });
        this.toggleConfirmRemoveKey();
        this.getSubscriberKeys();
        this.setState({ loadingRemoveKey: false });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.setState({ loadingRemoveKey: false });
      });
  };
  toggleAddKey = (key) => {
    this.setState({
      toggleAddKey: true,
      modal: !this.state.modal,
    });
  };
  submitAddKey = (keypoolID, unitID_internal) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: this.state.email,
      KeyPoolID: keypoolID,
      GivenName: this.state.firstName,
      FamilyName: this.state.lastName,
      "PhoneNumber.E164": this.state.phoneNumber,
      VenueID: this.state.venueID,
      PolicyforSubscriberKey: {
        UnitID_internal: unitID_internal,
      },
    };
    this.setState({ loadingAddKey: true });
    axios
      .post("/networkoperatorconsole/subscribers/addKey", body, {
        headers: {},
      })
      .then((response) => {
        toast.success("Added Key!", {
          closeButton: false,
        });
        this.toggleAddKey();
        this.getSubscriberKeys();
        this.setState({ loadingAddKey: false });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: true,
        });
        this.setState({ loadingAddKey: false });
      });
  };
  handleSelectChangeServicePlan = (event, { name, value }) => {
    this.setState({
      selectedServicePlanID: this.state.availablePlans[value]["ServicePlanID"],
      selectedServicePlanName: name,
      selectedServicePlanIndex: value,
      selectedServicePlanPrice:
        getSymbolFromCurrency(this.state.currencyCode) +
        (
          this.state.availablePlans[value][
            "BillingCyclePrice." + this.state.currencyCode
          ] / 100
        ).toFixed(2),
      netBillingPrice: (
        (this.state.availablePlans[value][
          "BillingCyclePrice." + this.state.currencyCode
        ] +
          this.state.venuePriceAdjustmentNumber +
          this.state.subscriptionDiscount * 100) /
        100
      ).toFixed(2),
      selectedServicePlanPriceNumber: (
        this.state.availablePlans[value][
          "BillingCyclePrice." + this.state.currencyCode
        ] / 100
      ).toFixed(2),
    });
  };
  handleSelectChangeLanguage = (value) => {
    this.setState({ selectedLanguage: value });
  };
  changeHandlerSubscriptionDiscount = (event) => {
    this.setState({
      subscriptionDiscount: -Math.abs(event.target.value).toFixed(2),
    });
    this.setState({
      netBillingPrice: (
        (this.state.selectedServicePlanPriceNumber * 100 +
          this.state.venuePriceAdjustmentNumber +
          (-Math.abs(event.target.value)).toFixed(2) * 100) /
        100
      ).toFixed(2),
    });
  };
  submitHandler = (event) => {
    let isUpdated;
    let isUpdatedServicePlan;
    let isUpdatedStartDate;
    let isUpdatedEndDate;
    let isUpdatedSubscriptionDiscount;
    event.preventDefault();
    event.target.className += " was-validated";
    $("#selectLanguage").removeClass("is-invalid");
    if (this.state.selectedLanguage === undefined) {
      this.setState({
        invalidLanguage: "Please provide a valid language",
      });
      $("#selectLanguage").addClass("is-invalid");
    } else {
      $("#selectLanguage").addClass("is-valid");
    }
    $("#selectServicePlan").removeClass("is-invalid");
    if (this.state.selectedServicePlanID === undefined) {
      this.setState({
        invalidLanguage: "Please provide a valid service plan",
      });
      $("#selectServicePlan").addClass("is-invalid");
    } else {
      $("#selectServicePlan").addClass("is-valid");
    }
    $("#selectBuilding").removeClass("is-invalid");
    if (this.state.selectedBuilding === undefined) {
      this.setState({
        invalidLanguage: "Please select a Building",
      });
      $("#selectBuilding").addClass("is-invalid");
    } else {
      $("#selectBuilding").addClass("is-valid");
    }
    $("#selectFloor").removeClass("is-invalid");
    if (this.state.selectedFloor === undefined) {
      this.setState({
        invalidLanguage: "Please select a Floor",
      });
      $("#selectFloor").addClass("is-invalid");
    } else {
      $("#selectFloor").addClass("is-valid");
    }
    if (
      this.state.firstName !== this.props.firstName ||
      this.state.lastName !== this.props.lastName ||
      this.state.phoneNumber !== this.props.phoneNumber ||
      this.state.selectedLanguage !== this.props.language ||
      this.state.email !== this.props.email ||
      this.state.contactpref_email !== this.props.contactpref_email ||
      this.state.contactpref_sms !== this.props.contactpref_sms
    ) {
      isUpdated = true;
    }
    if (this.state.selectedServicePlanID !== this.props.servicePlanID) {
      isUpdatedServicePlan = true;
    }
    if (
      this.state.subscriptionDiscount * 100 !==
      -Math.abs(this.props.subscriptionDiscount)
    ) {
      isUpdatedSubscriptionDiscount = true;
    }
    if (
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(this.state.oldDateStart)) !== this.state.dateStart
    ) {
      isUpdatedStartDate = true;
    }
    if (this.state.oldDateEnd !== this.state.dateEnd) {
      isUpdatedEndDate = true;
    }
    if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.selectedLanguage !== undefined &&
      this.state.selectedServicePlanID !== undefined &&
      this.state.selectedBuilding !== undefined &&
      this.state.selectedFloor !== undefined
    ) {
      this.props.submitModify(
        this.state.email,
        this.state.firstName,
        this.state.lastName,
        this.state.phoneNumber,
        this.state.selectedLanguage,
        this.state.contactpref_email,
        this.state.contactpref_sms,
        this.state.recentResidents,
        this.state.dateStart,
        this.state.oldDateStart,
        this.state.dateEnd,
        this.state.oldDateEnd,
        this.state.venueID,
        this.state.unitID,
        this.state.selectedBuilding,
        this.state.selectedFloor,
        this.state.selectedServicePlanID,
        isUpdated,
        isUpdatedServicePlan,
        isUpdatedStartDate,
        isUpdatedEndDate,
        isUpdatedSubscriptionDiscount,
        this.state.unitID_internal,
        this.state.selectedServicePlanName,
        this.state.subscriberToken,
        this.state.updatedUnit,
        this.state.subscriptionDiscount,
        this.state.setupIntentID
      );
    }
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  changeHandlerPhone = (event) => {
    this.setState({ phoneNumber: event });
  };
  toggleCheckboxValueSMS = () => {
    this.setState({ contactpref_sms: !this.state.contactpref_sms });
  };
  toggleCheckboxValueEmail = () => {
    this.setState({ contactpref_email: !this.state.contactpref_email });
  };
  createMask = () => {
    this.setState({
      phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
    });
  };
  setEndDate = (dateStart) => {
    var endDateYear = new Date(dateStart).getFullYear() + 1;
    var endDate = new Date(dateStart).setFullYear(endDateYear);
    if (dateStart !== null) {
      this.setState({
        dateEnd: new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(endDate)),
      });
    }
  };
  handleDayChange(dateStart, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    this.setState({
      dateStart,
      isEmpty: !input.value.trim(),
      isDisabled: modifiers.disabled === true,
    });
  }
  handleDayChangeEnd(dateEnd, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    this.setState({
      dateEnd,
      isEmpty: !input.value.trim(),
      isDisabled: modifiers.disabled === true,
    });
  }
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  toggleConfirmDeletion = (key) => {
    this.setState({
      toggleConfirm: true,
      modal: !this.state.modal,
    });
  };
  submitDelete = (event) => {
    let cancellationReason = [];
    this.state.radio.length === 0
      ? (cancellationReason = this.state.reason)
      : cancellationReason.push(this.state.radio);
    cancellationReason = cancellationReason.filter((item) => item !== "other");
    if (
      this.state.reason.includes("other") ||
      this.state.radio.includes("other")
    ) {
      cancellationReason.push("Other: " + this.state.otherSpecify);
    }
    if (cancellationReason.length === 0) {
      this.setState({
        cancelErrorMessage: "Please select a reason for cancellation",
      });
    } else {
      this.setState({ cancelErrorMessage: "" });
      this.props.submitDelete(
        this.state.email,
        this.state.venueID,
        this.state.unitID,
        this.state.building,
        this.state.floor,
        this.state.recentResidents,
        this.state.oldDateStart,
        this.state.unitID_internal,
        cancellationReason,
        this.state.refundAmount * 100
      );
    }
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      modalMessage: !this.state.modalMessage,
    });
  };
  toggleMessageSend = () => {
    this.setState({
      modalMessageSend: !this.state.modalMessageSend,
      subject: undefined,
      emailMessage: undefined,
    });
  };
  toggleMessage = (key) => {
    this.setState({
      modalMessage: !this.state.modalMessage,
      messageID: key,
    });
  };
  submitMessage = () => {
    this.setState({ loadingMessages: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      UnitID_internal: this.state.unitID_internal,
      EmailAddress: this.state.email,
      Subject: this.state.subject,
      Message: this.state.emailMessage,
    };
    axios
      .post("/venuemgmtconsole/subscribers/sendMessage", body, {
        headers: {},
      })
      .then((response) => {
        toast.success("Message Sent!", {
          closeButton: false,
        });
        this.setState({
          modalMessageSend: !this.state.modalMessageSend,
        });
        this.getMessages(this.props.venueID);
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.setState({ error: true });
      });
  };
  handleEditorChange(emailMessage, editor) {
    this.setState({ emailMessage });
  }
  toggleTab = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };
  toggleTabRefund = (tabRefund) => () => {
    if (this.state.activeItemRefund !== tabRefund) {
      this.setState({
        activeItemRefund: tabRefund,
      });
    }
  };
  toggleResendActivation = () => {
    this.setState({
      modalResendActivation: !this.state.modalResendActivation,
    });
  };
  submitHandlerResendActivationSMS = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: this.state.email,
      VenueID: this.props.venueID,
      UserTimeZone: new Date().getTimezoneOffset(),
      NotificationChannel: "SMS",
    };
    this.setState({ loadingResendSMS: true });
    axios
      .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Resent Confirmation!", {
            closeButton: false,
          });
          this.setState({
            loadingResendSMS: false,
            modalResendActivation: false,
          });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.setState({
            loadingResendSMS: false,
            modalResendActivation: false,
          });
        }
      );
  };
  submitHandlerResendActivationEmail = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: this.state.email,
      VenueID: this.props.venueID,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({ loadingResend: true });
    axios
      .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Resent Confirmation!", {
            closeButton: false,
          });
          this.setState({
            loadingResend: false,
            modalResendActivation: false,
          });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.setState({
            loadingResend: false,
            modalResendActivation: false,
          });
        }
      );
  };
  toggleCancel = () => {
    this.setState({
      modalCancel: !this.state.modalCancel,
    });
  };
  changeHandlerCheckbox = (event) => {
    this.setState({ [event.target.name]: !this.state[event.target.name] });
    if (!this.state[event.target.name] === true) {
      this.setState((prevState) => ({
        reason: [...prevState.reason, event.target.name],
      }));
    }
  };
  changeHandlerRadio = (nr) => () => {
    this.setState({
      radio: nr,
    });
  };
  changeHandlerOtherSpecify = (event) => {
    this.setState({ otherSpecify: event.target.value });
  };
  deviceDetails = (device) => {
    this.setState({
      modalDeviceDetails: !this.state.modalDeviceDetails,
      deviceDetails: device,
    });
  };
  toggleDeviceDetailsClose = () => {
    this.setState({
      modalDeviceDetails: !this.state.modalDeviceDetails,
    });
  };
  toggleRefund = (
    refundAmount,
    refundAmountMax,
    currencySymbol,
    currency,
    invoiceDateRefund,
    billingCycleHours,
    stripeClientSecret,
    stripeClientParentSecret,
    amountAlreadyRefunded
  ) => {
   // console.log(stripeClientParentSecret)
    let billing_cycle_end;
    let fixedBillingCycleHours = 0;
    const d = new Date(invoiceDateRefund);
    if (billingCycleHours > 671) {
      billing_cycle_end = new Date(
        d.getFullYear(),
        d.getMonth() + 1,
        d.getDate(),
        d.getHours(),
        d.getMinutes(),
        d.getSeconds(),
        d.getMilliseconds()
      );
      fixedBillingCycleHours =
        (billing_cycle_end.getTime() - d.getTime()) / (1000 * 60 * 60);
    } else {
      fixedBillingCycleHours = billingCycleHours;
      billing_cycle_end = new Date(
        d.getTime() + billingCycleHours * 60 * 60 * 1000
      );
    }
    this.setState({
      billingCycleEnd: billing_cycle_end,
    });
    this.setState({
      modalRefund: !this.state.modalRefund,
      refundAmount: (refundAmount / 100).toFixed(2),
      refundAmountMax: (refundAmountMax / 100).toFixed(2),
      currencySymbol: currencySymbol,
      currency: currency,
      invoiceDateRefund: invoiceDateRefund,
      billingCycleHours: fixedBillingCycleHours,
      stripeClientSecret: stripeClientSecret,
      stripeClientParentSecret: stripeClientParentSecret,
      endRequestDate: undefined,
    });
  };
  changeHandlerRefundEndDate = (event, { name, value }) => {
    this.setState({ endRequestDate: value });
    let pricehr =
      parseInt(this.state.refundAmountMax) / this.state.billingCycleHours;
    if (
      new Date(value).getTime() <
      new Date(this.state.invoiceDateRefund).getTime()
    ) {
      value = new Date(this.state.invoiceDateRefund).getTime();
    }
    let hours_remaining = Math.floor(
      (this.state.billingCycleEnd.getTime() - new Date(value).getTime()) /
        (1000 * 60 * 60)
    );
    let amount_to_refund = hours_remaining * pricehr;
    if (amount_to_refund > this.state.refundAmountMax) {
      this.setState({
        refundAmount: this.state.refundAmountMax,
      });
    } else {
      this.setState({
        refundAmount: amount_to_refund.toFixed(2),
      });
    }
  };
  submitRefund = () => {
    this.setState({
      submittingRefund: true,
    });
    $("#refundAmount").removeClass("is-invalid");
    if (
      parseInt(this.state.refundAmount) > parseInt(this.state.refundAmountMax)
    ) {
      this.setState({
        invalidRefund: "Refund cannot be more than amount paid",
      });
      $("#refundAmount").addClass("is-invalid");
      $("#refundAmount").removeClass("is-valid");
    } else {
      $("#refundAmount").addClass("is-valid");
    }
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      StripeClientSecret: this.state.stripeClientSecret,
      Currency: this.state.currency,
      RefundAmount: this.state.refundAmount * 100,
      Reason: this.state.refundMemo,
    };
    let api;
    if ($(".invalid-feedback").is(":visible") !== true) {
      if (window.location.pathname === "/networkdashboard") {
        api = "/networkoperatorconsole/subscribers/refundPayment";
      } else {
        api = "/venuemgmtconsole/subscribers/refundPayment";
      }
      axios
        .post(api, body, {
          headers: {},
        })
        .then(
          (response) => {
            toast.success("Refund Submitted!", {
              closeButton: false,
            });
            this.setState({ loadingResend: false });
            this.toggleRefund();
            this.getTransactions();
            this.setState({
              submittingRefund: false,
            });
          },
          (error) => {
            toast.error("There was an error!", {
              closeButton: false,
            });
            this.setState({ loadingResend: false });
            this.toggleRefund();
            this.setState({
              submittingRefund: false,
            });
          }
        );
    }
  };
  submitCredit = () => {
    this.setState({
      submittingCredit: true,
    });
    $("#refundAmount").removeClass("is-invalid");
    if (
      parseInt(this.state.refundAmount) > parseInt(this.state.refundAmountMax)
    ) {
      this.setState({
        invalidRefund: "Credit cannot be more than amount paid",
      });
      $("#refundAmount").addClass("is-invalid");
      $("#refundAmount").removeClass("is-valid");
    } else {
      $("#refundAmount").addClass("is-valid");
    }
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      StripeClientSecret: this.state.stripeClientParentSecret,
      NewBillingCyclePriceAdjustment: this.state.refundAmount * 100,
      Reason: this.state.refundMemo,
    };
    let api;
    if ($(".invalid-feedback").is(":visible") !== true) {
      if (window.location.pathname === "/networkdashboard") {
        api = "/networkoperatorconsole/subscribers/applyOneTimeCredit";
      } else {
        api = "/networkoperatorconsole/subscribers/applyOneTimeCredit";
      }
      axios
        .post(api, body, {
          headers: {},
        })
        .then(
          (response) => {
            toast.success("Credit Submitted!", {
              closeButton: false,
            });
            this.setState({ loadingResend: false });
            this.toggleRefund();
            this.getTransactions();
            this.setState({
              submittingCredit: false,
            });
          },
          (error) => {
            toast.error("There was an error!", {
              closeButton: false,
            });
            this.setState({ loadingResend: false });
            this.toggleRefund();
            this.setState({
              submittingCredit: false,
            });
          }
        );
    }
  };
  copySubscriberToken = () => {
    var copyText = document.getElementById("subscriberTokenHidden");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    this.setState({ copyText: "Copied" });
    textArea.remove();
    toast.success("Subscriber Token copied", {
      closeButton: false,
    });
  };
  copyExternalSubscriberID = () => {
    var copyText = document.getElementById("externalSubscriberIDHidden");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    this.setState({ copyText: "Copied" });
    textArea.remove();
    toast.success("External Subscriber ID copied", {
      closeButton: false,
    });
  };
  copyStripeCustomerID = () => {
    var copyText = document.getElementById("stripeCustomerIDHidden");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    this.setState({ copyText: "Copied" });
    textArea.remove();
    toast.success("Stripe Customer ID copied", {
      closeButton: false,
    });
  };
  render() {
    sessionStorage.setItem(
      "stripeClientSecretSubscriber",
      this.props.StripeClientSecret
    );
    sessionStorage.setItem(
      "unitID_internalSubscriber",
      this.props.unitID_internal
    );
    sessionStorage.setItem("servicePlanIDSubscriber", this.props.servicePlanID);
    sessionStorage.setItem("newServicePlanID", this.props.servicePlanID);
    sessionStorage.setItem("emailSubscriber", this.props.email);
    sessionStorage.setItem("dateStart", this.props.startDate);
    const { t } = this.props;
    const FORMAT = "MM/dd/yyyy";
    let showUpdate;
    let cancelInputType;
    let showTransactions;
    brandsData.forEach(
      ({
        name,
        url,
        cancel_input_type,
        phone_number,
        flag_update,
        flag_showTransactions,
      }) => {
        if (url.includes(window.location.hostname)) {
          showUpdate = flag_update;
          cancelInputType = cancel_input_type;
          showTransactions = flag_showTransactions;
        }
      }
    );
    return (
      <>
        <MDBModal
          isOpen={this.state.modalDeviceDetails}
          toggle={this.toggle}
          size="lg"
          className="form"
        >
          <DeviceDetails
            toggleDeviceDetails={this.toggleDeviceDetailsClose}
            deviceDetails={this.state.deviceDetails}
            listKeys={this.props.listKeys}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalCancel} toggle={this.toggleCancel}>
          <MDBModalHeader toggle={this.toggle}>Cancel Service</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer className="Form" fluid></MDBContainer>
          </MDBModalBody>
        </MDBModal>
        {this.state.toggleConfirm ? (
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader toggle={this.toggle}>
              Confirm Deletion
            </MDBModalHeader>
            <MDBModalBody>
              <p>Name: {this.state.firstName + " " + this.state.lastName}</p>
              <p>Email: {this.state.email}</p>
              <p>Unit: {this.state.unitID}</p>
              <p>Building: {this.state.building}</p>
              <p>Floor: {this.state.floor}</p>
              <>
                <div className="FormContainer">
                  <div className="loginDisclosure">
                    {showTransactions &&
                    window.location.pathname === "/networkdashboard" ? (
                      <>
                        <MDBRow>
                          <MDBCol lg="6" sm="12">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              Last Invoice Date
                            </label>
                            <DateInput
                              dateFormat="MM/DD/YYYY"
                              name="endRequestDate"
                              placeholder="MM/DD/YYYY"
                              initialDate={
                                new Date(this.state.mostRecentInvoice)
                              }
                              value={new Date(this.state.mostRecentInvoice)}
                              iconPosition="left"
                              onChange={this.changeHandlerRefundEndDate}
                              className="dateTimePicker"
                              maxDate={new Date(this.state.billingCycleEnd)}
                              minDate={new Date(this.state.invoiceDate)}
                              closable={true}
                              disabled={true}
                            />
                          </MDBCol>
                          <MDBCol lg="6" sm="12">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              Refund Date (price calculated from this date)
                            </label>
                            <DateInput
                              dateFormat="MM/DD/YYYY"
                              name="endRequestDate"
                              placeholder="MM/DD/YYYY"
                              initialDate={new Date(this.state.billingCycleEnd)}
                              value={new Date(this.state.endRequestDate)}
                              iconPosition="left"
                              onChange={this.changeHandlerRefundEndDate}
                              className="dateTimePicker"
                              maxDate={new Date(this.state.billingCycleEnd)}
                              minDate={new Date(this.state.invoiceDate)}
                              closable={true}
                              disabled={
                                this.state.transactions.length === 0
                                  ? true
                                  : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <br />
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Refund Amount
                        </label>
                        <br />
                        <span className="currencySymbol">
                          {this.state.currencySymbol}
                        </span>
                        <input
                          type="number"
                          id="refundAmount"
                          name="refundAmount"
                          className="form-control refundAmount"
                          value={
                            this.state.refundAmount === 0
                              ? this.state.refundAmount.toFixed(2)
                              : this.state.refundAmount
                          }
                          min="0"
                          max={this.state.refundAmountMax}
                          onChange={this.changeHandler}
                          step=".01"
                          disabled={
                            this.state.transactions.length === 0 ? true : false
                          }
                        />
                        <div className="invalid-feedback">
                          Refund amount cannot be more than paid amount
                        </div>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <p className="pb-0 mb-0">Reason for Deletion:</p>
										{cancelInputType === "checkbox" ? (
                      <>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              required
                              label="Moving"
                              type="checkbox"
                              id="moving"
                              name="Moving"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.moving}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Price"
                              type="checkbox"
                              id="price"
                              name="Price"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.price}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Poor customer service"
                              type="checkbox"
                              id="poorCustomerService"
                              name="Poor Customer Service"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.poorCustomerService}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Received a competitor promo"
                              type="checkbox"
                              id="receivedPromo"
                              name="Received Promo"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.receivedPromo}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="My promo expired"
                              type="checkbox"
                              id="promoExpired"
                              name="Promo Expired"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.promoExpired}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Reliability"
                              type="checkbox"
                              id="reliability"
                              name="Reliability"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.reliability}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Speeds"
                              type="checkbox"
                              id="speeds"
                              name="Speeds"
                              onChange={this.changeHandlerCheckbox}
                              checked={this.state.speeds}
                            />
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      <>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Moving"
                              type="radio"
                              id="moving"
                              name="Moving"
                              onChange={this.changeHandlerRadio("Moving")}
                              checked={
                                this.state.radio === "Moving" ? true : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Price"
                              type="radio"
                              id="price"
                              name="Price"
                              onChange={this.changeHandlerRadio("Price")}
                              checked={
                                this.state.radio === "Price" ? true : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Poor customer service"
                              type="radio"
                              id="poorCustomerService"
                              name="Poor Customer Service"
                              onChange={this.changeHandlerRadio(
                                "Poor Customer Service"
                              )}
                              checked={
                                this.state.radio === "Poor Customer Service"
                                  ? true
                                  : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Received a competitor promo"
                              type="radio"
                              id="receivedPromo"
                              name="Received Promo"
                              onChange={this.changeHandlerRadio(
                                "Received Promo"
                              )}
                              checked={
                                this.state.radio === "Received Promo"
                                  ? true
                                  : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="My promo expired"
                              type="radio"
                              id="promoExpired"
                              name="Promo Expired"
                              onChange={this.changeHandlerRadio(
                                "Promo Expired"
                              )}
                              checked={
                                this.state.radio === "Promo Expired"
                                  ? true
                                  : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Reliability"
                              type="radio"
                              id="reliability"
                              name="Reliability"
                              onChange={this.changeHandlerRadio("Reliability")}
                              checked={
                                this.state.radio === "Reliability"
                                  ? true
                                  : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol col="12" className="leftAlign agree">
                            <MDBInput
                              label="Speeds"
                              type="radio"
                              id="speeds"
                              name="Speeds"
                              onChange={this.changeHandlerRadio("Speeds")}
                              checked={
                                this.state.radio === "Speeds" ? true : false
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </div>
                </div>
              </>
              <div className="error-message">
                {this.state.cancelErrorMessage}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.submitDelete}>
                Confirm
              </MDBBtn>
              <MDBBtn color="primary" onClick={this.toggle}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : null}
        <MDBModal
          isOpen={this.state.modalResendActivation}
          toggle={this.toggle}
        >
          <MDBModalHeader toggle={this.toggleResendActivation}>
            Resend Activation
          </MDBModalHeader>
          <MDBModalBody>
            <MDBBtn
              className="float-right"
              color="primary"
              onClick={this.submitHandlerResendActivationSMS}
              disabled={!this.state.contactpref_sms}
            >
              {this.state.loadingResendSMS === true ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Resend by SMS"
              )}
            </MDBBtn>
            <MDBBtn
              className="float-right"
              color="primary"
              onClick={this.submitHandlerResendActivationEmail}
              disabled={!this.state.contactpref_email}
            >
              {this.state.loadingResend === true ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Resend by Email"
              )}
            </MDBBtn>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalConfirmRemoveKey}
          toggle={this.toggle}
        >
          <MDBModalHeader toggle={this.toggleConfirmRemoveKey}>
            Confirm Removal of Key
          </MDBModalHeader>
          <MDBModalBody>
            <p>SSID: {this.state.keySSID}</p>
            <p>Keytag: {this.state.keytag}</p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.submitRemoveKey}>
              {this.state.loadingRemoveKey === true ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Confirm"
              )}
            </MDBBtn>
            <MDBBtn color="primary" onClick={this.toggleConfirmRemoveKey}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalShowWifiPassword}
          toggle={this.closeShowWifiPassword}
        >
          <MDBModalBody>
            <p>
              You are about to view a user's Wi-Fi password. This event will be
              logged and reviewed.
            </p>
            <MDBBtn
              color="primary"
              onClick={this.toggleConfirmShowWifiPassword}
            >
              Show Password
            </MDBBtn>
            <MDBBtn color="primary" onClick={this.closeShowWifiPassword}>
              Close
            </MDBBtn>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.modalConfirmShowWifiPassword}
          toggle={this.toggle}
        >
          <MDBModalHeader toggle={this.toggleConfirmShowWifiPassword}>
            Wi-Fi Password
          </MDBModalHeader>
          <MDBModalBody>
            <p id="password">{this.state.subcriberWifiPassword}</p>
          </MDBModalBody>
        </MDBModal>
				{this.state.toggleAddKey ? (
        <MDBModal
						isOpen={this.state.modal}
						toggle={this.toggle}
          size="lg"
					>
            <AddKey
              keyPoolList={this.state.keyPoolList}
              venueName={this.state.venueName}
              venueID={this.state.venueID}
              email={this.state.email}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              phoneNumber={this.state.phoneNumber}
              UnitID_internal={this.props.unitID_internal}
              unitID={this.state.unitID}
              building={this.state.building}
              floor={this.state.floor}
              toggleAddKey={this.toggleAddKey}
              submitAddKey={this.submitAddKey}
              loadingAddKey={this.state.loadingAddKey}
            />
          </MDBModal>
        ) : null}
        {this.state.messageID !== undefined ? (
          <MDBModal
            isOpen={this.state.modalMessage}
            toggle={this.toggle}
            centered
          >
            <MDBModalHeader toggle={this.toggle}>Messages</MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="messageSubject">
                <MDBCol>
                  <p>Subject:</p>
                  {this.state.messages[this.state.messageID]["Subject"]}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <p>Message:</p>
                  {parse(this.state.messages[this.state.messageID]["Message"])}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        ) : (
          ""
        )}
        <MDBModal
          isOpen={this.state.modalMessageSend}
          toggle={this.toggleMessageSend}
          centered
          size="lg"
        >
          <MDBModalHeader toggle={this.toggleMessageSend}>
            Send Message
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol col="12">
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Subject
                </label>
                <input
                  name="subject"
                  id="subject"
                  className="form-control"
                  onChange={this.changeHandler}
                  value={this.state.subject}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol col="6">
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea
                  className="form-control"
                  id="emailMessage"
                  name="emailMessage"
                  rows="10"
                  onChange={this.changeHandler}
                  value={this.state.emailMessage}
                ></textarea>
                {/* <Editor
									apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
									value={this.state.emailMessage}
									outputFormat="html"
									init={{
										height: 300,
										menubar: true,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
									}}
									onEditorChange={this.handleEditorChange}
								/> */}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.submitMessage}>
              {this.state.loadingMessages === true ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Send"
              )}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalRefund}
          toggle={this.toggleRefund}
          size="lg"
        >
          <MDBModalHeader toggle={this.toggleRefund}>
            Refund Transaction or Issue Credit
          </MDBModalHeader>
          <MDBModalBody>
            <p>
              Original Invoice Date:{" "}
              {this.state.invoiceDateRefund !== undefined
                ? new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(this.state.invoiceDateRefund))
                : ""}
            </p>
            <p>
              Amount: {this.state.currencySymbol + this.state.refundAmountMax}
            </p>
            <MDBNav className="nav-tabs">
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItemRefund === "1"}
                  onClick={this.toggleTabRefund("1")}
                  role="tabRefund"
                >
                  Credit
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItemRefund === "2"}
                  onClick={this.toggleTabRefund("2")}
                  role="tabRefund"
                >
                  Refund
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent
              className=""
              activeItem={this.state.activeItemRefund}
            >
              <MDBTabPane tabId="2" role="tabpanel">
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Date Range (select the dates that you want to credit)
                </label>
                <MDBRow>
                  <MDBCol lg="6" sm="12">
                    <DateInput
                      dateFormat="MM/DD/YYYY"
                      name="endRequestDate"
                      placeholder="MM/DD/YYYY"
                      initialDate={new Date(this.state.invoiceDateRefund)}
                      value={new Date(this.state.invoiceDateRefund)}
                      iconPosition="left"
                      onChange={this.changeHandlerRefundEndDate}
                      className="dateTimePicker"
                      maxDate={new Date(this.state.billingCycleEnd)}
                      minDate={new Date(this.state.invoiceDateRefund)}
                      closable={true}
                      disabled={true}
                    />
                  </MDBCol>
                  <MDBCol lg="6" sm="12">
                    <DateInput
                      dateFormat="MM/DD/YYYY"
                      name="endRequestDate"
                      placeholder="MM/DD/YYYY"
                      initialDate={new Date(this.state.billingCycleEnd)}
                      value={new Date(this.state.endRequestDate)}
                      iconPosition="left"
                      onChange={this.changeHandlerRefundEndDate}
                      className="dateTimePicker"
                      maxDate={new Date(this.state.billingCycleEnd)}
                      minDate={new Date(this.state.invoiceDateRefund)}
                      closable={true}
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Refund Amount
                </label>
                <br />
                <span className="currencySymbol">
                  {this.state.currencySymbol}
                </span>
                <input
                  type="number"
                  id="refundAmount"
                  name="refundAmount"
                  className="form-control refundAmount"
                  value={this.state.refundAmount}
                  min="0"
                  max={this.state.refundAmountMax}
                  onChange={this.changeHandler}
                  step=".01"
                />
                <div className="invalid-feedback">
                  Refund amount cannot be more than paid amount
                </div>
                <br />
                <label htmlFor="exampleFormControlTextarea1">Memo</label>
                <textarea
                  className="form-control"
                  id="refundMemo"
                  name="refundMemo"
                  rows="5"
                  onChange={this.changeHandler}
                />
                <MDBBtn color="danger" onClick={this.submitRefund}>
                  {this.state.submittingRefund ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </MDBBtn>
              </MDBTabPane>
              <MDBTabPane tabId="1" role="tabpanel">
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Date Range (select the dates that you want to credit)
                </label>
                <MDBRow>
                  <MDBCol lg="6" sm="12">
                    <DateInput
                      dateFormat="MM/DD/YYYY"
                      name="endRequestDate"
                      placeholder="MM/DD/YYYY"
                      initialDate={new Date(this.state.invoiceDateRefund)}
                      value={new Date(this.state.invoiceDateRefund)}
                      iconPosition="left"
                      onChange={this.changeHandlerRefundEndDate}
                      className="dateTimePicker"
                      maxDate={new Date(this.state.billingCycleEnd)}
                      minDate={new Date(this.state.invoiceDateRefund)}
                      closable={true}
                      disabled={true}
                    />
                  </MDBCol>
                  <MDBCol lg="6" sm="12">
                    <DateInput
                      dateFormat="MM/DD/YYYY"
                      name="endRequestDate"
                      placeholder="MM/DD/YYYY"
                      initialDate={new Date(this.state.billingCycleEnd)}
                      value={new Date(this.state.endRequestDate)}
                      iconPosition="left"
                      onChange={this.changeHandlerRefundEndDate}
                      className="dateTimePicker"
                      maxDate={new Date(this.state.billingCycleEnd)}
                      minDate={new Date(this.state.invoiceDateRefund)}
                      closable={true}
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Credit Amount
                </label>
                <br />
                <span className="currencySymbol">
                  {this.state.currencySymbol}
                </span>
                <input
                  type="number"
                  id="refundAmount"
                  name="refundAmount"
                  className="form-control refundAmount"
                  value={this.state.refundAmount}
                  min="0"
                  max={this.state.refundAmountMax}
                  onChange={this.changeHandler}
                  step=".01"
                />
                <div className="invalid-feedback">
                  Credit amount cannot be more than paid amount
                </div>
                <br />
                <label htmlFor="exampleFormControlTextarea1">Memo</label>
                <textarea
                  className="form-control"
                  id="refundMemo"
                  name="refundMemo"
                  rows="5"
                  onChange={this.changeHandler}
                />
                <MDBBtn color="danger" onClick={this.submitCredit}>
                  {this.state.submittingCredit ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </MDBBtn>
              </MDBTabPane>
            </MDBTabContent>
          </MDBModalBody>
        </MDBModal>
        <MDBModalHeader toggle={this.props.toggleModifyResident}>
          <h4>{t("modifyresident.title")}</h4>
          {this.state.activeItem === "1" ? (
            <p>{t("addnewresident.required")}</p>
          ) : (
            ""
          )}
        </MDBModalHeader>
        <MDBModalBody>
          <MDBNav className="nav-tabs">
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItem === "1"}
                onClick={this.toggleTab("1")}
                role="tab"
              >
                <MDBIcon icon="user" /> Profile
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItem === "2"}
                onClick={this.toggleTab("2")}
                role="tab"
              >
                <MDBIcon icon="envelope" /> Messages
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItem === "3"}
                onClick={this.toggleTab("3")}
                role="tab"
              >
                <MDBIcon icon="chart-line" /> Bandwidth
              </MDBNavLink>
            </MDBNavItem>
            {window.location.pathname === "/networkdashboard" ? (
              <>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeItem === "4"}
                    onClick={this.toggleTab("4")}
                    role="tab"
                  >
                    <MDBIcon icon="key" /> Keys
                  </MDBNavLink>
                </MDBNavItem>
              </>
            ) : (
              ""
            )}
            {showTransactions ? (
              <>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeItem === "5"}
                    onClick={this.toggleTab("5")}
                    role="tab"
                  >
                    <MDBIcon icon="shopping-cart" /> Transactions
                  </MDBNavLink>
                </MDBNavItem>
              </>
            ) : (
              ""
            )}
            {window.location.pathname === "/networkdashboard" ? (
              <>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeItem === "6"}
                    onClick={this.toggleTab("6")}
                    role="tab"
                  >
                    <MDBIcon icon="clipboard-list" /> Logs
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeItem === "7"}
                    onClick={this.toggleTab("7")}
                    role="tab"
                  >
                    <MDBIcon icon="laptop" /> Devices
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeItem === "8"}
                    onClick={this.toggleTab("8")}
                    role="tab"
                  >
                    <MDBIcon icon="database" /> System Data
                  </MDBNavLink>
                </MDBNavItem>
              </>
            ) : (
              ""
            )}
          </MDBNav>
          <MDBTabContent className="" activeItem={this.state.activeItem}>
            <MDBTabPane tabId="1" role="tabpanel">
              <form
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <MDBRow>
                  <MDBCol col="6" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.email")}
                    </label>
                    <div className="residentEmail">
                      <input
                        disabled={true}
                        id="email"
                        className="form-control"
                        onBlur={(event) => this.validateEmail(this.state.email)}
                        onChange={(event) =>
                          this.setState({
                            email: event.target.value,
                          })
                        }
                        value={this.state.email}
                      />
                    </div>
                    <div className="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Status
                    </label>
                    <input
                      disabled
                      id="status"
                      className="form-control"
                      value={this.state.status}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.firstname")}
                    </label>
                    <input
                      required
                      disabled={
                        this.state.realpageSyncDate === "" &&
                        (this.state.subscriberManagedviaSSO === false ||
                          this.state.subscriberManagedviaSSO === undefined)
                          ? false
                          : true
                      }
                      name="firstName"
                      id="firstName"
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      onChange={this.changeHandler}
                      value={this.state.firstName}
                    />
                    <div className="invalid-feedback">
                      Please provide a valid first name.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.lastname")}
                    </label>
                    <input
                      required
                      disabled={
                        this.state.realpageSyncDate === "" &&
                        (this.state.subscriberManagedviaSSO === false ||
                          this.state.subscriberManagedviaSSO === undefined)
                          ? false
                          : true
                      }
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      name="lastName"
                      id="lastName"
                      onChange={this.changeHandler}
                      value={this.state.lastName}
                    />
                    <div className="invalid-feedback">
                      Please provide a valid last name.
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol col="6" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.phone")}
                    </label>
                    <PhoneInput
                      disabled={
                        this.state.realpageSyncDate === "" &&
                        (this.state.subscriberManagedviaSSO === false ||
                          this.state.subscriberManagedviaSSO === undefined)
                          ? false
                          : true
                      }
                      placeholder={t("addnewresident.enterphone")}
                      value={this.state.phoneNumber}
                      format={FORMAT}
                      onChange={this.changeHandlerPhone}
                      name="phoneNumber"
                      id="phoneNumber"
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      defaultCountry="US"
                    />
                    {this.state.invalidPhone !== null ? (
                      <div className="invalid">{this.state.invalidPhone}</div>
                    ) : (
                      ""
                    )}
                  </MDBCol>
                  <MDBCol col="6">
                    <div>
                      <label
                        htmlFor="defaultFormLoginEmailEx"
                        className="black-text"
                      >
                        {t("addnewresident.language")}
                      </label>
                      <SelectSearch
                        disabled={
                          this.state.realpageSyncDate === "" &&
                          (this.state.subscriberManagedviaSSO === false ||
                            this.state.subscriberManagedviaSSO === undefined)
                            ? false
                            : true
                        }
                        id="selectLanguage"
                        search={true}
                        filterOptions={fuzzySearch}
                        options={this.state.languageOptions}
                        isOptionSelected={true}
                        classNamePrefix="language-select"
                        onChange={this.handleSelectChangeLanguage}
                        value={this.state.selectedLanguage}
                        placeholder={
                          this.state.selectedLanguage !== undefined
                            ? this.state.selectedLanguage === "en-us"
                              ? "English"
                              : "Spanish"
                            : "Select..."
                        }
                      />
                      <div className="invalid-feedback">
                        Please provide a valid language.
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="12" lg="4">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.unit")}
                    </label>
                    <SelectSearch
                      id="selectUnit"
                      options={this.state.optionTemplateUnits}
                      search={true}
                      filterOptions={fuzzySearch}
                      onChange={this.handleSelectChangeUnit}
                      isOptionSelected={true}
                      inputId="buildingArray"
                      classNamePrefix="unit-select"
                      placeholder="Select..."
                      value={this.state.unitID}
                    />
                    <div className="invalid-feedback">
                      Please provide a unit number.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="4">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.building")}
                    </label>
                    <SelectSearch
                      id="selectBuilding"
                      options={this.state.optionTemplateBuilding}
                      search={true}
                      filterOptions={fuzzySearch}
                      onChange={this.handleSelectChangeBuilding}
                      isOptionSelected={true}
                      inputId="buildingArray"
                      classNamePrefix="building-select"
                      placeholder="Select..."
                      value={
                        this.state.selectedBuilding === undefined
                          ? this.state.building
                          : this.state.selectedBuilding
                      }
                    />
                    <div className="invalid-feedback">
                      Please provide a building.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="4">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.floor")}
                    </label>
                    <SelectSearch
                      ref={this.floorSelect}
                      id="selectFloor"
                      search={true}
                      filterOptions={fuzzySearch}
                      options={this.state.optionTemplateFloor}
                      onChange={this.handleSelectChangeFloor}
                      isOptionSelected={true}
                      inputId="floorArray"
                      classNamePrefix="floo-select"
                      placeholder="Select..."
                      emptyMessage="Select..."
                      value={
                        this.state.selectedFloor === undefined
                          ? this.state.floor
                          : this.state.selectedFloor
                      }
                    />
                    <div className="invalid-feedback">
                      Please provide a floor.
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow></MDBRow>
                {showTransactions ? (
                  <>
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Service Plan
                        </label>
                        <SelectSearch
                          id="selectServicePlan"
                          search={true}
                          filterOptions={fuzzySearch}
                          options={this.state.optionTemplateServicePlan}
                          onChange={this.handleSelectChangeServicePlan}
                          isOptionSelected={true}
                          inputId="servicePlanArray"
                          classNamePrefix="service-plan-select form-control"
                          value={this.state.selectedServicePlanIndex}
                          placeholder="Select..."
                        />
                        <div className="invalid-feedback">
                          Please provide a service plan.
                        </div>
                      </MDBCol>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Service Plan Price
                        </label>
                        <input
                          disabled
                          id="floor"
                          className="form-control"
                          value={
                            getSymbolFromCurrency(this.state.currencyCode) +
                            this.state.selectedServicePlanPriceNumber
                          }
                        />
                        <div className="invalid-feedback">
                          Please provide a floor.
                        </div>
                      </MDBCol>
                      {window.location.pathname === "/networkdashboard" ? (
                        <>
                          <MDBCol sm="12" lg="6">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              VLAN
                            </label>
                            <input
                              disabled
                              id="vlan"
                              className="form-control"
                              value={this.state.vlan}
                            />
                            <div className="invalid-feedback">
                              Please provide a vlan.
                            </div>
                          </MDBCol>
                        </>
                      ) : (
                        ""
                      )}
                      <MDBCol sm="12" lg="6" className="ml-auto">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Venue Discount
                        </label>
                        <input
                          disabled
                          id="floor"
                          className="form-control"
                          value={this.state.venuePriceAdjustment}
                        />
                        <div className="invalid-feedback">
                          Please provide a floor.
                        </div>
                      </MDBCol>
                    </MDBRow>
                    {this.state.realpageSyncDate !== "" ? (
                      <>
                        <MDBRow>
                          <MDBCol col="6">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              Last sync with RealPage
                            </label>
                            <input
                              disabled
                              id="realpageSyncDateUTC"
                              className="form-control"
                              value={
                                this.state.realpageSyncDate !== ""
                                  ? new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(
                                      new Date(this.state.realpageSyncDate)
                                    )
                                  : ""
                              }
                            />
                          </MDBCol>
                          <MDBCol col="6">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              RealPage Unit ID
                            </label>
                            <input
                              disabled
                              id="RealPageUnitID"
                              className="form-control"
                              value={this.state.RealPageUnitID}
                            />
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      ""
                    )}
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.servicestart")}
                        </label>
                        <DateInput
                          dateFormat="MM/DD/YYYY"
                          name="dateStart"
                          placeholder="MM/DD/YYYY"
                          value={this.state.dateStart}
                          iconPosition="left"
                          onChange={this.handleChange}
                          className="dateTimePicker"
                          disabled={
                            new Date(this.state.dateStart) < new Date() ||
                            this.state.realpageSyncDate !== "" ||
                            this.state.subscriberManagedviaSSO === true
                              ? true
                              : false
                          }
                        />
                        <label
                          for="serviceStart"
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {this.props.venueType === "HOTSPOT"
                            ? "Local Policy can use their Wi-Fi password as of this date"
                            : t("addnewresident.servicestartlabel")}
                        </label>
                      </MDBCol>
                      <MDBCol col="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Subscription Discount
                        </label>
                        <input
                          disabled={
                            window.location.pathname === "/networkdashboard"
                              ? false
                              : true
                          }
                          type="number"
                          step="0.01"
                          id="shoppingCartPriceAdjustment"
                          className="form-control subscriptionDiscount"
                          name="subscriptionDiscount"
                          value={
                            -Math.abs(this.state.subscriptionDiscount).toFixed(
                              2
                            )
                          }
                          onChange={this.changeHandlerSubscriptionDiscount}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-2">
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.serviceend")}
                        </label>
                        <DateInput
                          dateFormat="MM/DD/YYYY"
                          name="dateEnd"
                          placeholder="MM/DD/YYYY"
                          initialDate={new Date(this.state.dateStart)}
                          value={
                            isNaN(new Date(this.state.dateEnd)) === true &&
                            this.state.invalidDateEnd === null
                              ? Intl.DateTimeFormat("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }).format(new Date(this.state.dateEnd))
                              : this.state.dateEnd
                          }
                          iconPosition="left"
                          onChange={this.handleChange}
                          className="dateTimePicker"
                          minDate={new Date(this.state.dateStart)}
                          disabled={
                            this.state.realpageSyncDate !== "" ||
                            this.state.subscriberManagedviaSSO === true
                              ? true
                              : false
                          }
                        />
                        <label
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {t("addnewresident.serviceendlabel")}
                        </label>
                      </MDBCol>
                      <MDBCol sm="12" lg="3">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Net Billing Price
                        </label>
                        <input
                          disabled
                          id="RealPageUnitID"
                          className="form-control"
                          value={
                            this.state.netBillingPrice > 0
                              ? getSymbolFromCurrency(this.state.currencyCode) +
                                this.state.netBillingPrice
                              : getSymbolFromCurrency(this.state.currencyCode) +
                                "0.00"
                          }
                        />
                      </MDBCol>
                      <MDBCol sm="12" lg="3">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Payment Update Required?
                        </label>
                        <input
                          disabled
                          id="paymentFailed"
                          className={
                            this.state.paymentFailed
                              ? "required form-control"
                              : "notRequired form-control"
                          }
                          value={this.state.paymentFailed ? "Yes" : "No"}
                        />
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  <>
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Service Plan
                        </label>
                        <SelectSearch
                          id="selectServicePlan"
                          search={true}
                          filterOptions={fuzzySearch}
                          options={this.state.optionTemplateServicePlan}
                          onChange={this.handleSelectChangeServicePlan}
                          isOptionSelected={true}
                          inputId="servicePlanArray"
                          classNamePrefix="service-plan-select form-control"
                          value={this.state.selectedServicePlanIndex}
                          placeholder="Select..."
                        />
                        <div className="invalid-feedback">
                          Please provide a service plan.
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.servicestart")}
                        </label>
                        <DateInput
                          dateFormat="MM/DD/YYYY"
                          name="dateStart"
                          placeholder="MM/DD/YYYY"
                          value={this.state.dateStart}
                          iconPosition="left"
                          onChange={this.handleChange}
                          className="dateTimePicker"
                          disabled={
                            new Date(this.state.dateStart) < new Date() ||
                            this.state.realpageSyncDate !== "" ||
                            this.state.subscriberManagedviaSSO === true
                              ? true
                              : false
                          }
                        />
                        <label
                          for="serviceStart"
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {this.props.venueType === "HOTSPOT"
                            ? "Local Policy can use their Wi-Fi password as of this date"
                            : t("addnewresident.servicestartlabel")}
                        </label>
                      </MDBCol>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.serviceend")}
                        </label>
                        <DateInput
                          dateFormat="MM/DD/YYYY"
                          name="dateEnd"
                          placeholder="MM/DD/YYYY"
                          initialDate={new Date(this.state.dateStart)}
                          value={
                            isNaN(new Date(this.state.dateEnd)) === true &&
                            this.state.invalidDateEnd === null
                              ? Intl.DateTimeFormat("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }).format(new Date(this.state.dateEnd))
                              : this.state.dateEnd
                          }
                          iconPosition="left"
                          onChange={this.handleChange}
                          className="dateTimePicker"
                          minDate={new Date(this.state.dateStart)}
                          disabled={
                            this.state.realpageSyncDate !== "" ||
                            this.state.subscriberManagedviaSSO === true
                              ? true
                              : false
                          }
                        />
                        <label
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {t("addnewresident.serviceendlabel")}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Allow account notifications via SMS"
                      type="checkbox"
                      id="contactpref_sms"
                      name="contactpref_sms"
                      onChange={this.toggleCheckboxValueSMS}
                      checked={this.state.contactpref_sms}
                    />
                    {/* <MDBInput
											label="Send notification via email"
											type="checkbox"
											id="contactpref_email"
											name="contactpref_email"
											onChange={this.toggleCheckboxValueEmail}
											checked={this.state.contactpref_email}
										/> */}
                  </MDBCol>
                </MDBRow>
                <div className="text-right submitButton">
                  {sessionStorage.getItem("stripeClientSecretSubscriber")
                    .length !== 0 ||
                  sessionStorage.getItem("stripeClientSecretSubscriber") !==
                    "undefined" ? (
                    <MDBBtn
                      color="danger"
                      className="btn Ripple-parent btn-default centered upgradePlan"
                      href={
                        "/update/checkout?ui=" +
                        window.location.pathname.slice(1)
                      }
                    >
                      Modify Billing CC
                    </MDBBtn>
                  ) : (
                    ""
                  )}
                  <MDBBtn color="danger" onClick={this.toggleResendActivation}>
                    {t("residentnav.resendactivation")}
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    onClick={this.toggleConfirmDeletion}
                    disabled={
                      this.state.realpageSyncDate !== "" ||
                      this.state.subscriberManagedviaSSO !== false
                        ? true
                        : false
                    }
                  >
                    {this.props.loadingDelete === true ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Delete Subscription"
                    )}
                  </MDBBtn>
                  <MDBBtn color="danger" type="submit">
                    {this.props.loading === true ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : this.props.venueType === "HOTSPOT" ? (
                      "Save Changes"
                    ) : (
                      t("modifyresident.modifyresident")
                    )}
                  </MDBBtn>
                </div>
              </form>
            </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel">
              {this.state.messages !== undefined ? (
                this.state.loadingMessages === true ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <MDBRow>
                      <MDBCol col="6">
                        <div
                          className="envelopeIcon"
                          onClick={this.toggleMessageSend}
                        >
                          <MDBIcon icon="envelope" />
                          <span>Send Message</span>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MessagesData
                      messages={this.state.messages}
                      toggleMessage={this.toggleMessage}
                    />
                  </>
                )
              ) : (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </MDBTabPane>
            <MDBTabPane tabId="3" role="tabpanel">
              <Bandwidth
                venueID={this.props.venueID}
                unitID_internal={this.props.unitID_internal}
                email={this.state.email}
              />
            </MDBTabPane>
            <MDBTabPane tabId="4" role="tabpanel">
              {this.state.subscriberKeys !== undefined ? (
                this.state.loadingKeys === true ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    Subscriber Token:{" "}
                    <p id="password">{this.state.subscriberToken}</p>
                    <div className="allrecentresidentsExpanded">
                      <SubscriberKeypoolsData
                        venueID={this.props.venueID}
                        subscriberKeys={this.state.subscriberKeys}
                        toggleConfirmRemoveKey={this.toggleConfirmRemoveKey}
                        removeKeyConfirm={this.removeKeyConfirm}
                        openChangeWifiPassword={this.openChangeWifiPassword}
                        toggleShowWifiPassword={this.toggleShowWifiPassword}
                      />
                      <div className="text-right submitButton">
                        <MDBBtn color="danger" onClick={this.toggleAddKey}>
                          Add Key
                        </MDBBtn>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </MDBTabPane>
            <MDBTabPane tabId="5" role="tabpanel">
              {this.state.transactions !== undefined ? (
                <>
                  <div className="allrecentresidentsExpanded">
                    <SubscriberTransactionsData
                      transactions={this.state.transactions}
                      stripeClientParentSecret={
                        this.state.stripeClientParentSecret
                      }
                      toggleRefund={this.toggleRefund}
                    />
                  </div>
                </>
              ) : (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </MDBTabPane>
            <MDBTabPane tabId="6" role="tabpanel">
              {}
              {this.state.subscriberLog !== undefined ? (
                // console.log(this.state.subscriberLog)
                <div>
                  <JSONPretty
                    id="json-pretty"
                    data={this.state.subscriberLog}
                  ></JSONPretty>
                </div>
              ) : (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </MDBTabPane>
            <MDBTabPane tabId="7" role="tabpanel">
              {this.state.devices !== undefined ? (
                <>
                  <ManageDevicesData deviceData={this.state.devices} />
                </>
              ) : (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </MDBTabPane>
            <MDBTabPane tabId="8" role="tabpanel">
              <div>
                <h4>System Data</h4>
                <MDBRow>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Cognito Status
                    </label>
                    <input
                      disabled
                      id="cognitoStatus"
                      name="cognitoStatus"
                      className="form-control"
                      value={this.state.cognitoStatus}
                    />
                  </MDBCol>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Status
                    </label>
                    <input
                      disabled
                      id="status"
                      name="status"
                      className="form-control"
                      value={this.state.status}
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Email Verified
                    </label>
                    <input
                      disabled
                      id="emailVerified"
                      name="emailVerified"
                      className="form-control"
                      value={this.state.emailVerified}
                    />
                  </MDBCol>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Subscriber Token{" "}
                      <MDBIcon icon="copy" onClick={this.copySubscriberToken} />
                    </label>
                    <input
                      disabled
                      id="subscriberToken"
                      name="subscriberToken"
                      className="form-control"
                      value={this.state.subscriberToken}
                    />
                    <span id="subscriberTokenHidden">
                      {this.state.subscriberToken}
                    </span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Phone Number Verified
                    </label>
                    <input
                      disabled
                      id="phoneVerified"
                      name="phoneVerified"
                      className="form-control"
                      value={this.state.phoneVerified}
                    />
                  </MDBCol>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      External Subscriber ID{" "}
                      <MDBIcon
                        icon="copy"
                        onClick={this.copyExternalSubscriberID}
                      />
                    </label>
                    <input
                      disabled
                      id="externalSubscriberID"
                      name="externalSubscriberID"
                      className="form-control"
                      value={this.state.externalSubscriberID}
                    />
                    <span id="externalSubscriberIDHidden">
                      {this.state.externalSubscriberID}
                    </span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Stripe Customer ID{" "}
                      <MDBIcon
                        icon="copy"
                        onClick={this.copyStripeCustomerID}
                      />
                    </label>
                    <input
                      disabled
                      id="stripeCustomerID"
                      name="stripeCustomerID"
                      className="form-control"
                      value={this.state.stripeCustomerID}
                    />
                    <span id="stripeCustomerIDHidden">
                      {this.state.stripeCustomerID}
                    </span>
                  </MDBCol>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Subscriber Managed by SSO
                    </label>
                    <input
                      disabled
                      id="managedBySSO"
                      name="managedBySSO"
                      className="form-control"
                      value={this.state.subscriberManagedviaSSO}
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Contact Pref SMS
                    </label>
                    <input
                      disabled
                      id="contactpref_sms"
                      name="contactpref_sms"
                      className="form-control"
                      value={this.state.contactpref_sms}
                    />
                  </MDBCol>
                  <MDBCol col="12" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Contact Pref Email
                    </label>
                    <input
                      disabled
                      id="contactpref_email"
                      name="contactpref_email"
                      className="form-control"
                      value={this.state.contactpref_email}
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol col="12" sm="12" lg="12">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Date Added
                    </label>
                    <input
                      disabled
                      id="dateAdded"
                      name="dateAdded"
                      className="form-control"
                      value={this.state.dateAdded}
                    />
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBTabPane>
          </MDBTabContent>
        </MDBModalBody>
      </>
    );
  }
}
export default withTranslation()(ModifyResident);
