/* Find Deals screen */
import React, { Component, Suspense } from "react";
import axios from "../../axios";
import _ from "lodash";
import "../../brands/common/css/common.scss";
import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";


let FindDealsComponent;

let Logo;

let BrandName;

export const SUB_PAGES = brandsData.forEach(
	({ name, url, component_directory_name, brand_directory_name, custom_component_directory_name, main_logo }) => {
		if (url.includes(window.location.hostname)) {
			import("../../brands/" + brand_directory_name + "/css/style.scss");
			FindDealsComponent = React.lazy(() =>
  import(
    "../../brands/" +
					custom_component_directory_name +
      "/components/CreateAccount/FindDeals"
  )
);
			Logo = "/" + brand_directory_name + "/" + main_logo;
			BrandName = name;
		}
	}
);

class FindDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venueList: undefined,
      region: [],
      stateOptions: [
        { value: "AK", name: "Alaska" },
        { value: "AL", name: "Alabama" },
        { value: "AR", name: "Arkansas" },
        { value: "AZ", name: "Arizona" },
        { value: "CA", name: "California" },
        { value: "CO", name: "Colorado" },
        { value: "CT", name: "Connecticut" },
        { value: "DC", name: "District of Columbia" },
        { value: "DE", name: "Delaware" },
        { value: "FL", name: "Florida" },
        { value: "GA", name: "Georgia" },
        { value: "HI", name: "Hawaii" },
        { value: "IA", name: "Iowa" },
        { value: "ID", name: "Idaho" },
        { value: "IL", name: "Illinois" },
        { value: "IN", name: "Indiana" },
        { value: "KS", name: "Kansas" },
        { value: "KY", name: "Kentucky" },
        { value: "LA", name: "Louisiana" },
        { value: "MA", name: "Massachusetts" },
        { value: "MD", name: "Maryland" },
        { value: "ME", name: "Maine" },
        { value: "MI", name: "Michigan" },
        { value: "MN", name: "Minnesota" },
        { value: "MO", name: "Missouri" },
        { value: "MS", name: "Mississippi" },
        { value: "MT", name: "Montana" },
        { value: "NC", name: "North Carolina" },
        { value: "ND", name: "North Dakota" },
        { value: "NE", name: "Nebraska" },
        { value: "NH", name: "New Hampshire" },
        { value: "NJ", name: "New Jersey" },
        { value: "NM", name: "New Mexico" },
        { value: "NV", name: "Nevada" },
        { value: "NY", name: "New York" },
        { value: "OH", name: "Ohio" },
        { value: "OK", name: "Oklahoma" },
        { value: "OR", name: "Oregon" },
        { value: "PA", name: "Pennsylvania" },
        { value: "RI", name: "Rhode Island" },
        { value: "SC", name: "South Carolina" },
        { value: "SD", name: "South Dakota" },
        { value: "TN", name: "Tennessee" },
        { value: "TX", name: "Texas" },
        { value: "UT", name: "Utah" },
        { value: "VA", name: "Virginia" },
        { value: "VT", name: "Vermont" },
        { value: "WA", name: "Washington" },
        { value: "WI", name: "Wisconsin" },
        { value: "WV", name: "West Virginia" },
        { value: "WY", name: "Wyoming" },
      ],
      address: undefined,
      optionTemplateAddress: [],
    };
  }
  componentDidMount() {
    // console.log("../../brands/" + this.props.brand + "/components/CreateAccount/FindDeals")
    sessionStorage.removeItem("venueName");
    sessionStorage.removeItem("venueID");
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("rememberMe");
    sessionStorage.removeItem("phoneNumber");
    sessionStorage.removeItem("lastName");
    sessionStorage.removeItem("dateStart");
    sessionStorage.removeItem("unit");
    sessionStorage.removeItem("unitName");
    sessionStorage.removeItem("state");
    sessionStorage.removeItem("city");
    sessionStorage.removeItem("address");
    sessionStorage.removeItem("postalcode");
    sessionStorage.removeItem("unitAdjustment");
    sessionStorage.removeItem("flagUnit");
    sessionStorage.removeItem("servicePlanID");
    sessionStorage.removeItem("stripePriceID");
    sessionStorage.removeItem("servicePlanName");
    sessionStorage.removeItem("billingCyclePrice");
    sessionStorage.removeItem("billingCycleRecurrence");
    sessionStorage.removeItem("fixedBillingDate");
    sessionStorage.removeItem("trialCycles");
    this.getVenues();
    // if (this.state.brand !== undefined) {
    //   FindDealsComponent = React.lazy(() =>
    //     import("../../brands/common/components/CreateAccount/FindDeals")
    //   );
    // }
  }
  getVenues = () => {
    let body = {};
    axios
      .post("/networkoperatorconsole/shoppingcart/listVenues", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          venueList: response.data.VenueList,
        });
        this.filterAvailableStates();
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  };
  filterStates(arrayOfObject, term) {
    let ans = arrayOfObject.filter(function (v, i) {
      if (v.value.indexOf(term) >= 0) {
        return true;
      }
      return false;
    });
    this.setState({
      filteredStates: ans,
    });
    return ans;
  }
  filterAvailableStates = () => {
    let ans = [];
    let optionTemplateStates = [];
    let optionTemplateStatesUnique = [];
    for (let i = 0; i < this.state.venueList.length; i++) {
      ans.push(
        this.filterStates(
          this.state.stateOptions,
          this.state.venueList[i]["State"]
        )
      );
    }
    if (ans !== undefined) {
      Object.keys(ans).forEach((oneKey, i) => {
        if (ans[oneKey].length !== 0) {
          optionTemplateStates.push({
            value: ans[i][0]["value"],
            name: ans[i][0]["name"],
          });
          return true;
        }
      });
    }
    optionTemplateStatesUnique = _.uniqWith(
      optionTemplateStates.sort(),
      _.isEqual
    );
    var sortedKeys = _(optionTemplateStatesUnique)
      .map(function (v, k) {
        // insert the key into the object
        return _.merge({}, v, { key: k });
      })
      .sortBy("value") // sort by name
      .value();
    this.setState({
      optionTemplateStates: sortedKeys,
    });
  };
  handleSelectState = (value) => {
    this.setState({ region: value });
    let ans = this.filterByValueState(this.state.venueList, value);
    let optionTemplateAddress = [];
    if (ans !== undefined) {
      Object.keys(ans).map((oneKey, i) => {
        if (
          ans[i]["VenueID"] !==
          "USA.VA.22102.GIGSTREEMMANAGEDPROPERTIES.MCLEAN._V3VENUE.USA.FL.33602.AVETAMPA.TAMPA"
        ) {
          optionTemplateAddress.push({
            value: ans[i]["VenueID"],
            name:
              ans[i]["VenueName"] +
              " - " +
              ans[i]["AddressLine1"] +
              ", " +
              ans[i]["City"],
          });
        }
        return true;
      });
    }
    var sortedKeys = _(optionTemplateAddress)
      .map(function (v, k) {
        // insert the key into the object
        return _.merge({}, v, { key: k });
      })
      .sortBy("name") // sort by name
      .value();
    this.setState({
      optionTemplateAddress: sortedKeys,
    });
    sessionStorage.setItem("state", value);
  };
  filterByValueState(arrayOfObject, term) {
    let ans = arrayOfObject.filter(function (v, i) {
      if (v.State.indexOf(term) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({
      filteredVenues: ans,
    });
    return ans;
  }
  handleSelectAddress = (event, { name, value }) => {
    let venueName = this.filterByValueVenue(this.state.venueList, value);
    this.setState({ venueID: value });
    sessionStorage.setItem("venueID", value);
    sessionStorage.setItem("venueName", venueName[0]["VenueName"]);
    sessionStorage.setItem("address", name);
    sessionStorage.setItem("city", venueName[0]["City"]);
    sessionStorage.setItem("postalcode", venueName[0]["PostalCode"]);
    sessionStorage.setItem(
      "unitAdjustment",
      venueName[0]["hasVenueUnitShoppingCartPriceAdjustment"]
    );
    sessionStorage.setItem("fccLabelAvailable",
      venueName[0]["FCCLabel"]["displayLabel"]
    );
    sessionStorage.setItem("fccTypicalLatency",
      venueName[0]["FCCLabel"]["TypicalLatency"]
    )
  };
  filterByValueVenue(arrayOfObject, term) {
    let ans = arrayOfObject.filter(function (v, i) {
      if (v.VenueID.indexOf(term) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    return ans;
  }
  render() {
    return (
      <>
        <Suspense
          fallback={
            <div className="overlay" id="overlay" style={{ display: "block" }}>
              <div className="overlayMessage">
                <div className="spinner-border spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
                    </div>}>
            <FindDealsComponent
              venueList={this.state.venueList}
              stateOptions={this.state.stateOptions}
              region={this.state.region}
              optionTemplateStates={this.state.optionTemplateStates}
              optionTemplateAddress={this.state.optionTemplateAddress}
              handleSelectState={this.handleSelectState}
              handleSelectAddress={this.handleSelectAddress}
              venueID={this.state.venueID}
              logo={Logo}
              brand={BrandName}
            />
					<Footer/>
        </Suspense>
      </>
    );
  }
}
export default FindDeals;
