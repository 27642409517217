import React, { Component } from "react";

import {
	MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol,
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBIcon
} from "mdbreact";

import reactCSS from 'reactcss'

import { ChromePicker } from 'react-color';

import { withTranslation } from "react-i18next";

import SelectSearch, { fuzzySearch } from 'react-select-search';

import parse from 'html-react-parser';

class AddNewVenueEquipment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			SMS: "",
			sms: false,
			voice: false,
			invalidPhone: null,
			loading: false,
			userFound: false,
			activeItem: "1",
			languageOptions: ([
				{ value: 'en-us', name: 'English' },
				{ value: 'es-xl', name: 'Spanish' }
			]),
			smsTypeOptions: ([
				{ value: 'WelcomeSMS', name: 'Welcome SMS' },
				{ value: 'SubscriberEvents', name: 'Subscriber Events' }
			]),
			smsUserOptions: ([
				{ value: 'NewSubscriber', name: 'New Subscriber' },
				{ value: 'KnownSubscriber', name: 'Known Subscriber' },
				{ value: 'GuestAccess', name: 'Guest Access' },
				{ value: 'NewAdministrator', name: 'New Administrator' },
				{ value: 'KnownAdministrator', name: 'Known Administrator' }
			]),
			smsUserEventsOptions: ([
				{ value: 'PasswordResetInitiated', name: 'Password Reset Initiated' },
				{ value: 'PasswordResetComplete', name: 'Password Reset Complete' },
				{ value: 'PIIDataUpdated', name: 'PII Data Updated' },
				{ value: 'PaymentSuccess', name: 'Payment Success' },
				{ value: 'PaymentFailed', name: 'Payment Failed' },
				{ value: 'PaymentMethodUpdated', name: 'Payment Method Updated' },
				{ value: 'SubscriptionStartDateAdjustment', name: 'Subscription Start Date Adjustment' },
				{ value: 'SubscriptionEndDateAdjustment', name: 'Subscription End Date Adjustment' },
				{ value: 'SubscriptionEnded', name: 'Subscription Ended' },
				{ value: 'SubscriptionPlanUpdated', name: 'Subscription Plan Updated' },
				{ value: 'SubscriptionPriceUpdated', name: 'Subscription Price Updated' },
				{ value: 'SubscriptionNextInvoiceAdjustment', name: 'Subscription Next Invoice Adjustment' },
				{ value: 'ChargeRefunded', name: 'Charge Refunded' },
				{ value: 'MessageFromAdministrator', name: 'Message From Administrator' }
			]),
			displayColorPicker: false,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
			// smsSectionOptions: ([
			// 	{ value: 'Subject', name: 'Subject' },
			// 	{ value: 'preheader', name: 'Pre-Header' },
			// 	{ value: 'title', name: 'Title' },
			// 	{ value: 'serviceinfo', name: 'Service Info' },
			// 	{ value: 'header', name: 'Header' },
			// 	{ value: 'message', name: 'Message' },
			// 	{ value: 'actionbutton', name: 'Action Button' },
			// 	{ value: 'additional_instructions', name: 'Additional Instructions' }
			// ]),
			// smsServiceInfoOptions: ([
			// 	{ value: 'template', name: 'Template' },
			// 	{ value: 'networkname', name: 'Network Name' },
			// 	{ value: 'wifipassword', name: 'Wifi Password' },
			// 	{ value: 'subscriptionstart', name: 'Subscription Start' },
			// 	{ value: 'subscriptionend', name: 'Subscription End' }
			// ])

		};
		this.handleEditorChangeMainTemplate = this.handleEditorChangeMainTemplate.bind(this);
		this.handleEditorChangePreheader = this.handleEditorChangePreheader.bind(this);
		this.handleEditorChangeHeader = this.handleEditorChangeHeader.bind(this);
		this.handleEditorChangeTitle = this.handleEditorChangeTitle.bind(this);
		this.handleEditorChangeActionButton = this.handleEditorChangeActionButton.bind(this);
		this.handleEditorChangeAdditionalInfo = this.handleEditorChangeAdditionalInfo.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleEditorChangeTemplate = this.handleEditorChangeTemplate.bind(this);
		this.handleEditorChangeNetworkName = this.handleEditorChangeNetworkName.bind(this);
		this.handleEditorChangeWifiPassword = this.handleEditorChangeWifiPassword.bind(this);
		this.handleEditorChangeSubscriptionStart = this.handleEditorChangeSubscriptionStart.bind(this);
		this.handleEditorChangeSubscriptionEnd = this.handleEditorChangeSubscriptionEnd.bind(this);
	}


	componentDidUpdate(prevProps) {
		if (prevProps.logoURL !== this.props.logoURL) {
			this.setState({ logoURL: this.props.logoURL });
		}
		if (prevProps.dynamicVariables !== this.props.dynamicVariables){
			this.setState({ dynamicVariables: this.props.dynamicVariables});
		}
		if (prevProps.brandColor !== this.props.brandColor){
			this.setState({ brandColor: this.props.brandColor });
		}
	}

	submitModifySMS = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";

		if (this.state.language !== undefined && this.state.type !== undefined && this.state.smsMessage !== undefined) {
			this.props.submitModifySMS(
				this.state.language, 
				this.state.type, 
				this.state.smsUserType,
				this.state.smsSubject, 
				this.state.smsMessage, 
				this.state.logoURL,
				this.state.dynamicVariables,
				this.state.brandColor,
				this.state.smsMainTemplate,
				this.state.smsPreheader,
				this.state.smsTitle,
				this.state.smsHeader,
				this.state.smsActionButton,
				this.state.smsAdditionalInfo,
				this.state.smsTemplate,
				this.state.smsNetworkName,
				this.state.smsWifiPassword,
				this.state.smsSubscriptionStart,
				this.state.smsSubscriptionEnd
			);
		}
	};

	changeHandlerLanguage = (value) => {
		this.setState({
			language: value
		});
		this.handleEditorChange(this.props.allSMSData[value === undefined ? "en-us" : value][this.state.type + "Message"]);
	};

	changeHandlerSMSType = (value) => {
		this.setState({
			type: value,
			smsMainTemplate: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][value]["Template"]
		});
		this.handleEditorChange(this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][value + "Message"]);
	};

	changeHandlerUserType = (value) => {
		let previewMessage = undefined;
		console.log(value)
		this.setState({
			previewMessage: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["Template"],
			smsUserType: value,
			smsSubject: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["Subject"],
			smsPreheader: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["preheader"],
			smsTitle: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["title"],
			smsHeader: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["header"],
			smsMessage: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["message"],
			smsActionButton: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["actionbutton"],
			smsAdditionalInfo: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["additional_instructions"],
			smsTemplate: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["template"] : undefined,
			smsNetworkName: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["networkname"] : undefined,
			smsWifiPassword: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["wifipassword"] : undefined,
			smsSubscriptionStart: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["subscriptionstart"] : undefined,
			smsSubscriptionEnd: this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["subscriptionend"] : undefined,
			activeItem: "1"
		})
		previewMessage = this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type]["Template"].replace("{preheader}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["preheader"]);
		previewMessage = previewMessage.replace("{serviceinfo}", (this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["template"] : ""));
		previewMessage = previewMessage.replace("{title}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["title"]);
		previewMessage = previewMessage.replace("{networkname}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["networkname"] : undefined); 
		previewMessage = previewMessage.replace("{subscriptionstart}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["subscriptionstart"] : undefined); 
		previewMessage = previewMessage.replace("{subscriptionend}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"] !== undefined ? this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["serviceinfo"]["subscriptionend"] : undefined);
		previewMessage = previewMessage.replace("{actionbutton}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["actionbutton"]);
		previewMessage = previewMessage.replace("{header}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["header"]);
		previewMessage = previewMessage.replace("{message}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["message"]);
		previewMessage = previewMessage.replace("{additional_instructions}", this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][value]["additional_instructions"]);
		previewMessage = previewMessage.replace("{operatorlogourl}", this.state.logoURL)
		this.setState({
			previewMessage: previewMessage
		})
	}

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if(event.target.name==="smsMainTemplate"){
			console.log(1)
			let previewMessage = event.target.value;
			previewMessage = previewMessage.replace("{preheader}", this.state.smsPreheader);
			previewMessage = previewMessage.replace("{serviceinfo}", this.state.smsTemplate);
			previewMessage = previewMessage.replace("{title}", this.state.smsTitle);
			previewMessage = previewMessage.replace("{networkname}", this.state.smsNetworkName); 
			previewMessage = previewMessage.replace("{subscriptionstart}", this.state.smsSubscriptionStart); 
			previewMessage = previewMessage.replace("{subscriptionend}", this.state.smsSubscriptionEnd);
			previewMessage = previewMessage.replace("{actionbutton}", this.state.smsActionButton);
			previewMessage = previewMessage.replace("{header}", this.state.smsHeader);
			previewMessage = previewMessage.replace("{message}", this.state.smsMessage);
			previewMessage = previewMessage.replace("{additional_instructions}", this.state.smsAdditionalInfo);
			previewMessage = previewMessage.replace("{operatorlogourl}", this.state.logoURL)
			this.setState({
				previewMessage: previewMessage
			})
			
		}else{
			let previewMessage = this.state.previewMessage;
			previewMessage = previewMessage.replace(this.state[event.target.name], event.target.value)
			this.setState({
				previewMessage: previewMessage
			})
		}
		
	};

	handleEditorChangeMainTemplate(smsMainTemplate, editor) {
		this.setState({ smsMainTemplate });
	}

	handleEditorChangePreheader(smsPreheader, editor) {
		console.log(this.props.allSMSData[this.state.language === undefined ? "en-us" : this.state.language][this.state.type][this.state.smsUserType]["preheader"])
		this.setState({ smsPreheader });
		
	}

	handleEditorChange(smsMessage, editor) {
		this.setState({ smsMessage });
	}

	handleEditorChangeHeader(smsHeader, editor) {
		this.setState({ smsHeader });
	}

	handleEditorChangeTitle(smsTitle, editor) {
		this.setState({ smsTitle });
	}

	handleEditorChangeActionButton(smsActionButton, editor) {
		this.setState({ smsActionButton });
	}

	handleEditorChangeAdditionalInfo(smsAdditionalInfo, editor) {
		this.setState({ smsAdditionalInfo });
	}

	handleEditorChangeTemplate(smsTemplate, editor) {
		this.setState({ smsTemplate });
	}

	handleEditorChangeNetworkName(smsNetworkName, editor) {
		this.setState({ smsNetworkName });
	}

	handleEditorChangeWifiPassword(smsWifiPassword, editor) {
		this.setState({ smsWifiPassword });
	}

	handleEditorChangeSubscriptionStart(smsSubscriptionStart, editor) {
		this.setState({ smsSubscriptionStart });
	}

	handleEditorChangeSubscriptionEnd(smsSubscriptionEnd, editor) {
		this.setState({ smsSubscriptionEnd });
	}

	handleChangeComplete = (color) => {
		this.setState({ brandColor: color.hex });
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
		// if(this.state.previewMessage !== undefined){
		// 	console.log(this.state.brandColor.toUpperCase())
		// 	let previewMessage = this.state.previewMessage;
		// 	previewMessage = previewMessage.replace(this.state.brandColor.toUpperCase(), color.hex)
		// 	this.setState({
		// 		previewMessage: previewMessage
		// 	})
		// }
		
		this.setState({ brandColor: color.hex })
		// console.log(this.state.brandColor)
		
	};

	toggleTab = (tab) => () => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};

	render() {
		let previewMessage;
		previewMessage = this.state.previewMessage !== undefined ? parse(this.state.previewMessage) : "";
		const { t } = this.props;
		const styles = reactCSS({
			'default': {
				color: {
					width: '36px',
					height: '14px',
					borderRadius: '2px',
					background: `${this.state.brandColor}`,
				},
				swatch: {
					padding: '5px',
					background: `${this.state.brandColor}`,
					borderRadius: '1px',
					boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
					display: 'inline-block',
					cursor: 'pointer',
				},
				popover: {
					position: 'absolute',
					zIndex: '2',
				},
				cover: {
					position: 'fixed',
					top: '0px',
					right: '0px',
					bottom: '0px',
					left: '0px',
				},
			},
		});
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleSMSInfo}>
					<h4>Modify SMS</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>


					<form className="needs-validation" onSubmit={this.submitModifySMS} noValidate>
						<MDBRow>
							<MDBCol sm="12" lg="12">
								<label htmlFor="defaultFormLoginSMSEx" className="black-text">
									Dynamic Variables
								</label>
								<div className="disabledArea">
									{this.state.dynamicVariables}
								</div>
							</MDBCol>
						</MDBRow>
						
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<div>
									<label htmlFor="defaultFormLoginSMSEx" className="black-text">
										{t("addnewresident.language")}
									</label>
									<SelectSearch
										id="selectLanguage"
										search={true}
										filterOptions={fuzzySearch}
										options={this.state.languageOptions}
										isOptionSelected={true}
										classNamePrefix="language-select"
										onChange={this.changeHandlerLanguage}
										value={this.state.language}
										placeholder={this.state.language !== undefined ? this.state.language === "en-us" ? "English" : "Spanish" : "Select..."}
									/>
									<div className="invalid-feedback">Please provide a valid language.</div>
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<div>
									<label htmlFor="defaultFormLoginSMSEx" className="black-text">
										SMS Type
									</label>
									<SelectSearch
										id="selectSMSType"
										search={true}
										filterOptions={fuzzySearch}
										options={this.state.smsTypeOptions}
										isOptionSelected={true}
										classNamePrefix="smsType-select"
										onChange={this.changeHandlerSMSType}
										value={this.state.type}
										placeholder={"Select an SMS Type"}
									/>

									<div className="invalid-feedback">Please provide a valid type.</div>
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<div>
									<label htmlFor="defaultFormLoginSMSEx" className="black-text">
										{ this.state.type === "WelcomeSMS" ? "User Type" : "Action Type" }
									</label>
									<SelectSearch
										id="selectUserType"
										search={true}
										filterOptions={fuzzySearch}
										options={this.state.type === "WelcomeSMS" ? this.state.smsUserOptions : this.state.smsUserEventsOptions}
										isOptionSelected={true}
										classNamePrefix="smsType-select"
										onChange={this.changeHandlerUserType}
										value={this.state.userType}
										placeholder={this.state.type === "WelcomeSMS" ? "Select a User Type" : "Select an Action Type"}
									/>

									<div className="invalid-feedback">Please provide a valid type.</div>
								</div>
							</MDBCol>
						</MDBRow>
						{/* <MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<div>
									<label htmlFor="defaultFormLoginSMSEx" className="black-text">
										SMS Section
									</label>
									<SelectSearch 
								    	id="selectSMSSection" 
								    	search={true}
                         				filterOptions={ fuzzySearch }
								    	options={this.state.smsSectionOptions}
								    	isOptionSelected={true}
								    	classNamePrefix="smsType-select"
								    	onChange={this.changeHandlerSMSSection}
								    	value={this.state.smsSection}
								    	placeholder={"Select an SMS Section"}
								    />
									
									<div className="invalid-feedback">Please provide a valid section.</div>
								</div>
							</MDBCol>
						</MDBRow> */}
						{/* <MDBRow>
									<MDBCol col="6" sm="12" lg="12">
										<div>
											<label htmlFor="defaultFormLoginSMSEx" className="black-text">
												Service Info
											</label>
											<SelectSearch
												id="selectEmaiServiceInfo"
												search={true}
												filterOptions={fuzzySearch}
												options={this.state.smsServiceInfoOptions}
												isOptionSelected={true}
												classNamePrefix="smsType-select"
												onChange={this.changeHandlerSMSServiceInfo}
												value={this.state.smsServiceInfo}
												placeholder={"Select a Service Info Type"}
											/>

											<div className="invalid-feedback">Please provide a valid section.</div>
										</div>
									</MDBCol>
								</MDBRow> */}
						<br/>
						
						<MDBNav className="nav-tabs">
							<MDBNavItem>
								<MDBNavLink
									link
									to="#"
									active={this.state.activeItem === "1"}
									onClick={this.toggleTab("1")}
									role="tab"
								>
									<MDBIcon icon="envelope" /> Preview
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink
									link
									to="#"
									active={this.state.activeItem === "4"}
									onClick={this.toggleTab("4")}
									role="tab"
								>
									<MDBIcon icon="file-invoice" /> Branding Template
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink
									link
									to="#"
									active={this.state.activeItem === "2"}
									onClick={this.toggleTab("2")}
									role="tab"
								>
									<MDBIcon icon="envelope" /> Components
								</MDBNavLink>
							</MDBNavItem>
							{/* <MDBNavItem>
								<MDBNavLink
									link
									to="#"
									active={this.state.activeItem === "3"}
									onClick={this.toggleTab("3")}
									role="tab"
									disabled={this.state.smsTemplate !== undefined ? false : true}
								>
									<MDBIcon icon="file" /> Service Info Templates
								</MDBNavLink>
							</MDBNavItem> */}
							
						</MDBNav>

						<MDBTabContent className="" activeItem={this.state.activeItem}>
							<MDBTabPane tabId="1" role="tabpanel">
								{previewMessage}
							</MDBTabPane>
							<MDBTabPane tabId="4" role="tabpanel">
								<MDBRow>
									<MDBCol sm="12" lg="12">
										<label htmlFor="defaultFormLoginSMSEx" className="black-text">
											Main Template
										</label>
										<textarea
											className="form-control"
											id="smsMainTemplate"
											name="smsMainTemplate"
											rows="10"
											onChange={this.changeHandler}
											value={this.state.smsMainTemplate}
											disabled={this.state.disabled}
										/>
										{/* <Editor
											apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
											value={this.state.smsMainTemplate}
											outputFormat="html"
											init={{
												entity_encoding: "raw",
												height: 300,
												menubar: true,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste code help wordcount",
													"fullpage"
												],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
											}}
											onEditorChange={this.handleEditorChangeMainTemplate}
											entity_encoding="raw"
										/> */}
										<div className="invalid-feedback">Please provide a valid subject.</div>
									</MDBCol>
								</MDBRow>
							</MDBTabPane>
							<MDBTabPane tabId="2" role="tabpanel">
								{/* <MDBRow>
									<MDBCol sm="12" lg="12">
										<label htmlFor="defaultFormLoginSMSEx" className="black-text">
											SMS Subject
										</label>
										<input 
											name="smsSubject" 
											id="smsSubject" 
											className="form-control" 
											onChange={this.changeHandler} 
											value={this.state.smsSubject} 
											disabled={this.state.disabled}
										/>
										<div className="invalid-feedback">Please provide a valid subject.</div>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol sm="12" lg="12">
										<label htmlFor="defaultFormLoginSMSEx" className="black-text">
											Preheader
										</label>
										<textarea
											className="form-control"
											id="smsPreheader"
											name="smsPreheader"
											rows="10"
											onChange={this.changeHandler}
											value={this.state.smsPreheader}
											disabled={this.state.disabled}
										/>
										
										<div className="invalid-feedback">Please provide a valid subject.</div>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol sm="12" lg="12">
										<label htmlFor="defaultFormLoginSMSEx" className="black-text">
											Title
										</label>
										<textarea
											className="form-control"
											id="smsTitle"
											name="smsTitle"
											rows="10"
											onChange={this.changeHandler}
											value={this.state.smsTitle}
											disabled={this.state.disabled}
										/>
										
										<div className="invalid-feedback">Please provide a valid subject.</div>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol sm="12" lg="12">
										<label htmlFor="defaultFormLoginSMSEx" className="black-text">
											Header
										</label>
										<textarea
											className="form-control"
											id="smsHeader"
											name="smsHeader"
											rows="10"
											onChange={this.changeHandler}
											value={this.state.smsHeader}
											disabled={this.state.disabled}
										/>
										
										<div className="invalid-feedback">Please provide a valid subject.</div>
									</MDBCol>
								</MDBRow> */}
								<MDBRow>
									<MDBCol sm="12" lg="12">
										<label htmlFor="exampleFormControlTextarea1">Message</label>
										<textarea
											className="form-control"
											id="smsMessage"
											name="smsMessage"
											rows="10"
											onChange={this.changeHandler}
											value={this.state.smsMessage}
											disabled={this.state.disabled}
										/>
										
									</MDBCol>
								</MDBRow>
								

							</MDBTabPane>
							<MDBTabPane tabId="3" role="tabpanel">
								
							</MDBTabPane>
						</MDBTabContent>
						<div className="text-right submitButton">
							<MDBBtn 
								color="danger" 
								type="submit"
								disabled={this.state.disabled}
							>
								{this.props.modify_sms === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									"Save Changes"
								)}
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddNewVenueEquipment);