import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from 'react-select-search';

class AddNewVenueEquipment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: "",
			sms: false,
			voice: false,
			invalidPhone: null,
			loading: false,
			userFound: false,
			equipmentTypeOptions: [
				{ 
					value: "Gateway", 
					name: "Router/Gateway" },
				{
					value: "Wireless Controller",
					name: "Wireless Controller",
				},

			],
		};
	}

	validateEmail = (email) => {
		var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
		this.setState({
			invalidPhone: undefined,
			userFound: false,
			userFoundWithPhone: false,
		});

		if (!this.state.userName.match(mailformat)) {
			this.setState({
				invalidEmail: "Please provide a valid email address",
			});
			$(".emailInput").addClass("is-invalid");
		} else {
			this.setState({ invalidEmail: undefined });
			$(".emailInput").removeClass("is-invalid");
		}
	};

	submitAddVenueEquipment = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";
		if ( 
			this.state.selectedEquipmentType === "Gateway"
		){
			if (
				this.state.equipID !== undefined &&
				this.state.manufacturer !== undefined &&
				this.state.model !== undefined &&
				this.state.selectedEquipmentType !== undefined &&
				this.state.protocol !== undefined &&
				this.state.ipaddy !== undefined &&
				this.state.port !== undefined &&
				this.state.userName !== undefined &&
				this.state.userPassword !== undefined &&
				this.state.status !== undefined && 
				this.state.pre643 !== undefined
				
				)
				{
				this.props.submitAddVenueEquipment(
					this.state.equipID,
					this.state.manufacturer,
					this.state.model,
					this.state.selectedEquipmentType,
					this.state.zone,
					this.state.protocol,
					this.state.ipaddy,
					this.state.port,
					this.state.userName,
					this.state.userPassword,
					this.state.status,
					this.state.pre643
				);
			}
		}
		else
		{
			if (
				this.state.equipID !== undefined &&
				this.state.manufacturer !== undefined &&
				this.state.model !== undefined &&
				this.state.selectedEquipmentType !== undefined &&
				this.state.protocol !== undefined &&
				this.state.ipaddy !== undefined &&
				this.state.port !== undefined &&
				this.state.userName !== undefined &&
				this.state.userPassword !== undefined &&
				this.state.status !== undefined 
				
				)
				{
				this.props.submitAddVenueEquipment(
					this.state.equipID,
					this.state.manufacturer,
					this.state.model,
					this.state.selectedEquipmentType,
					this.state.zone,
					this.state.protocol,
					this.state.ipaddy,
					this.state.port,
					this.state.userName,
					this.state.userPassword,
					this.state.status
				);
			}
		}
		
	};

	handleSelectEquipmentType = (value) => {
		this.setState({ selectedEquipmentType: value });
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleAddVenueEquipment}>
					<h4>Add Venue Equipment</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>
					<form
						className="needs-validation"
						onSubmit={this.submitAddVenueEquipment}
						noValidate
					>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Venue Name*
								</label>
								<input
									disabled
									name="venueName"
									id="venueName"
									className="form-control"
									onChange={this.changeHandler}
									value={this.props.venueName}
								/>
								<div className="invalid-feedback">
									Please provide a valid name
								</div>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Equipment Type
								</label>
								<SelectSearch
									id="selectEquipmentType"
									search={true}
									filterOptions={fuzzySearch}
									options={this.state.equipmentTypeOptions}
									inputId="equipmentTypeArray"
									classNamePrefix="unit-select form-control"
									placeholder="Select..."
									onChange={this.handleSelectEquipmentType}
									value={this.state.selectedEquipmentType}
								/>
								<div className="invalid-feedback">
									Please provide a valid type.
								</div>
							</MDBCol>
							<MDBCol col="6" sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Equipment Name
								</label>
								<input
									required
									id="equipID"
									name="equipID"
									className="form-control"
									onChange={this.changeHandler}
									value={this.state.equipID}
								/>
								<div className="invalid-feedback">
									Please provide a valid name.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Manufacturer
								</label>
								<input
									required
									name="manufacturer"
									id="manufacturer"
									className="form-control"
									onChange={this.changeHandler}
									value={this.state.manufacturer}
								/>
								<div className="invalid-feedback">
									Please provide a valid manufacturer name.
								</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Model
								</label>
								<input
									required
									className="form-control"
									name="model"
									id="model"
									onChange={this.changeHandler}
									value={this.state.model}
								/>
								<div className="invalid-feedback">
									Please provide a valid model name.
								</div>
							</MDBCol>
						</MDBRow>
						{
							this.state.selectedEquipmentType === "Gateway" ? 
							<MDBRow>
								<MDBCol sm="12" lg="12">
									<label
										htmlFor="defaultFormLoginEmailEx"
										className="black-text"
									>
										MikrotikPre643
									</label>
									<div className="select-box">
									<select
										className="browser-default custom-select"
										name="pre643"
										required
										onChange={this.changeHandler}
										value={this.state.pre643}
									>
										<option value="" disabled selected>
											Select
										</option>
										<option value="true">True</option>
										<option value="false">False</option>
									</select>
									</div>
									<div className="invalid-feedback">
										Please provide a valid selection.
									</div>
								</MDBCol>
							</MDBRow>
							:
							<MDBRow>
								<MDBCol sm="12" lg="12">
									<label
										htmlFor="defaultFormLoginEmailEx"
										className="black-text"
									>
										Wireless Zone
									</label>
									<input
										className="form-control"
										name="zone"
										id="zone"
										onChange={this.changeHandler}
										value={this.state.zone}
									/>
									<div className="invalid-feedback">
										Please provide a valid zone.
									</div>
								</MDBCol>
							</MDBRow>
						}
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Connection Protocol
								</label>
								<input
									required
									name="protocol"
									id="protocol"
									className="form-control"
									onChange={this.changeHandler}
									value={this.state.protocol}
								/>
								<div className="invalid-feedback">
									Please provide a protocol.
								</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									IP Address
								</label>
								<input
									required
									className="form-control"
									name="ipaddy"
									id="ipaddy"
									onChange={this.changeHandler}
									value={this.state.ipaddy}
								/>
								<div className="invalid-feedback">
									Please provide a valid IP Address.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Port
								</label>
								<input
									required
									name="port"
									id="port"
									className="form-control"
									onChange={this.changeHandler}
									value={this.state.port}
								/>
								<div className="invalid-feedback">
									Please provide a port number.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									User Name
								</label>
								<input
									required
									name="userName"
									id="userName"
									className="form-control emailInput"
									onChange={(event) =>
										this.setState({
											userName: event.target.value,
										})
									}
									value={this.state.userName}
								/>
								<div className="invalid-feedback">
									Please provide a valid name.
								</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Password
								</label>
								<input
									required
									className="form-control"
									name="userPassword"
									id="userPassword"
									onChange={this.changeHandler}
									value={this.state.userPassword}
								/>
								<div className="invalid-feedback">
									Please provide a valid password.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Status
								</label>
								<div className="select-box">
								<select
									className="browser-default custom-select"
									name="status"
									required
									onChange={this.changeHandler}
									value={this.state.status}
								>
									<option value="" disabled selected>
										Select Status
									</option>
									<option value="In Service">
										In Service
									</option>
									<option value="Up">Up</option>
									<option value="Down">Down</option>
								</select>
								</div>
								<div className="invalid-feedback">
									Please provide a valid status.
								</div>
							</MDBCol>
							
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.add_venueequip === true ? (
									<div
										className="spinner-border spinner-border-sm"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								) : (
									"Add Equipment"
								)}
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddNewVenueEquipment);