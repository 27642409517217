import React from 'react';

import { MDBDataTableV5 } from 'mdbreact';

import { useTranslation } from 'react-i18next';

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
    [datatable] = React.useState('');
  if(props.staff!==undefined){
    
    userAttributes = [];
    for (const key of Object.keys(props.staff)) {
      
      userAttributes.push({
          email: Object.keys(props.staff[key]),
          type: Object.values(props.staff[key]),
          action: <><div className="renewConfirmButton1"><a href="#link" onClick={()=>props.toggleDisable(Object.keys(props.staff[key]))}className="expirationAction">Delete</a></div></>

        })
      i = i + 1;
    }
    
  }
  
    [datatable] = React.useState({
    columns: [
      
      {
        label: t('recentservice.email'),
        field: 'email',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Type',
        field: 'type',
        sort: 'asc',
        width: 200,
      },
      {
        label: sessionStorage.getItem("customerSupport") > 0 ? "" : "Action",
        field: sessionStorage.getItem("customerSupport") > 0 ? "" : "action",
      }
    ],
    rows: userAttributes
  });
  return <MDBDataTableV5 
            responsive 
            hover={false}
            data={datatable} 
            searchBottom={false} 
            paging={false}
            className="modifyAdminStaff"
            info={false}
            noRecordsFoundLabel={t('recentservice.nomatches')}
            />;
}