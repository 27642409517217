/* Find Deals screen */
import React, { Component, Suspense } from "react";
import axios from "../../axios";
import "../../brands/common/css/common.scss";
import Footer from "../../components/Common/Footer";
import brandsData from "../../brands/common/brands.json";
import _ from "lodash";
let SelectPlanFactsComponent;

let Logo;

let BrandName;

export const SUB_PAGES = brandsData.forEach(
	({ name, url, component_directory_name, brand_directory_name, custom_component_directory_name, main_logo }) => {
		if (url.includes(window.location.hostname)) {
			import("../../brands/" + brand_directory_name + "/css/style.scss");
			SelectPlanFactsComponent = React.lazy(() =>
				import(
					"../../brands/" +
					custom_component_directory_name +
						"/components/CreateAccount/SelectPlanFacts"
				)
			);
			Logo = "/" + brand_directory_name + "/" + main_logo;
			BrandName = name;
		}
	}
);
// let SelectPlanFactsComponent = React.lazy(() =>
//   import(
//     "../../brands/" +
//       sessionStorage.getItem("customBrand") +
//       "/components/CreateAccount/SelectPlanFacts"
//   )
// );
// const root = document.documentElement;
// root?.style.setProperty(
//  "--background-color",
//  sessionStorage.getItem("background-color")
// );
// root?.style.setProperty(
//  "--modal-background-color",
//  sessionStorage.getItem("modal-background-color")
// );
// root?.style.setProperty(
//  "--primary-text-color",
//  sessionStorage.getItem("primary-text-color")
// );
// root?.style.setProperty("--base-color", sessionStorage.getItem("base-color"));
// root?.style.setProperty(
//  "--secondary-color",
//  sessionStorage.getItem("secondary-color")
// );
// root?.style.setProperty(
//  "--secondary-text-color",
//  sessionStorage.getItem("secondary-text-color")
// );
// root?.style.setProperty(
//  "--border-color",
//  sessionStorage.getItem("border-color")
// );
// root?.style.setProperty(
//  "--base-color-rgb",
//  sessionStorage.getItem("base-color-rgb")
// );
// root?.style.setProperty("--link-color", sessionStorage.getItem("link-color"));
// root?.style.setProperty(
//  "--button-color",
//  sessionStorage.getItem("button-color")
// );
// root?.style.setProperty("--button-text-color", "#FFF");
// root?.style.setProperty(
//  "--banner-color-1",
//  sessionStorage.getItem("banner-color-1")
// );
// root?.style.setProperty(
//  "--banner-color-2",
//  sessionStorage.getItem("banner-color-2")
// );
class SelectPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planList: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.getPlans();
  }
  getPlans = () => {
    this.setState({
      loading: true,
    });
    let body;
    // if (sessionStorage.getItem("unit") !== null) {
    //   body = {
    //     UnitID_internal: sessionStorage.getItem("unit"),
    //     ShoppingCartEnabled: true,
    //   };
    // } else {
    //   body = {
    //     VenueID: sessionStorage.getItem("venueID"),
    //     ShoppingCartEnabled: true,
    //   };
    // }
    body = {
     VenueID: sessionStorage.getItem("venueID"),
     ShoppingCartEnabled: true,
   };
    // console.log(sessionStorage.getItem("unit"))
    let filteredServicePlans = [];
    axios
      .post("/networkoperatorconsole/shoppingcart/listServicePlans", body, {
        headers: {},
      })
      .then((response) => {
        for (
          var i = 0;
          i <
          response.data["AvailableServicePlans"].length;
          i++
        ) {
          filteredServicePlans.push(
            response.data["AvailableServicePlans"][i]
          );
        }
        let sortedServicePlans = _(filteredServicePlans)
          .map(function (v, k) {
            return _.merge({}, v, { key: k });
          })
          .sortBy("BillingCyclePrice.USD")
          .reverse() // sort by name
          .value();
        this.setState({
          planList: sortedServicePlans,
          // fccLabel: response.data["AvailableServicePlans"][i][
          //  "FCCLabel"
          // ],
          // supportInfo: response.data["DisplayConfiguration"][
          //  "Support"
          // ],
        });
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        this.setState({ error: true });
      });
  };
  selectedPlan = (
    servicePlanKey,
    servicePlanID,
    stripePriceID,
    servicePlanName,
    billingCyclePrice,
    billingCycleRecurrence,
    fixedBillingDate,
    trialCycles
  ) => {
   sessionStorage.setItem("servicePlanKey", servicePlanKey);
    sessionStorage.setItem("servicePlanID", servicePlanID);
    sessionStorage.setItem("stripePriceID", stripePriceID);
    sessionStorage.setItem("servicePlanName", servicePlanName);
    sessionStorage.setItem("billingCyclePrice", billingCyclePrice);
    sessionStorage.setItem("billingCycleRecurrence", billingCycleRecurrence);
    sessionStorage.setItem("fixedBillingDate", fixedBillingDate);
    sessionStorage.setItem("trialCycles", trialCycles);
  };
  render() {
   console.log(this.state.planList)
    return (
      <>
        <Suspense
          fallback={
            <div className="overlay" id="overlay" style={{ display: "block" }}>
              <div className="overlayMessage">
                <div className="spinner-border spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        >
          <SelectPlanFactsComponent
            planList={this.state.planList}
            fccLabel={this.state.fccLabel}
            supportInfo={this.state.supportInfo}
            loading={this.state.loading}
            selectedPlan={this.selectedPlan}
            logo={Logo}
            brand={BrandName}
          />
          <Footer />
        </Suspense>
      </>
    );
  }
}
export default SelectPlan;
