import * as React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Button } from "@mui/material";
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };
  const handleMouseLeave = () => {
    setShowFullCell(false);
  };
  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }
    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);
  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.formattedValue || ''} width={displayTextWidth(params.formattedValue, ".9rem Proxima Nova Rg")} />
  );
}
function displayTextWidth(text, font) {
  let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width + 25;
}
export default function Basic(props) {
  let userAttributes;
  let i = 0;
  let columns = [];
  let rows = [];
  if (props.upcomingexpirations !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.upcomingexpirations)) {
      if (props.upcomingexpirations[key]["UnitID"] !== undefined) {
        userAttributes.push({
          id: key,
          unitID: props.upcomingexpirations[key]["UnitID"],
          building: props.upcomingexpirations[key]["UnitBuilding"],
          floor: props.upcomingexpirations[key]["UnitFloor"],
          name: props.upcomingexpirations[key]["SubscriberGivenName"] + " " + props.upcomingexpirations[key]["SubscriberFamilyName"],
          email: props.upcomingexpirations[key]["SubscriberEmail"],
          status: props.upcomingexpirations[key]["Status"],
          expiring: props.upcomingexpirations[key]["EndDateTimeUTC.ISO8601"].toString(),
          action: (
              <div className="renewConfirmButton1">
                <Button onClick={() => props.modifyResident(props.upcomingexpirations[key])} className="expirationAction">
                  Renew
                </Button>
              </div>
          ),
        });
      }
    }
  }
  columns = [
    {
      headerName: "Unit ID",
      field: "unitID",
      flex: 0.04,
      minWidth: 20,
      renderCell: renderCellExpand
    },
    {
      headerName: "Building",
      field: "building",
      flex: 0.04,
      minWidth: 20,
      renderCell: renderCellExpand
    },
    {
      headerName: "Floor",
      field: "floor",
      flex: 0.04,
      minWidth: 20,
      renderCell: renderCellExpand
    },
    {
      headerName: "Name",
      field: "name",
      flex: 0.1,
      renderCell: renderCellExpand
    },
    {
      headerName: "Email Address",
      field: "email",
      flex: 0.1,
      renderCell: renderCellExpand
    },
    {
      headerName: "Status",
      field: "status",
      flex: 0.1,
      renderCell: renderCellExpand
    },
    {
      headerName: "Expiring",
      field: "expiring",
      hide: true,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: renderCellExpand,
      minWidth: 50,
      flex: 0.1,
    },
    {
      headerName: "Action",
      field: "action",
      width: 160,
      renderCell: (params) => {
        return (
          <>
          <Button
            onClick={(e) => (props.modifyResident(props.upcomingexpirations[params["id"]]))}
            // onClick={(e) => (console.log(params["id"]))}
            variant="contained"
            className='expirationAction'
          >
            Renew
          </Button>
          <Button
          onClick={(e) => (props.toggleConfirmExpiration(props.upcomingexpirations[params["id"]]))}
          // onClick={(e) => (console.log(params["id"]))}
          variant="contained"
          className='expirationAction'
        >
          Confirm
        </Button>
        </>
        );
      },
      minWidth: 50,
      flex: 0.1,
    }
  ];
  rows = userAttributes;
  return (
    <div style={{ height: 150, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={(id) => props.modifyResident(props.allrecentresidents[id.row["id"]])}
        getRowHeight={({ id, densityFactor }) => {
          return 40 * densityFactor;
}}
        hideFooterPagination={true}
        hideFooter={true}
        sx={{ '--DataGrid-overlayHeight': '300px' }}
      />
    </div>
  );
}