import React, { Component } from "react";
import { MDBBtn, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBModal, toast } from "mdbreact";
import AddStaff from "../../../../components/VenueMgmt/Forms/AddStaff/AddStaff";
import ModifyStaff from "../../../../components/VenueMgmt/PropertyInfo/ModifyStaff";
import ResendActivation from "../../../../components/VenueMgmt/ResendActivation/ResendActivation";
import PropertyInfo from "../../../../components/VenueMgmt/PropertyInfo/PropertyInfo";
import VenueDropdown from "../../../../components/VenueMgmt/Navigation/VenueDropdown/VenueDropdown";
import axios from "../../../../axios";
import { withTranslation } from "react-i18next";
class PropertyManagementNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddStaff: false,
      modalModifyStaff: false,
      loading: false,
      building: "All",
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }
  toggleAddStaff = () => {
    this.setState({
      modalAddStaff: !this.state.modalAddStaff,
    });
  };
  toggleModifyStaff = () => {
    this.setState({
      modalModifyStaff: !this.state.modalModifyStaff,
    });
  };
  togglePropertyInfo = () => {
    this.setState({
      modalPropertyInfo: !this.state.modalPropertyInfo,
    });
  };
  toggleResendActivation = () => {
    this.setState({
      modalResendActivation: !this.state.modalResendActivation,
    });
  };
  getAdmins = () => {
    this.setState({ staffArrayAll: undefined });
    let staffArrayAll = [];
    let staffArrayVA = [];
    let staffArrayLeasing = [];
    for (var i = 0; i < this.props.venueAdmins.length; i++) {
      staffArrayVA[i] = { [this.props.venueAdmins[i]]: "Venue Administrator" };
    }
    for (var j = 0; j < this.props.leasingAgents.length; j++) {
      staffArrayLeasing[j] = { [this.props.leasingAgents[j]]: "Leasing Agent" };
    }
    staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
    this.setState({ staffArrayAll: [...staffArrayAll] });
  };
  submitHandler = (dateEnd, email, firstName, lastName, phoneNumber, language, contactpref_email, contactpref_sms, role, venueid) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: venueid,
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      contactpref_email: true,
      contactpref_sms: contactpref_sms,
      Role: role,
    };
    this.setState({ loading: true });
    axios
      .post("/venuemgmtconsole/staff/enableStaffAccess", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Added staff member!", {
            closeButton: false,
          });
          this.toggleAddStaff();
          this.props.updateName(this.props.venueNameSelected, this.props.venueID);
          this.setState({ loading: false });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleAddStaff();
          this.setState({ loading: false });
        }
      );
  };
  submitHandlerResendActivation = (email) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: this.props.venueID,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({ loading: true });
    axios
      .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Resent Confirmation!", {
            closeButton: false,
          });
          this.toggleResendActivation();
          this.setState({ loading: false });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleResendActivation();
          this.setState({ loading: false });
        }
      );
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  toggleMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
      subject: undefined,
      emailMessage: undefined,
    });
  };
  submitMessage = () => {
    this.setState({ loading: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      Subject: this.state.subject,
      Message: this.state.emailMessage,
      UnitBuilding: this.state.building === "All" ? "" : this.state.building,
    };
    axios
      .post("/venuemgmtconsole/subscribers/sendMessage", body, {
        headers: {},
      })
      .then((response) => {
        toast.success("Message Sent!", {
          closeButton: false,
        });
        this.setState({
          modalMessage: !this.state.modalMessage,
          loading: false,
        });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.setState({
          error: true,
          loading: false,
        });
      });
  };
  handleEditorChange(emailMessage, editor) {
    this.setState({ emailMessage });
  }
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { t } = this.props;
    if (this.state.postMessage) {
      var h = document.getElementById("overlaySettings");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage: "" });
      }, 6000);
    }
    return (
      <>
        {this.props.loading === true ? (
          <div id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <MDBModal isOpen={this.state.modalMessage} toggle={this.toggleMessage} centered size="lg">
          <MDBModalHeader toggle={this.toggleMessage}>Send Message to Property</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol col="6" className="mb-2">
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  {t("addnewresident.building")}
                </label>
                <div className="select-box">
                <select className="browser-default custom-select" name="building" required onChange={this.changeHandler} value={this.state.building}>
                  <option value="" disabled selected>
                    {t("addnewresident.select")}
                  </option>
                  <option value="All">All Buildings</option>
                  {this.props.buildings.map((value, index) => {
                    return (
                      <option key={value} val={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                </div>
                <div className="invalid-feedback">Please provide a building.</div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol col="12">
                <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Subject
                </label>
                <input name="subject" id="subject" className="form-control" onChange={this.changeHandler} value={this.state.subject} />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol col="6">
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea
									className="form-control"
									id="emailMessage"
									name="emailMessage"
									rows="10"
									onChange={this.changeHandler}
									value={this.state.emailMessage}>
								</textarea>	
                {/* <Editor
                  apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
                  value={this.state.emailMessage}
                  outputFormat="html"
                  init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
                  }}
                  onEditorChange={this.handleEditorChange}
                /> */}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.submitMessage}>
              {this.state.loading === true ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Send"
              )}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModalHeader toggle={this.props.togglePropertySettings}>
          <VenueDropdown getVenues={this.props.getVenues} updateName={this.props.updateName} />
          <h4>{t("settingsnav.title")}</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="managementModal">
            {sessionStorage.getItem("adminOf").includes("VenueAdministrator") === true ? (
              <>
                <MDBRow>
                  <MDBCol md="4">
                    <div className="managementModalButton" onClick={this.toggleAddStaff}>
                      <MDBIcon icon="user-plus" />
                      <p>Add Venue Administrator</p>
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <div className="managementModalButton" onClick={this.toggleModifyStaff}>
                      <MDBIcon icon="edit" />
                      <p>Modify Venue Administrator</p>
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <div className="managementModalButton" onClick={this.toggleResendActivation}>
                      <MDBIcon icon="envelope-open" />
                      <p>{t("settingsnav.resend")}</p>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4">
                    <div className="managementModalButton" onClick={this.togglePropertyInfo}>
                      <MDBIcon icon="building" />
                      <p>{t("settingsnav.propinfo")}</p>
                    </div>
                  </MDBCol>
                  <MDBCol md="4">
                    <div className="managementModalButton" onClick={this.toggleMessage}>
                      <MDBIcon icon="comment-alt" />
                      <p>Message Residents</p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </>
            ) : (
              <>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="managementModalButton" onClick={this.toggleResendActivation}>
                      <MDBIcon icon="envelope-open" />
                      <p>{t("settingsnav.resend")}</p>
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="managementModalButton" onClick={this.toggleMessage}>
                      <MDBIcon icon="comment-alt" />
                      <p>Message Residents</p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </>
            )}
          </div>
        </MDBModalBody>
        <MDBModal isOpen={this.state.modalAddStaff} toggle={this.toggle} size="lg" className="form" centered>
          <AddStaff toggleAddStaff={this.toggleAddStaff} submitHandler={this.submitHandler} venueID={this.props.venueID} loading={this.state.loading} />
        </MDBModal>
        <MDBModal isOpen={this.state.modalModifyStaff} toggle={this.toggle} size="lg" className="form" centered>
          <ModifyStaff
            toggleModifyStaff={this.toggleModifyStaff}
            submitHandler={this.submitHandler}
            venueID={this.props.venueID}
            venueAdmins={this.props.venueAdmins}
            leasingAgents={this.props.leasingAgents}
            updateName={this.props.updateName}
            venueName={this.props.venueNameSelected}
            getAdmins={this.getAdmins}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalResendActivation} toggle={this.toggle} size="lg" className="form" centered>
          <ResendActivation
            toggleResendActivation={this.toggleResendActivation}
            recentResidents={this.props.activeData}
            submitHandlerResendActivation={this.submitHandlerResendActivation}
            loading={this.state.loading}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalPropertyInfo} toggle={this.toggle} size="lg" className="form" centered>
          <PropertyInfo
            togglePropertyInfo={this.togglePropertyInfo}
            venueID={this.props.venueID}
            venueData={this.props.venueData}
            updateName={this.props.updateName}
            venueName={this.props.venueNameSelected}
            loading={this.state.loading}
          />
        </MDBModal>
      </>
    );
  }
}
export default withTranslation()(PropertyManagementNavigation);