import React, { Component } from "react";
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBLink,
    toast,
} from "mdbreact";
import AddNewResident from "../../../components/VenueMgmt/Forms/AddNewResident/AddNewResident";
import AddNewGuest from "../../../components/VenueMgmt/Forms/AddNewGuest/AddNewGuest";
import AddModifyStaff from "../../../components/VenueMgmt/PropertyInfo/AddModifyStaff";
import axios from "../../../axios";
import { withTranslation } from "react-i18next";
import { MDBModalBody, MDBModalHeader, MDBModalFooter } from "mdbreact";
class QuickLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddNewResident: false,
            modalAddNewGuest: false,
            modalAddModifyStaff: false,
            modalMessage: false,
            loading: false,
            building: "All",
            paymentFailed: false,
            modalAddPayment: true
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }
    toggleAddNewResident = () => {
        this.setState({
            modalAddNewResident: !this.state.modalAddNewResident,
        });
    };
    toggleAddNewGuest = () => {
        this.setState({
            modalAddNewGuest: !this.state.modalAddNewGuest,
        });
    };
    toggleAddModifyStaff = () => {
        this.setState({
            modalAddModifyStaff: !this.state.modalAddModifyStaff,
        });
    };
    toggleMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
            subject: undefined,
            emailMessage: undefined,
        });
    };
    togglePayment = () =>{
        this.setState({
                modalAddPayment: !this.state.modalAddPayment,
            });
    }
    submitHandler = (
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        unitID,
        building,
        floor,
        contactpref_email,
        contactpref_sms,
        dateStart,
        unitID_internal,
        servicePlan
    ) => {
        let isoDateString;
        let isoDateStart;
        let body;
        this.setState({ loading: true });
        if (!isNaN(new Date(dateStart))) {
            isoDateStart = new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        if (!isNaN(new Date(dateEnd))) {
            isoDateString = new Date(dateEnd)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
            body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                EmailAddress: email,
                KeyPoolID: this.props.keypoolID[0],
                GivenName: firstName,
                FamilyName: lastName,
                "PhoneNumber.E164": phoneNumber,
                locale: language,
                "EndDateTimeUTC.ISO8601": isoDateString,
                VenueID: this.props.venueID,
                PolicyforSubscriberKey: {
                    UnitID_internal: unitID_internal,
                },
                contactpref_email: true,
                contactpref_sms: contactpref_sms,
                "StartDateTimeUTC.ISO8601": isoDateStart,
                UserTimeZone: new Date().getTimezoneOffset(),
                ServicePlanID: servicePlan
            };
        } else {
            body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                EmailAddress: email,
                KeyPoolID: this.props.keypoolID[0],
                GivenName: firstName,
                FamilyName: lastName,
                "PhoneNumber.E164": phoneNumber,
                locale: language,
                VenueID: this.props.venueID,
                PolicyforSubscriberKey: {
                    UnitID_internal: unitID_internal,
                },
                contactpref_email: true,
                contactpref_sms: contactpref_sms,
                "EndDateTimeUTC.ISO8601": undefined,
                "StartDateTimeUTC.ISO8601": isoDateStart,
                UserTimeZone: new Date().getTimezoneOffset(),
                ServicePlanID: servicePlan
            };
        }
        if(phoneNumber!==undefined){
            if (phoneNumber.length === 10) {
                phoneNumber = "+1" + phoneNumber;
            }
        }
        axios
            .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Resident!", {
                        closeButton: false,
                    });
                    this.toggleAddNewResident();
                    this.props.updateName(
                        this.props.venueName,
                        this.props.venueID
                    );
                    this.setState({ loading: false });
                    if(response.data.paymentFailed){
                        this.setState({
                            paymentFailed: true
                        })
                    }
                    sessionStorage.setItem("unitID_internalSubscriber", unitID_internal)
                    sessionStorage.setItem("stripeClientSecretSubscriber", response.data.StripeClientSecret)
                },
                (error) => {
                    toast.error(error.response.data.message, {
                        closeButton: false,
                    });
                    this.setState({ loading: false });
                }
            );
    };
    submitHandlerGuest = (
        dateEnd,
        email,
        firstName,
        lastName,
        phoneNumber,
        language,
        unitID,
        building,
        floor,
        contactpref_email,
        contactpref_sms,
        guest,
        dateStart,
        unitID_internal
    ) => {
        let isoDateString;
        let isoDateStart;
        this.setState({ loading: true });
        if (!isNaN(new Date(dateEnd))) {
            isoDateString = new Date(dateEnd)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        if (!isNaN(new Date(dateStart))) {
            isoDateStart = new Date(dateStart)
                .toISOString()
                .replace(/:00.\d+Z$/g, "Z");
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            EmailAddress: email,
            KeyPoolID: this.props.keypoolID[0],
            GivenName: firstName,
            FamilyName: lastName,
            "PhoneNumber.E164": phoneNumber,
            locale: language,
            "EndDateTimeUTC.ISO8601": isoDateString,
            VenueID: this.props.venueID,
            contactpref_email: true,
            contactpref_sms: contactpref_sms,
            PolicyforSubscriberKey: {
                UnitID_internal: unitID_internal,
            },
            GuestKey: guest,
            "StartDateTimeUTC.ISO8601": isoDateStart,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        axios
            .post("/venuemgmtconsole/subscribers/addSubscriber", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Added Guest!", {
                        closeButton: false,
                    });
                    this.toggleAddNewGuest();
                    this.props.updateName(
                        this.props.venueName,
                        this.props.venueID
                    );
                    this.setState({ loading: false });
                },
                (error) => {
                    toast.error("There was an error!", {
                        closeButton: false,
                    });
                    this.toggleAddNewGuest();
                    this.setState({ loading: false });
                }
            );
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    submitMessage = () => {
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: this.props.venueID,
            Subject: this.state.subject,
            Message: this.state.emailMessage,
            UnitBuilding:
                this.state.building === "All" ? "" : this.state.building,
        };
        axios
            .post("/venuemgmtconsole/subscribers/sendMessage", body, {
                headers: {},
            })
            .then((response) => {
                toast.success("Message Sent!", {
                    closeButton: false,
                });
                this.setState({
                    modalMessage: !this.state.modalMessage,
                    loading: false,
                });
            })
            .catch((error) => {
                toast.error("There was an error!", {
                    closeButton: false,
                });
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    };
    handleEditorChange(emailMessage, editor) {
        this.setState({ emailMessage });
    }
    render() {
        const { t } = this.props;
        if (this.state.postMessage) {
            var h = document.getElementById("overlayAdd");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
                this.props.refresh();
            }, 6000);
        }
        return (
            <>
                {this.state.paymentFailed ? 
                    <MDBModal
                    isOpen={this.state.modalAddPayment}
                    toggle={this.togglePayment}
                    size="md"
                    className="form"
                    centered
                    >
                    <MDBModalHeader toggle={this.togglePayment}>
                        Modify Billing Credit Card Info
                    </MDBModalHeader>
                    <MDBModalBody className="error">
                                <h4>The subscriber's credit card info needs to be updated for billing purposes.</h4>
                                <MDBBtn
											color="danger"
											className="btn Ripple-parent btn-default centered upgradePlan"
											href={
												"/update/checkout?ui=" +
												window.location.pathname.slice(
													1
												)
											}
										>
											Modify Billing CC
										</MDBBtn>
                    </MDBModalBody>
                    </MDBModal>
                :""}
                <div className="quicklinks">
                    <MDBRow>
                        {this.props.venueType === "HOTSPOT" ? (
                            <>
                                <MDBCol>
                                    <div className="quicklink">
                                        <MDBLink
                                            to="#"
                                            onClick={this.toggleAddNewGuest}
                                        >
                                            <MDBIcon icon="user-plus" />
                                            <p className="">
                                                Add New Subscriber
                                            </p>
                                        </MDBLink>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className="quicklink">
                                        <MDBLink
                                            to="#"
                                            onClick={this.toggleAddNewResident}
                                        >
                                            <MDBIcon icon="stopwatch" />
                                            <p className="">
                                                Add New
                                                <br />
                                                Local Policy
                                            </p>
                                        </MDBLink>
                                    </div>
                                </MDBCol>
                            </>
                        ) : (
                            <>
                                <MDBCol>
                                    <div className="quicklink">
                                        <MDBLink
                                            to="#"
                                            onClick={this.toggleAddNewResident}
                                        >
                                            <MDBIcon icon="user-plus" />
                                            <p className="">
                                                {t("quicklinks.addresident")}
                                            </p>
                                        </MDBLink>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className="quicklink">
                                        <MDBLink
                                            to="#"
                                            onClick={this.toggleAddNewGuest}
                                        >
                                            <MDBIcon icon="stopwatch" />
                                            <p className="">
                                                {t("quicklinks.addguest")}
                                            </p>
                                        </MDBLink>
                                    </div>
                                </MDBCol>
                            </>
                        )}
                        {sessionStorage
                            .getItem("adminOf")
                            .includes("VenueAdministrator") === true ? (
                            <MDBCol>
                                <div className="quicklink">
                                    <MDBLink
                                        to="#"
                                        onClick={this.toggleAddModifyStaff}
                                    >
                                        <MDBIcon icon="edit" />
                                        <p className="">
                                            {t("quicklinks.modifyservice")}
                                        </p>
                                    </MDBLink>
                                </div>
                            </MDBCol>
                        ) : (
                            ""
                        )}
                        <MDBCol>
                            <div className="quicklink">
                                <MDBLink to="#" onClick={this.toggleMessage}>
                                    <MDBIcon icon="envelope" />
                                    <p className="">
                                        Send
                                        <br /> Message
                                    </p>
                                </MDBLink>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBModal
                    isOpen={this.state.modalAddNewResident}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddNewResident
                        toggleAddNewResident={this.toggleAddNewResident}
                        keypoolID={this.props.keypoolID}
                        venueID={this.props.venueID}
                        submitHandler={this.submitHandler}
                        loading={this.state.loading}
                        venueType={this.props.venueType}
                        availablePlans={this.props.availablePlans}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalAddNewGuest}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddNewGuest
                        toggleAddNewGuest={this.toggleAddNewGuest}
                        keypoolID={this.props.keypoolID}
                        venueID={this.props.venueID}
                        submitHandlerGuest={this.submitHandlerGuest}
                        loading={this.state.loading}
                        venueType={this.props.venueType}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalAddModifyStaff}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <AddModifyStaff
                        toggleModifyStaff={this.toggleAddModifyStaff}
                        keypoolID={this.props.keypoolID}
                        venueID={this.props.venueID}
                        loading={this.state.loading}
                        updateName={this.props.updateName}
                        venueNameSelected={this.props.venueName}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalMessage}
                    toggle={this.toggleMessage}
                    centered
                    size="lg"
                >
                    <MDBModalHeader toggle={this.toggleMessage}>
                        Send Message to Property
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol col="6" className="mb-2">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    {t("addnewresident.building")}
                                </label>
                                <div className="select-box">
                                <select
                                    className="browser-default custom-select"
                                    name="building"
                                    required
                                    onChange={this.changeHandler}
                                    value={this.state.building}
                                >
                                    <option value="" disabled selected>
                                        {t("addnewresident.select")}
                                    </option>
                                    <option value="All">All Buildings</option>
                                    {this.props.buildings.map(
                                        (value, index) => {
                                            return (
                                                <option key={value} val={value}>
                                                    {value}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                                </div>
                                <div className="invalid-feedback">
                                    Please provide a building.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="12">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Subject
                                </label>
                                <input
                                    name="subject"
                                    id="subject"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    value={this.state.subject}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Message
                                </label>
                                <textarea
									className="form-control"
									id="emailMessage"
									name="emailMessage"
									rows="10"
									onChange={this.changeHandler}
									value={this.state.emailMessage}>
								</textarea>	
                                {/* <Editor
                                    apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
                                    value={this.state.emailMessage}
                                    outputFormat="html"
                                    init={{
                                        height: 300,
                                        menubar: true,
                                        plugins: [
                                            "advlist autolink lists link image charmap print preview anchor",
                                            "searchreplace visualblocks code fullscreen",
                                            "insertdatetime media table paste code help wordcount",
                                        ],
                                        toolbar:
                                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                /> */}
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.submitMessage}>
                            {this.state.loading === true ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Send"
                            )}
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(QuickLinks);