import React, { Component } from "react";
import {
  MDBBtn,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBModal,
  toast,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBInput,
} from "mdbreact";
import axios from "../../../../axios";
import { DateInput } from "semantic-ui-calendar-react";
import { withTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
import MessagesData from "../../../../components/VenueMgmt/Messages/Datatables/MessagesData";
import Bandwidth from "../../../../components/VenueMgmt/Bandwidth/Bandwidth";
import SubscriberKeypoolsData from "../../../../components/NetworkMgmt/Subscribers/Datatables/SubscriberKeypoolsData";
import SubscriberTransactionsData from "../../../../components/NetworkMgmt/Subscribers/Datatables/SubscriberTransactionsData";
import AddKey from "../../../../components/NetworkMgmt/Forms/AddKey/AddKey";
import ManageDevicesData from "../../../../components/SubscriberMgmt/ManageDevices/Datatables/ManageDevicesData";
import DeviceDetails from "../../../../components/SubscriberMgmt/DeviceInformation/DeviceDetails";
import parse from "html-react-parser";
import SelectSearch, { fuzzySearch } from "react-select-search";
import countryInfo from "country-locale-map";
import getSymbolFromCurrency from "currency-symbol-map";
import _ from "lodash";
import brandsData from "../../../../brands/common/brands.json";
import JSONPretty from "react-json-pretty";
class ModifyResident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      venueName: this.props.venueName,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      phoneNumber: this.props.phoneNumber,
      selectedLanguage: this.props.language,
      floor: this.props.floor,
      building: this.props.building,
      receivetext: this.props.receivetext,
      receivephone: this.props.receivephone,
      dateStart:
        this.props.startDate !== null
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(this.props.startDate))
          : "",
      oldDateStart: this.props.startDate,
      dateEnd:
        this.props.endDate !== ""
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(this.props.endDate))
          : undefined,
      oldDateEnd:
        this.props.endDate !== ""
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(this.props.endDate))
          : undefined,
      unitID: this.props.unitID,
      unitID_internal: this.props.unitID_internal,
      recentResidents: this.props.recentResidents,
      venueID: this.props.venueID,
      loading: false,
      loadingDelete: false,
      loadingMessages: true,
      ssid: this.props.SSID,
      activeItem: "1",
      activeItemRefund: "1",
      selectedServicePlan: this.props.servicePlan,
      selectedServicePlanID: this.props.servicePlanID,
      loadingResend: false,
      availablePlans: this.props.availablePlans,
      vlan: this.props.vlan,
      status: this.props.status,
      languageOptions: [
        { value: "en-us", name: "English" },
        { value: "es-xl", name: "Spanish" },
      ],
      managedBySSO: false,
      subscriberManagedviaSSO: this.props.subscriberManagedviaSSO,
      realpageSyncDate: this.props.realpageSyncDate,
      paymentFailed: this.props.paymentFailed,
      StripeClientSecret: this.props.StripeClientSecret,
      stripeClientParentSecret: this.props.stripeClientParentSecret,
      other: false,
      reason: [],
      radio: [],
      subscriberToken: this.props.subscriberToken,
      RealPageUnitID: this.props.RealPageUnitID,
      modalRefund: false,
      currencyCode: countryInfo.getCurrencyByAlpha3(this.props.country),
      subscriptionDiscount: -Math.abs(this.props.subscriptionDiscount) / 100,
      venuePriceAdjustment:
        getSymbolFromCurrency(
          countryInfo.getCurrencyByAlpha3(this.props.country)
        ) + (-Math.abs(this.props.venueDiscount) / 100).toFixed(2),
      venuePriceAdjustmentNumber: this.props.venueDiscount,
      contactpref_sms: this.props.contactpref_sms,
      contactpref_email: this.props.contactpref_email,
      dateAdded: this.props.dateAdded,
      selectedServicePlanPrice: this.props.selectedServicePlanPrice,
      selectedServicePlanPriceNumber: (
        this.props.selectedServicePlanPrice / 100
      ).toFixed(2),
      setupIntentID: this.props.setupIntentID,
      refundAmount: 0,
      transactions: undefined,
      status: this.props.active,
    };
  }

  validateEmail = (email) => {
    this.setState({ loading: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: this.props.venueID,
    };
    axios
      .post("/venuemgmtconsole/subscribers/validateEmailAddress", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          firstName: response.data.GivenName,
          lastName: response.data.FamilyName,
          phoneNumber: response.data["PhoneNumber.E164"],
          selectedLanguage: response.data.locale,
          invalidPhone: undefined,
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ error: true });
        this.setState({ loading: false });
      });
  };
  getVenueUnitDetails = async (index) => {
    sessionStorage.setItem("venueIDSubscriber", index);
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      UnitID_internal: sessionStorage.getItem("unitID_internalSubscriber"),
    };
    this.setState({
      unitData: undefined,
      expiringData: undefined,
      searchData: undefined,
      value: "",
    });
    await axios
      .post("/networkoperatorconsole/venues/listVenueUnitDetails", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          unitData: response.data["VenueUnits"],
        });
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  };

  getVenueDetails = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: sessionStorage.getItem("venueID"),
    };
    let index = sessionStorage.getItem("venueID");
    axios
      .post("/venuemgmtconsole/venues/listVenueDetails", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          venueServicePlans: response.data[index]["AvailableServicePlans"],
          availablePlans: response.data[index]["AvailableServicePlans"],
          venueName: response.data[index]["VenueName"],
          vlan: response.data[index]["vlan"],
        });
        let units = Object.keys(
          response.data[index]["Units-Building-Floor-List-Subscribers"]
        );
        let buildings = [];
        let objUnits = {};
        for (let i = 0; i < units.length; i++) {
          buildings.push(
            Object.keys(
              response.data[index]["Units-Building-Floor-List-Subscribers"][
                units[i]
              ]
            )
          );
          for (let j = 0; j < buildings[i].length; j++) {
            let floor = Object.keys(
              response.data[index]["Units-Building-Floor-List-Subscribers"][
                units[i]
              ][buildings[i][j]]
            );
            for (let k = 0; k < floor.length; k++) {
              objUnits[i] =
                response.data[index]["Units-Building-Floor-List-Subscribers"][
                  units[i]
                ][buildings[i]];
            }
          }
        }
        let optionTemplateUnits = [];
        Object.keys(objUnits).map((oneKey, i) => {
          optionTemplateUnits.push({
            value: units[oneKey],
            name: units[oneKey],
          });
          return true;
        });

        this.setState({
          optionTemplateUnits: optionTemplateUnits,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: true });
      });
  };

  submitHandler = (event) => {
    let isUpdated;
    let isUpdatedServicePlan;
    let isUpdatedStartDate;
    let isUpdatedEndDate;
    let isUpdatedSubscriptionDiscount;
    event.preventDefault();
    event.target.className += " was-validated";

    this.props.resubmitModify(
      this.state.email,
      this.state.firstName,
      this.state.lastName,
      this.state.phoneNumber,
      this.state.selectedLanguage,
      this.state.venueID,
      this.state.optionTemplateUnits,
      this.state.optionTemplateBuilding,
      this.props.venueUnitData[0]["UnitID"],
      this.props.venueUnitData[0]["UnitBuilding"],
      this.props.venueUnitData[0]["UnitFloor"],
      this.state.selectedServicePlanID,
      this.props.unitID_internal
    );
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  changeHandlerPhone = (event) => {
    this.setState({ phoneNumber: event });
  };
  toggleCheckboxValueSMS = () => {
    this.setState({ contactpref_sms: !this.state.contactpref_sms });
  };
  toggleCheckboxValueEmail = () => {
    this.setState({ contactpref_email: !this.state.contactpref_email });
  };
  createMask = () => {
    this.setState({
      phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
    });
  };

  render() {
    // console.log("EndedSubscription/this.state: ", this.state)
    sessionStorage.setItem(
      "stripeClientSecretSubscriber",
      this.props.StripeClientSecret
    );
    sessionStorage.setItem(
      "unitID_internalSubscriber",
      this.props.unitID_internal
    );
    sessionStorage.setItem("servicePlanIDSubscriber", this.props.servicePlanID);
    sessionStorage.setItem("newServicePlanID", this.props.servicePlanID);
    sessionStorage.setItem("emailSubscriber", this.props.email);
    sessionStorage.setItem("dateStart", this.props.startDate);
    const { t } = this.props;
    const FORMAT = "MM/dd/yyyy";
    let showUpdate;
    let cancelInputType;
    let showTransactions;
    brandsData.forEach(
      ({
        name,
        url,
        cancel_input_type,
        phone_number,
        flag_update,
        flag_showTransactions,
      }) => {
        if (url.includes(window.location.hostname)) {
          showUpdate = flag_update;
          cancelInputType = cancel_input_type;
          showTransactions = flag_showTransactions;
        }
      }
    );
    return (
      <>
        {this.props.venueUnitData === undefined ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <MDBModalHeader toggle={this.props.toggleEndedSubscriptionInfo}>
              <h4>Ended Subscription Info</h4>
            </MDBModalHeader>
            <MDBModalBody>
              <form
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <MDBRow>
                  <MDBCol col="6" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      Venue
                    </label>
                    <div className="residentEmail">
                      <input
                        disabled={true}
                        id="email"
                        className="form-control"
                        
                        value={this.state.venueName}
                      />
                    </div>
                    <div className="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </MDBCol>
                  <MDBCol col="6" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.email")}
                    </label>
                    <div className="residentEmail">
                      <input
                        disabled={true}
                        id="email"
                        className="form-control"
                        onBlur={(event) => this.validateEmail(this.state.email)}
                        onChange={(event) =>
                          this.setState({
                            email: event.target.value,
                          })
                        }
                        value={this.state.email}
                      />
                    </div>
                    <div className="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.firstname")}
                    </label>
                    <input
                      required
                      disabled={true}
                      name="firstName"
                      id="firstName"
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      value={this.state.firstName}
                    />
                    <div className="invalid-feedback">
                      Please provide a valid first name.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.lastname")}
                    </label>
                    <input
                      required
                      disabled={true}
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      name="lastName"
                      id="lastName"
                      value={this.state.lastName}
                    />
                    <div className="invalid-feedback">
                      Please provide a valid last name.
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol col="6" sm="12" lg="6">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.phone")}
                    </label>
                    <PhoneInput
                      disabled={true}
                      placeholder={t("addnewresident.enterphone")}
                      value={this.state.phoneNumber}
                      format={FORMAT}
                      name="phoneNumber"
                      id="phoneNumber"
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      defaultCountry="US"
                    />
                    {this.state.invalidPhone !== null ? (
                      <div className="invalid">{this.state.invalidPhone}</div>
                    ) : (
                      ""
                    )}
                  </MDBCol>
                  <MDBCol col="6">
                    <div>
                      <label
                        htmlFor="defaultFormLoginEmailEx"
                        className="black-text"
                      >
                        {t("addnewresident.language")}
                      </label>
                      <input
                        disabled={true}
                        // id="selectLanguage"
                        search={true}
                        filterOptions={fuzzySearch}
                        className={
                          this.state.loading === true
                            ? "form-control loading"
                            : "form-control"
                        }
                        value={this.state.selectedLanguage==="en-us"?"English":"Spanish"}
                      />
                      <div className="invalid-feedback">
                        Please provide a valid language.
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="12" lg="4">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.unit")}
                    </label>
                    <input
                      // id="selectUnit"
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      disabled={true}
                      classNamePrefix="unit-select"
                      placeholder="Select..."
                      value={this.props.venueUnitData[0]["UnitID"]}
                    />
                    <div className="invalid-feedback">
                      Please provide a unit number.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="4">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.building")}
                    </label>
                    <input
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      classNamePrefix="building-select"
                      placeholder="Select..."
                      value={this.props.venueUnitData[0]["UnitBuilding"]}
                      disabled={true}
                    />
                    <div className="invalid-feedback">
                      Please provide a building.
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" lg="4">
                    <label
                      htmlFor="defaultFormLoginEmailEx"
                      className="black-text"
                    >
                      {t("addnewresident.floor")}
                    </label>
                    <input
                      className={
                        this.state.loading === true
                          ? "form-control loading"
                          : "form-control"
                      }
                      classNamePrefix="floo-select"
                      value={this.props.venueUnitData[0]["UnitFloor"]}
                      disabled={true}
                    />
                    <div className="invalid-feedback">
                      Please provide a floor.
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow></MDBRow>
                {showTransactions ? (
                  <>
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Service Plan
                        </label>
                        <input
                          className={
                            this.state.loading === true
                              ? "form-control loading"
                              : "form-control"
                          }
                          classNamePrefix="service-plan-select form-control"
                          value={this.props.servicePlan}
                          disabled={true}
                        />
                        <div className="invalid-feedback">
                          Please provide a service plan.
                        </div>
                      </MDBCol>

                      {window.location.pathname === "/networkdashboard" ? (
                        <>
                          <MDBCol sm="12" lg="6">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              VLAN
                            </label>
                            <input
                              disabled
                              id="vlan"
                              className="form-control"
                              value={this.props.venueUnitData[0]["vlan"]}
                            />
                            <div className="invalid-feedback">
                              Please provide a vlan.
                            </div>
                          </MDBCol>
                        </>
                      ) : (
                        ""
                      )}
                    </MDBRow>
                    {this.state.realpageSyncDate !== "" ? (
                      <>
                        <MDBRow>
                          <MDBCol col="6">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              Last sync with RealPage
                            </label>
                            <input
                              disabled
                              id="realpageSyncDateUTC"
                              className="form-control"
                            />
                          </MDBCol>
                          <MDBCol col="6">
                            <label
                              htmlFor="defaultFormLoginEmailEx"
                              className="black-text"
                            >
                              RealPage Unit ID
                            </label>
                            <input
                              disabled
                              id="RealPageUnitID"
                              className="form-control"
                              value={this.state.RealPageUnitID}
                            />
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      ""
                    )}
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.servicestart")}
                        </label>
                        <input
                          dateFormat="MM/DD/YYYY"
                          name="dateStart"
                          placeholder="MM/DD/YYYY"
                          value={this.state.dateStart}
                          iconPosition="left"
                          onChange={this.handleChange}
                          className={
                            this.state.loading === true
                              ? "form-control loading"
                              : "form-control"
                          }
                          disabled={true}
                        />
                        <label
                          for="serviceStart"
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {this.props.venueType === "HOTSPOT"
                            ? "Local Policy can use their Wi-Fi password as of this date"
                            : t("addnewresident.servicestartlabel")}
                        </label>
                      </MDBCol>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.serviceend")}
                        </label>
                        <input
                          dateFormat="MM/DD/YYYY"
                          name="dateEnd"
                          className={
                            this.state.loading === true
                              ? "form-control loading"
                              : "form-control"
                          }
                          value={
                            isNaN(new Date(this.state.dateEnd)) === true &&
                            this.state.invalidDateEnd === null
                              ? Intl.DateTimeFormat("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }).format(new Date(this.state.dateEnd))
                              : this.state.dateEnd
                          }
                          iconPosition="left"
                          disabled={true}
                        />
                        <label
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {t("addnewresident.serviceendlabel")}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  <>
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          Service Plan
                        </label>
                        <SelectSearch
                          id="selectServicePlan"
                          search={true}
                          filterOptions={fuzzySearch}
                          options={this.state.optionTemplateServicePlan}
                          onChange={this.handleSelectChangeServicePlan}
                          isOptionSelected={true}
                          inputId="servicePlanArray"
                          classNamePrefix="service-plan-select form-control"
                          value={this.state.selectedServicePlanIndex}
                          placeholder="Select..."
                        />
                        <div className="invalid-feedback">
                          Please provide a service plan.
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.servicestart")}
                        </label>
                        <DateInput
                          dateFormat="MM/DD/YYYY"
                          name="dateStart"
                          placeholder="MM/DD/YYYY"
                          value={this.state.dateStart}
                          iconPosition="left"
                          onChange={this.handleChange}
                          className="dateTimePicker"
                          disabled={
                            new Date(this.state.dateStart) < new Date() ||
                            this.state.realpageSyncDate !== "" ||
                            this.state.subscriberManagedviaSSO === true
                              ? true
                              : false
                          }
                        />
                        <label
                          for="serviceStart"
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {this.props.venueType === "HOTSPOT"
                            ? "Local Policy can use their Wi-Fi password as of this date"
                            : t("addnewresident.servicestartlabel")}
                        </label>
                      </MDBCol>
                      <MDBCol sm="12" lg="6">
                        <label
                          htmlFor="defaultFormLoginEmailEx"
                          className="black-text"
                        >
                          {t("addnewresident.serviceend")}
                        </label>
                        <DateInput
                          dateFormat="MM/DD/YYYY"
                          name="dateEnd"
                          placeholder="MM/DD/YYYY"
                          initialDate={new Date(this.state.dateStart)}
                          value={
                            isNaN(new Date(this.state.dateEnd)) === true &&
                            this.state.invalidDateEnd === null
                              ? Intl.DateTimeFormat("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }).format(new Date(this.state.dateEnd))
                              : this.state.dateEnd
                          }
                          iconPosition="left"
                          onChange={this.handleChange}
                          className="dateTimePicker"
                          minDate={new Date(this.state.dateStart)}
                          disabled={
                            this.state.realpageSyncDate !== "" ||
                            this.state.subscriberManagedviaSSO === true
                              ? true
                              : false
                          }
                        />
                        <label
                          className="black-text"
                          style={{
                            fontSize: "11px",
                            position: "absolute",
                            top: "62px",
                            left: "15px",
                          }}
                        >
                          {t("addnewresident.serviceendlabel")}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}

                <div className="text-right submitButton">
                  <MDBBtn color="danger" type="submit">
                    {this.props.loading === true ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : this.props.venueType === "HOTSPOT" ? (
                      "Save Changes"
                    ) : (
                      "Re-Create Subscription"
                    )}
                  </MDBBtn>
                </div>
              </form>
            </MDBModalBody>
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(ModifyResident);
