import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;

    let datatable;
    [datatable] = React.useState("");
    if (props.coreEquipment !== undefined) {
        userAttributes = [];
        for (const key of Object.keys(props.coreEquipment["Devices"][0])) {
            userAttributes.push({
                Venue: props.selectedName, //thats not going to work for roll-ups.
                device: props.coreEquipment["Devices"][0][key]["EquipmentID"],
                type: props.coreEquipment["Devices"][0][key]["EquipmentType"],
                status: props.coreEquipment["Devices"][0][key]["Status"],
                lastseen:
                    props.coreEquipment["Devices"][0][key]["statusupdateDateTimeUTC.ISO8601"] !== ""
                        ? Intl.DateTimeFormat("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                          }).format(new Date(props.coreEquipment["Devices"][0][key]["statusupdateDateTimeUTC.ISO8601"]))
                        : "",
            });
        }
    }

    [datatable] = React.useState({
        columns: [
            {
                label: "Venue",
                field: "Venue",
            },
            {
                label: "Device Name",
                field: "device",
            },
            {
                label: "Device Type",
                field: "type",
            },
            {
                label: "Status",
                field: "status",
            },
            {
                label: "Last Seen",
                field: "lastseen",
            },
        ],
        rows: userAttributes,
    });
    return (
        <MDBDataTableV5
            responsive
            hover
            searchTop={true}
            searchBottom={false}
            searchLabel="Filter"
            data={datatable}
            paging={false}
            className="recentResidents"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
        />
    );
}