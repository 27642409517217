/* Language selector in navigation */

import React from "react";

import { useTranslation } from "react-i18next";

function LanguageSwitcher(props) {
    const { i18n } = useTranslation();

    return (
        <div className="select">
        <select
            value={i18n.language}
            onChange={(e) =>

                {i18n.changeLanguage(e.target.value)
                props.listKeys()}
            }
            className=""
        >
        <option value="en-US">English</option>
        <option value="es-SP">Spanish</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;