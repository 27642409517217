import React, { Component } from "react";

import { MDBRow, MDBCol, MDBBtn, MDBSpinner, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, toast } from "mdbreact";

import ModifyStaffTable from "../../../components/VenueMgmt/PropertyInfo/Datatables/ModifyStaffTable";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";




class ModifyStaff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			staffArrayAll: undefined,
		};
	}

	componentDidMount() {
		this.getVenueDetails(this.props.venueID);
	}
	getVenueDetails = (index) => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: index,
			UserTimeZone: new Date().getTimezoneOffset(),
		};
		this.setState({
			venueAdmins: undefined,
			leasingAgents: undefined,
			staffArrayAll: undefined,
		});
		axios
			.post("/venuemgmtconsole/venues/listVenueDetails", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					venueAdmins: response.data[index]["VenueAdministrators"],
					leasingAgents: response.data[index]["LeasingAgents"],
				});
				this.getAdmins();
			})
			.catch((error) => {
				this.getVenueDetails(index);
				this.setState({ error: true });
			});
	};
	getAdmins = () => {
		this.setState({ staffArrayAll: undefined });
		let staffArrayAll = [];
		let staffArrayVA = [];
		let staffArrayLeasing = [];
		for (var i = 0; i < this.state.venueAdmins.length; i++) {
			staffArrayVA[i] = { [this.state.venueAdmins[i]]: "Venue Administrator" };
		}
		for (var j = 0; j < this.state.leasingAgents.length; j++) {
			staffArrayLeasing[j] = { [this.state.leasingAgents[j]]: "Leasing Agent" };
		}
		staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
		this.setState({ staffArrayAll: [...staffArrayAll] });
	};

	toggleConfirmDisable = (key) => {
		this.setState({
			toggleConfirm: true,
			modal: !this.state.modal,

			confirmEmail: key[0],
		});
	};

	toggleDisable = (email) => {
		this.setState({ staffArrayAll: undefined });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: this.props.venueID,
			EmailAddress: email,
		};
		axios
			.post("/venuemgmtconsole/staff/disableStaffAccess", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Deleted", {
						closeButton: false,
					});
					this.setState({
						modal: !this.state.modal,
					});
					this.props.updateName(this.props.venueName, this.props.venueID);
					this.getVenueDetails(this.props.venueID);
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
				}
			);
	};
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	render() {
		return (
			<>
				{this.state.toggleConfirm ? (
					<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
						<MDBModalHeader toggle={this.toggle}>Confirm Delete</MDBModalHeader>
						<MDBModalBody>
							<p>Email: {this.state.confirmEmail}</p>
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn color="primary" onClick={() => this.toggleDisable(this.state.confirmEmail)}>
								Confirm
							</MDBBtn>
							<MDBBtn color="primary" onClick={this.toggle}>
								Close
							</MDBBtn>
						</MDBModalFooter>
					</MDBModal>
				) : null}
				<MDBModalHeader toggle={this.props.toggleModifyStaff}>
					<h4>Modify Staff</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<MDBRow>
						<MDBCol col="6">
							{this.state.staffArrayAll !== undefined ? (
								<>
									<div className="servicePlans">
										<ModifyStaffTable
											venueAdmins={this.props.venueAdmins}
											staff={this.state.staffArrayAll}
											toggleDisable={this.toggleConfirmDisable}
										/>
									</div>
								</>
							) : (
								<MDBSpinner small blue />
							)}
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(ModifyStaff);