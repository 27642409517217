/* Component for logo */

import React from "react";

import brandsData from "../../brands/common/brands.json";

let Logo;

let BrandDirectory;

class logo extends React.Component {
     render() {
          brandsData.forEach(
               ({ name, url, logo_light, logo_dark, brand_directory_name }) => {
                    if (url.includes(window.location.hostname)) {
                         BrandDirectory = brand_directory_name;
                         if (
                              this.props.darkMode === "false" ||
                              this.props.darkMode === null
                         ) {
                              Logo = logo_light;
                         } else if (this.props.darkMode === "true") {
                              Logo = logo_dark;
                         }

                    }

               }
          );
          return (
               <div className="Logo first-step">
                    <img src={"/" + BrandDirectory + "/" + Logo} alt="Logo" />
               </div>
          );
     }
}
export default logo;