import React, { Component } from "react";

import {
	toast
} from "mdbreact";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";

import ModifyEmail from "../../../components/NetworkMgmt/Forms/ModifyEmail/ModifyEmail";


class OperatorInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: [],
			logoURL: undefined,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
		};
	}

	componentDidMount() {
		this.getEmailInfo();
	}

	getEmailInfo = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		this.setState({
			loading_info: true,
			operatorInfo: undefined,
		});
		axios
			.post("/networkoperatorconsole/equipment/listEmailTemplates", body, {
				headers: {},
			})
			.then(
				(response) => {
					
					this.setState({
						allEmailData: response.data.EmailConfiguration,
						brandColor: response.data.EmailConfiguration["brandcolor"],
						dynamicVariables: response.data.EmailConfiguration["DynamicVariables"]
					})
					this.setState({logoURL: response.data["LogoPNG.URL"]});
					this.setState({ loading_info: false });
					
				},
				(error) => {
					this.setState({ loading_info: false });
				}
			);
	};

	submitModifyEmail = (
			language, 
			type, 
			userType,
			emailSubject, 
			emailMessage, 
			logoURL,
			dynamicVariables,
			brandColor,
			emailMainTemplate,
			emailPreheader,
			emailTitle,
			emailHeader,
			emailActionButton,
			emailAdditionalInfo,
			emailTemplate,
			emailNetworkName,
			emailWifiPassword,
			emailSubscriptionStart,
			emailSubscriptionEnd
		) => {
		this.setState({ modify_email: true });
		let body = {
			"AccessToken": sessionStorage.getItem("AccessToken"),
			
				"LogoPNG.URL": logoURL,
				"EmailConfiguration": {
				"brandcolor": brandColor,
				[language]: {  
				[type]: { 
				[userType]: {
				"Subject": emailSubject,
				"preheader": emailPreheader,
				"title": emailTitle,
				"serviceinfo":{ 
				"template": emailTemplate,
				"networkname": emailNetworkName,
				"wifipassword": emailWifiPassword,
				"subscriptionstart": emailSubscriptionStart,
				"subscriptionend": emailSubscriptionEnd},
				"header": emailHeader,
				"message": emailMessage.replace(/'/g, "\\'").replace(/&amp;/g, "&"),
				"actionbutton": emailActionButton,
				"additional_instructions": emailAdditionalInfo
				},
				"Template": emailMainTemplate
				}
			}
		}
		};
		console.log(body);
		Promise.all([
			axios.post("/networkoperatorconsole/equipment/configureNetworkOperator", body, {
			  headers: {},
			}),
		  ])
			.then
				(async ([res1]) => {
					toast.success("Modified Email!", {
						closeButton: false,
					});
					this.props.toggleEmailInfo();
					this.setState({ modify_email: false });
				})
				.catch((error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.setState({ modify_email: false });
				}
			);
	};

	render() {
		return (
			<>
				{this.state.loading_info === true ? (
					<div className="overlay" id="overlay" style={{ display: "block" }}>
						<div className="overlayMessage">
							<div className="spinner-border spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<ModifyEmail
					logoURL={this.state.logoURL}
					allEmailData={this.state.allEmailData}
					brandColor={this.state.brandColor}
					dynamicVariables={this.state.dynamicVariables}
					toggleEmailInfo={this.props.toggleEmailInfo}
					submitModifyEmail={this.submitModifyEmail}
					modify_email={this.state.modify_email}
				/>
			</>
		);
	}
}

export default withTranslation()(OperatorInfo);