import React, { Component } from "react";

import { MDBRow, MDBCol, MDBModal } from "mdbreact";

import { withTranslation } from "react-i18next";

import ViewVenues from "../../../components/NetworkMgmt/Venues/ViewVenues";

import ViewVenuePropMgmtGroups from "../../../components/NetworkMgmt/Venues/ViewVenuePropMgmtGroups";

class ResidentSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			loading: false,
		};
	}

	toggleViewModifyVenues = () => {
		this.setState({
			modalViewVenues: !this.state.modalViewVenues,
		});
	};

	toggleViewVenuePropMgmtGroups = () => {
		this.setState({
			modalViewVenuePropMgmtGroups:
				!this.state.modalViewVenuePropMgmtGroups,
		});
	};

	render() {
		let globalWirelessPlusLocalWiredAccessCount = this.props.venueList["UnitCount_GlobalWirelessPlusLocalWiredAccess"] === undefined ? 0 : this.props.venueList["UnitCount_GlobalWirelessPlusLocalWiredAccess"];
		let localWirelessAccessCount = this.props.venueList["UnitCount_LocalWirelessAccess"] === undefined ? 0 : this.props.venueList["UnitCount_LocalWirelessAccess"];
		let globalWirelessRoamingCount = this.props.venueList["UnitCount_GlobalWirelessRoaming"] === undefined ? 0 : this.props.venueList["UnitCount_GlobalWirelessRoaming"];
		let unitCount = 
			globalWirelessPlusLocalWiredAccessCount + 
			localWirelessAccessCount + 
			globalWirelessRoamingCount;
		return (
			<div>
				<MDBModal
					isOpen={this.state.modalViewVenues}
					toggle={this.toggleViewModifyVenues}
					className="form"
					size="fluid"
					centered
				>
					<ViewVenues
						toggleViewModifyVenues={this.toggleViewModifyVenues}
						loading={this.props.loading}
						venueListForTable={this.props.venueListForTable}
						sharedSecret={this.props.sharedSecret}
						attributeForm={this.props.attributeForm}
						venueInfo={this.props.venueInfo}
						loadingVenueDetails={this.props.loadingVenueDetails}
						getVenues={this.props.getVenues}
						groupForm={this.props.groupForm}
						keypoolForm={this.props.keypoolForm}
						unitsArrayAll={this.props.unitsArrayAll}
						keyPoolList={this.props.keyPoolList}
						servicePlans={this.props.servicePlans}
						staffArrayAll={this.props.staffArrayAll}
						venueEquipList={this.props.venueEquipList}
						getVenueDetails={this.props.getVenueDetails}
						updateName={this.props.updateName}
						handleSelectChangeVenueFromList={
							this.props.handleSelectChangeVenueFromList
						}
						keypoolID={this.props.keypoolID}
						propMgmtID={this.props.propMgmtID}
						optionTemplateVenues={this.props.optionTemplateVenues}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalViewVenuePropMgmtGroups}
					toggle={this.toggleViewVenuePropMgmtGroups}
					className="form"
					size="fluid"
					centered
				>
					<ViewVenuePropMgmtGroups
						propMgmtGroupList={
							this.props.propMgmtGroupList[
								"PropertyManagementGroups"
							]
						}
					/>
				</MDBModal>
				<div className="summaryCard">
					<a href="#link" onClick={this.viewAllUnits}>
						<h2>Venues</h2>
					</a>
					<div className="residentSummary rounded">
						<MDBRow>
							<MDBCol md="9">
								<a
									href="#link"
									onClick={this.toggleViewModifyVenues}
								>
									<p>Venues</p>
								</a>
							</MDBCol>
							<MDBCol md="3">
								<a
									href="#link"
									onClick={this.toggleViewModifyVenues}
								>
									{" "}
									<p>{this.props.venueList["VenueCount"]}</p>
								</a>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol md="9">
								<a
									href="#link"
									onClick={this.toggleViewVenuePropMgmtGroups}
								>
									<p>Property Management Groups</p>
								</a>
							</MDBCol>
							<MDBCol md="3">
								<a
									href="#link"
									onClick={this.toggleViewVenuePropMgmtGroups}
								>
									<p>
										{
											this.props.propMgmtGroupList[
												"PropertyManagementGroupsCount"
											]
										}
									</p>
								</a>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol md="9">
								<a href="#link" onClick={this.viewPendingUnits}>
									<p>Units</p>
								</a>
							</MDBCol>
							<MDBCol md="3">
								<p>{unitCount}</p>
							</MDBCol>
						</MDBRow>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(ResidentSummary);