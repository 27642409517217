import React, { Component } from "react";

import {
	MDBBtn,
	MDBModalBody,
	MDBModalHeader,
	MDBRow,
	MDBCol,
  MDBTooltip,
  MDBIcon
} from "mdbreact";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from 'react-select-search';

class AddDomainAdministrator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: "",
			loading: false,
			userFound: false,
			languageOptions: ([
				{ value: 'en-us', name: 'English'},
				{ value: 'es-xl', name: 'Spanish'}
			]),
			roleOptions: ([
			    { value: 'DomainAdministrator', name: 'Domain Administrator' },
			    { value: 'SIEMAdministrator', name: 'SIEM Administrator'},
			    { value: 'AccountManager', name: 'Account Manager'},
			    { value: 'CustomerSupport', name: 'Customer Support'}
			])
		};
	}

	validateEmail = (email) => {
		var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
		this.setState({
			invalidPhone: undefined,
			userFound: false,
			userFoundWithPhone: false,
		});

		if (!this.state.email.match(mailformat)) {
			this.setState({ invalidEmail: "Please provide a valid email address" });
			$(".emailInput").addClass("is-invalid");
		} else {
			this.setState({ invalidEmail: undefined });
			$(".emailInput").removeClass("is-invalid");
		}
	};

	submitAddDomainAdministrators = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";

		if (
			this.state.invalidPhone === undefined &&
			this.state.firstName !== undefined &&
			this.state.lastName !== undefined &&
			this.state.selectedLanguage !== undefined &&
			this.state.selectedRole !== undefined
		) {
			this.props.submitAddDomainAdministrators(this.state.email, this.state.firstName, this.state.lastName, this.state.selectedLanguage, this.state.selectedRole);
		}
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSelectChangeLanguage = (value) => {
		if(value!==null){
			this.setState({ selectedLanguage: value });
		}
	};

	handleSelectChangeRole = (value) => {
		if(value!==null){
			this.setState({ selectedRole: value });
		}
	};

	render() {

		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleAddDomainAdministrator}>
					<h4>Add Domain Administrator</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" onSubmit={this.submitAddDomainAdministrators} noValidate>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("addnewresident.email")}
								</label>
								<input
									required
									id="email"
									type="email"
									className="form-control emailInput"
									onBlur={(event) => this.validateEmail(this.state.email)}
									onChange={(event) => this.setState({ email: event.target.value })}
									value={this.state.email}
								/>
								<div className="invalid-feedback">Please provide a valid email.</div>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("addnewresident.firstname")}
								</label>
								<input
									required
									name="firstName"
									id="firstName"
									className={this.state.loading === true ? "form-control loading" : "form-control"}
									onChange={this.changeHandler}
									value={this.state.firstName}
									disabled={this.state.userFound}
								/>
								<div className="invalid-feedback">Please provide a valid first name.</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("addnewresident.lastname")}
								</label>
								<input
									required
									className={this.state.loading === true ? "form-control loading" : "form-control"}
									name="lastName"
									id="lastName"
									onChange={this.changeHandler}
									value={this.state.lastName}
									disabled={this.state.userFound}
								/>
								<div className="invalid-feedback">Please provide a valid last name.</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<div>
									<label htmlFor="defaultFormLoginEmailEx" className="black-text">
										{t("addnewresident.language")}
									</label>
                  
									<SelectSearch 
								    	id="selectLanguage" 
								    	search={true}
                         				filterOptions={ fuzzySearch }
								    	options={this.state.languageOptions}
								    	isOptionSelected={true}
								    	classNamePrefix="language-select"
								    	onChange={this.handleSelectChangeLanguage}
								    	value={this.state.selectedLanguage}
								    	placeholder={this.state.selectedLanguage!==undefined?this.state.selectedLanguage==="en-us"?"English":"Spanish":"Select..."}
								    />
									<div className="invalid-feedback">Please provide a valid language.</div>
								</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<div>
									<label htmlFor="defaultFormLoginEmailEx" className="black-text">
										{t("addstaff.role")}
									</label>
                  <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                        Domain Admin - Super Admins of VAULT system<br/>
SIEM Admin - Receives e-mail security, incident, and event management notifications<br/>
Account Manager - Venue Admin for all Venues within the environment<br/>
Customer Support - Access to subscriber modifications in network admin UI. May not edit venues
                                        </span>
                                    </MDBTooltip>
									<SelectSearch 
								    	id="selectRole" 
								    	search={true}
                         				filterOptions={ fuzzySearch }
								    	options={this.state.roleOptions}
								    	isOptionSelected={true}
								    	classNamePrefix="role-select"
								    	onChange={this.handleSelectChangeRole}
								    	value={this.state.selectedRole}
								    	placeholder={"Select..."}
								    />
									<div className="invalid-feedback">Please provide a valid role.</div>
								</div>
							</MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.add_domainadmin === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									"Add Administrator"
								)}
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddDomainAdministrator);