/* Create Account screen */

import React, { Component } from "react";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";




class SelectPlan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			planList: [],
			loading: false,
		};
	}

	componentDidMount() {
		this.getPlans();
	}

	getPlans = () => {
		for (
			let i = 0;
			i < sessionStorage.getItem("AvailableServicePlanCount");
			i++
		) {
			if (
				JSON.parse(sessionStorage.getItem("AvailableServicePlans"))[i][
					"BillingCycleHours"
				] === parseFloat(sessionStorage.getItem("BillingCycleHours")) && 
				JSON.parse(sessionStorage.getItem("AvailableServicePlans"))[i][
					"BillingCycleRecurrence"
				] === parseFloat(sessionStorage.getItem("BillingCycleRecurrence")) &&
				JSON.parse(sessionStorage.getItem("AvailableServicePlans"))[i][
					"ServicePlanID"
				] !== sessionStorage.getItem("servicePlanIDSubscriber") && 
				JSON.parse(sessionStorage.getItem("AvailableServicePlans"))[i][
					"DownloadKbps"
				] > 10000 &&
				JSON.parse(sessionStorage.getItem("AvailableServicePlans"))[i][
					"ServicePlanDescription"
				].toLowerCase().search('bulk') < 0
			) {
				this.setState((prevState) => ({
					planList: [
						...prevState.planList,
						JSON.parse(
							sessionStorage.getItem("AvailableServicePlans")
						)[i],
					],
				}));
				this.setState({
					loading: false,
				});
			}
		}
	};

	selectedPlan = (
		servicePlanID,
		stripePriceID,
		servicePlanName,
		billingCyclePrice,
		billingCycleRecurrence
	) => {
		sessionStorage.setItem("servicePlanID", servicePlanID);
		sessionStorage.setItem("newServicePlanID", servicePlanID);
		sessionStorage.setItem("stripePriceID", stripePriceID);
		sessionStorage.setItem("newServicePlanName", servicePlanName);
		sessionStorage.setItem("billingCyclePrice", billingCyclePrice);
		sessionStorage.setItem(
			"billingCycleRecurrence",
			billingCycleRecurrence
		);
		sessionStorage.setItem(
			"dateStart",
			new Intl.DateTimeFormat("en-US", {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			}).format(new Date())
		);
	};

	render() {
		return (
			<>
				<div className="SelectPlan">
					<MDBContainer className="Form" fluid>
						<MDBRow className="g-0 m-0">
							<MDBCol
								sm="12"
								md="12"
								lg="12"
								className="ml-auto p-0"
							>
								<div className="topBanner">
									<h2>Service Plan Options</h2>
								</div>
								<div className="FormContainer">
									<p className="title">Your Current Plan</p>
									<div className="loginDisclosure">
										<MDBRow className="g-0 plan">
											<MDBCol col="8">
												<p>
													<strong>
														Service Address
													</strong>
													<br />
													{sessionStorage.getItem(
														"addressUnit"
													)}
													, Unit{" "}
													{sessionStorage.getItem(
														"selectedUnitName"
													)}
													<br />
													{sessionStorage.getItem(
														"servicePlanNameSubscriberUnit"
													)
													}
													<br />
													Total price per month
												</p>
											</MDBCol>
											<MDBCol col="4">
												<p style={{ float: "right" }}>
													<br />
													<br />
             <br />
													<br />$
													{sessionStorage.getItem(
														"servicePlanPriceSubscriberUnit"
													)}
												</p>
											</MDBCol>
										</MDBRow>
									</div>
									<p className="title">
										Select Your New Plan
									</p>
									{this.state.loading === true ? (
										<div
											id="overlay"
											style={{ display: "block" }}
										>
											<div class="overlayMessage">
												<div
													className="spinner-border spinner-border"
													role="status"
												>
													<span className="sr-only">
														Loading...
													</span>
												</div>
											</div>
										</div>
									) : (
										<>
											<div className="loginDisclosure">
												{this.state.planList.map(
													(oneKey, i) => {
														return (
															<div key={i}>
																<MDBRow className="g-0 plan">
																	<MDBCol col="8">
																		<p>
																			<strong>
																				{
																					oneKey[
																						"ServicePlanName"
																					]
																				}
																			</strong>
																			<br />
																			<strong>
																				Price:{" "}
																				{"$" +
																					oneKey[
																						"BillingCyclePrice.USD"
																					] /
																						100}{" "}
																				Monthly
																			</strong>
																			<br />
																			{
																				oneKey[
																					"ServicePlanDescription"
																				]
																			}
																		</p>
																		<a
																			className="btn Ripple-parent btn-default"
																			href="/myaccount/upgrade/review"
																			onClick={() =>
																				this.selectedPlan(
																					oneKey[
																						"ServicePlanID"
																					],
																					oneKey[
																						"StripePriceID.USD"
																					],
																					oneKey[
																						"ServicePlanName"
																					],
																					oneKey[
																						"BillingCyclePrice.USD"
																					],
																					oneKey[
																						"BillingCycleRecurrence"
																					]
																				)
																			}
																		>
																			Select
																		</a>
																	</MDBCol>
																	<MDBCol col="4">
																		<img
																			src={
																				oneKey[
																					"ServicePlanImageURL"
																				]
																			}
																			alt="Plan1"
																		/>
																	</MDBCol>
																</MDBRow>
															</div>
														);
													}
												)}
											</div>
											<div className="backButton">
												<a
													className="btn Ripple-parent btn-default"
													href="/myaccount"
												>
													Back
												</a>
											</div>
										</>
									)}
								</div>
							</MDBCol>
							<MDBCol></MDBCol>
						</MDBRow>
					</MDBContainer>
				</div>
			</>
		);
	}
}

export default SelectPlan;