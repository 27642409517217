import React, { Component } from "react";

import { MDBRow, MDBCol, MDBModalHeader, MDBModalBody } from "mdbreact";

import ViewCoreEquipmentTable from "../../../components/VenueMgmt/Support/Datatables/ViewCoreEquipmentTable";

import { withTranslation } from "react-i18next";

class ViewCoreEquipment extends Component {
	render() {
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleCoreEquipment}>
					<h4>Core Device Status</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<MDBRow>
						<MDBCol>
							<div className="allrecentresidentsExpanded">
								<ViewCoreEquipmentTable
									coreEquipment={this.props.coreEquipment}
									selectedName={this.props.selectedName}
									venueList={this.props.venueList}
								/>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(ViewCoreEquipment);