import React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];
  if(props.venueList!==undefined){  
    for (const key of Object.keys(props.venueList)) {
      let Address2 = props.venueList[key]['AddressLine2']!==""?props.venueList[key]['AddressLine2']+"\n":""
      userAttributes.push({
          id: key,
          venueName: props.venueList[key]['VenueName'],
          venueAddress:props.venueList[key]['AddressLine1']+"\n"+Address2+props.venueList[key]['City']+", "+props.venueList[key]['State']+" "+props.venueList[key]['PostalCode']

        })
      i = i + 1;
      
    }
    
  }
  
    columns = [
      {
        headerName: "Venue",
        field: 'venueName',
        minWidth: 120,
        flex: 0.5
      },
      {
        headerName: "Address",
        field: 'venueAddress',
        minWidth: 120,
        flex: 0.5
      }
    ];
    rows = userAttributes;
  return (
    <div style={{ height: 200, width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      hideFooterPagination={true}
      hideFooter={true}
      getRowHeight={({ id, densityFactor }) => {
        return 50 * densityFactor;

      }}
    />
  </div>
);
}