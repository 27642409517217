import React, { Component } from "react";
import { MDBModal, MDBRow, MDBCol, MDBModalHeader, MDBModalBody, MDBSpinner, toast } from "mdbreact";
import ViewUnitStatusTable from "../../../components/VenueMgmt/ResidentSummary/Datatables/ViewUnitStatusTableMUI";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import { withTranslation } from "react-i18next";
import axios from "../../../axios";
class ViewUnitStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  active: false,
		  loading: false,
		  paymentFailed: false
		};
	  }
	statusTitle(param) {
		switch (param) {
			case "pendingAUP":
				return "Pending AUP Acceptance";
			case "assigned":
				return "Assigned Unit Status";
			case "unassigned":
				return "Unassigned Unit Status";
			default:
				return "Service Status";
		}
	}
	submitHandler = (event, email) => {
		event.preventDefault();
		var array = email;
var interval = 2000; // how much time should the delay between two iterations be (in milliseconds)?
array.forEach(function (el, index) {
  setTimeout(function () {
    console.log(el['email']);
	let body = {
		  AccessToken: sessionStorage.getItem("AccessToken"),
		  EmailAddress: el['email'],
		  VenueID: sessionStorage.getItem("venueID"),
		  UserTimeZone: new Date().getTimezoneOffset(),
		};
				axios
		  .post("/venuemgmtconsole/subscribers/resendCodeforTandC", body, {
			headers: {},
		  })
		  .then(
			(response) => {
			  toast.success("Resent Confirmation to "+el['email'], {
				closeButton: false,
			  });  this.setState({ loadingResend: false });
			},
			(error) => {
			  toast.error("There was an error", {
				closeButton: false,
			  });
			}
		  );
  }.bind(this), index * interval);
});
		event.target.className += " was-validated";
	};
	toggleModifyResident = () => {
		this.setState({
		  modalModifyResident: !this.state.modalModifyResident,
		});
	  };
	toggleModifyService = () => {
		this.setState({
		  modalModifyServiceInfo: !this.state.modalModifyServiceInfo,
		});
	  };
	modifyResident = (key) => {
		this.setState({
		modalModifyResident: !this.state.modalModifyResident,
		});
		this.setState({
		SubscriberGivenName: key.SubscriberGivenName,
		SubscriberFamilyName: key.SubscriberFamilyName,
		SubscriberEmail: key.SubscriberEmail,
		SubscriberPhoneNumber: key["SubscriberPhoneNumber.E164"].toString(),
		contactpref_email: key.contactpref_email,
		contactpref_sms: key.contactpref_sms,
		SubscriberLocale: key.SubscriberLocale,
		UnitFloor: key.UnitFloor,
		UnitBuilding: key.UnitBuilding,
		StartDateTime: key["StartDateTimeUTC.ISO8601"],
		EndDateTime: key["EndDateTimeUTC.ISO8601"],
		UnitID: key.UnitID,
		ssid: key.ssid,
		unitID_internal: key.UnitID_internal,
		servicePlanID: key.ServicePlan["ServicePlanID"],
		servicePlan: key.ServicePlan["ServicePlanName"],
		vlan: key.vlan,
		status: key.Status,
		realpageSyncDate: key["RealPageSyncDateTimeUTC.ISO8601"],
		subscriberManagedviaSSO: key["SubscriberManagedviaSSO"],
		StripeClientSecret: key["StripeClientSecret"],
		paymentFailed: key["paymentFailed"],
		subscriberToken: key.SubscriberToken,
		RealPageUnitID: key.RealPageUnitID,
		country: key["Country.ISO3166A3"],
		subscriptionDiscount: key["VenueUnitShoppingCartPriceAdjustment"],
		venueDiscount: key["VenueShoppingCartPriceAdjustment"],
		externalSubscriberID: key["externalSubscriberID"],
		selectedServicePlanPrice: key["ListBillingCyclePrice"],
		setupIntentID: key["SetupIntentID"]
		});
	};
	submitModify = (
		email,
		firstName,
		lastName,
		phoneNumber,
		language,
		contactpref_email,
		contactpref_sms,
		recentResidents,
		dateStart,
		oldDateStart,
		dateEnd,
		oldDateEnd,
		venueID,
		unitID,
		building,
		floor,
		servicePlanID,
		isUpdated,
		isUpdatedServicePlan,
		isUpdatedStartDate,
		isUpdatedEndDate,
		isUpdatedSubscriptionDiscount,
		unitID_internal,
		selectedServicePlanName,
		subscriberToken,
		updatedUnit,
		subscriptionDiscount,
		setupIntentID
	  ) => {
		this.setState({ loading: true });
		let bodyDateStart = {
		  AccessToken: sessionStorage.getItem("AccessToken"),
		  EmailAddress: email,
		  VenueID: venueID,
		  UnitID_internal: unitID_internal,
		  "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
			.toISOString()
			.replace(/:00.\d+Z$/g, "Z"),
		  "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
			.toISOString()
			.replace(/:00.\d+Z$/g, "Z"),
		  UserTimeZone: new Date().getTimezoneOffset(),
		  SubscriberToken: subscriberToken
		};
		let bodyDateEnd = {
		  AccessToken: sessionStorage.getItem("AccessToken"),
		  EmailAddress: email,
		  VenueID: venueID,
		  UnitID_internal: unitID_internal,
		  "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
		  "NewEndDateTimeUTC.ISO8601":
			dateEnd !== undefined
			  ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
			  : undefined,
		  UserTimeZone: new Date().getTimezoneOffset(),
		  SubscriberToken: subscriberToken
		};
		let bodyServicePlan = {
		  AccessToken: sessionStorage.getItem("AccessToken"),
		  EmailAddress: email,
		  VenueID: venueID,
		  UnitID_internal: unitID_internal,
		  "CurrentStartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
		  NewServicePlanID: servicePlanID,
		  UserTimeZone: new Date().getTimezoneOffset(),
		  SubscriberToken: subscriberToken
		};
		let bodyUpdatePII = {
		  AccessToken: sessionStorage.getItem("AccessToken"),
		  EmailAddress: email,
		  GivenName: firstName,
		  FamilyName: lastName,
		  "PhoneNumber.E164": phoneNumber,
		  locale: language,
		  contactpref_email: true,
		  contactpref_sms: contactpref_sms,
		  VenueID: venueID,
		  SubscriberToken: subscriberToken
		};
		let bodyUpdateUnit = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
			SetupIntentId: setupIntentID,
			NewUnitID_internal: unitID_internal,
			VenueID: venueID
		  };
		let bodyUpdateSubscriberPrice = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			StripeClientSecret: sessionStorage.getItem("stripeClientSecretSubscriber"),
			SetupIntentId: setupIntentID,
			NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
			"NewEndDateTimeUTC.ISO8601":
			  dateEnd !== undefined
				? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
				: "",
		  }
		Promise.all([
		  new Date(dateStart) > new Date() && isUpdatedStartDate === true
			? axios.post(
				"/venuemgmtconsole/subscribers/resetSubscriptionStartDate",
				bodyDateStart,
				{ headers: {} }
			  )
			: 
			console.log("🚀 ~ file: RecentResidents.js:160 ~ RecentResidents ~ bodyDateStart", bodyDateStart),
		  dateEnd !== undefined && isUpdatedEndDate === true
			? axios.post(
				"/venuemgmtconsole/subscribers/resetSubscriptionEndDate",
				bodyDateEnd,
				{ headers: {} }
			  )
			: 
			console.log("🚀 ~ file: RecentResidents.js:168 ~ RecentResidents ~ bodyDateEnd", bodyDateEnd),
		  isUpdated === true
			? axios.post(
				"/venuemgmtconsole/subscribers/updateSubscriberPII",
				bodyUpdatePII,
				{ headers: {} }
			  )
			: 
			console.log("🚀 ~ file: RecentResidents.js:176 ~ RecentResidents ~ bodyUpdatePII", bodyUpdatePII),
		  isUpdatedServicePlan === true
			? axios.post(
				"/venuemgmtconsole/subscribers/modifyServicePlan",
				bodyServicePlan,
				{ headers: {} }
			  )
			: 
			console.log("🚀 ~ file: RecentResidents.js:184 ~ RecentResidents ~ bodyServicePlan", bodyServicePlan),
			updatedUnit === true 
			? axios.post(
			  "/venuemgmtconsole/subscribers/modifyVenueUnit",
			  bodyUpdateUnit,
			  { headers: {} }
			)
			: 
			console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit", bodyUpdateUnit),
			isUpdatedSubscriptionDiscount === true 
        ? axios.post(
          "/venuemgmtconsole/subscribers/modifySubscriptionPrice",
          bodyUpdateSubscriberPrice,
          { headers: {} }
        )
        : 
        console.log("🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice", bodyUpdateSubscriberPrice),
		])
		  .then(async ([res1, res2, res3, res4, res5, res6]) => {
			toast.success("Updated Resident!", {
			  closeButton: false,
			});
			this.toggleModifyResident();
			this.props.updateName(this.props.venueName, this.props.venueID);
			if (isUpdatedServicePlan === true) {
			  this.setState({
				loading: false,
				nextInvoiceAdjustedAmount:
				  res4.data["NextInvoiceAdjustedAmount.USD"] === undefined ? "" : res4.data["NextInvoiceAdjustedAmount.USD"] / 100,
				nextInvoiceDate:
				  res4.data["NextInvoiceDateTimeUTC.ISO8601"],
				billingCycleAdjustment:
				  res4.data["BillingCyclePriceAdjustment"] === undefined ? "" : res4.data["BillingCyclePriceAdjustment"] / 100,
				subscriberName: firstName + " " + lastName,
				subscriberEmail: email,
				newServicePlanName: selectedServicePlanName,
				servicePlanID: servicePlanID,
				paymentFailed: res4.data["paymentFailed"]
			  });
			  sessionStorage.setItem("servicePlanIDSubscriber", servicePlanID);
			  sessionStorage.setItem("newServicePlanID", servicePlanID);
			  this.toggleModifyService();
			}
			this.setState({ loading: false });
		  })
		  .catch((error) => {
			toast.error("There was an error!", {
			  closeButton: false,
			});
			this.toggleModifyResident();
			this.setState({ loading: false });
		  });
	};
	submitDelete = (
		email,
		venueID,
		unitID,
		building,
		floor,
		recentResidents,
		oldDateStart,
		unitID_internal,
		cancellationReason,
		refundAmount
	  ) => {
		this.setState({ loadingDelete: true });
		let body = {
		  AccessToken: sessionStorage.getItem("AccessToken"),
		  EmailAddress: email,
		  VenueID: venueID,
		  UnitID_internal: unitID_internal,
		  endImmediately: true,
		  "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
		  UserTimeZone: new Date().getTimezoneOffset(),
		  "CancellationReasons": cancellationReason,
		  "requestedRefundAmount":refundAmount
		};
		axios
		  .post("/venuemgmtconsole/subscribers/resetSubscriptionEndDate", body, {
			headers: {},
		  })
		  .then(
			(response) => {
			  toast.success("Deleted Resident!", {
				closeButton: false,
			  });
			  this.toggleModifyResident();
			  this.props.updateName(this.props.venueName, this.props.venueID);
			  this.setState({ loadingDelete: false });
			},
			(error) => {
			  toast.error("There was an error!", {
				closeButton: false,
			  });
			  this.toggleModifyResident();
			  this.setState({ loadingDelete: false });
			}
		  );
	  };
	  getSelectedUnits = (selectedRowsData) => {
		// console.log(selectedRowsData)
		let unitIDList = [];
		for (const key of selectedRowsData) {
		  let unitID = [];
		  unitID.push(key.unitID_internal, key.unitID, key.building, key.floor)
		  unitIDList.push(unitID)
		}
		this.setState({
		  unitIDList: unitIDList
		});
	  }
	render() {
		return (
			<>
				<MDBModal
					isOpen={this.state.modalModifyResident}
					toggle={this.toggle}
					size="fluid"
					className="form"
					centered
				>
				<ModifyResident
					toggleModifyResident={this.toggleModifyResident}
					firstName={this.state.SubscriberGivenName}
					lastName={this.state.SubscriberFamilyName}
					email={this.state.SubscriberEmail}
					phoneNumber={this.state.SubscriberPhoneNumber}
					receivetext={this.state.contactpref_email}
					receivephone={this.state.contactpref_sms}
					contactpref_sms={this.state.contactpref_sms}
					contactpref_email={this.state.contactpref_email}
					language={this.state.SubscriberLocale}
					floor={this.state.UnitFloor}
					building={this.state.UnitBuilding}
					startDate={this.state.StartDateTime}
					endDate={this.state.EndDateTime}
					unitID={this.state.UnitID}
					submitModify={this.submitModify}
					venueID={this.props.venueID}
					updateName={this.props.updateName}
					loading={this.state.loading}
					SSID={this.state.ssid}
					unitID_internal={this.state.unitID_internal}
					servicePlanID={this.state.servicePlanID}
					servicePlan={this.state.servicePlan}
					submitDelete={this.submitDelete}
					availablePlans={this.props.availablePlans}
					vlan={this.state.vlan}
					status={this.state.status}
					loadingDelete={this.state.loadingDelete}
					realpageSyncDate={this.state.realpageSyncDate}
					subscriberManagedviaSSO={this.state.subscriberManagedviaSSO}
					StripeClientSecret={this.state.StripeClientSecret}
					paymentFailed={this.state.paymentFailed}
					subscriberToken={this.state.subscriberToken}
					RealPageUnitID={this.state.RealPageUnitID}
					country={this.state.country}
					subscriptionDiscount={this.state.subscriptionDiscount}
					venueDiscount={this.state.venueDiscount}
					externalSubscriberID={this.state.externalSubscriberID}
					selectedServicePlanPrice={this.state.selectedServicePlanPrice}
					setupIntentID={this.state.setupIntentID}
     showUpdate={this.props.showUpdate}
     cancelInputType={this.props.cancelInputType}
				/>
				</MDBModal>
					<MDBRow>
						<MDBCol>
						{this.props.UnitsBuildingFloorListSusbcribers !== undefined ? (
							<>
							<div className="allrecentresidentsExpanded">
								<ViewUnitStatusTable
									coreEquipment={this.props.coreEquipment}
									selectedName={this.props.selectedName}
									venueList={this.props.venueList}
									UnitsBuildingFloorListSusbcribers={this.props.UnitsBuildingFloorListSusbcribers}
									filter={this.props.filter}
									modifyResident={this.modifyResident}
									availablePlans={this.props.availablePlans}
									submitHandler={this.submitHandler}
									submitHandlerResendActivation={this.submitHandlerResendActivation}
									loadingResend={this.state.loadingResend}
								/>
							</div>
							</>
							) : (
							  <MDBSpinner small />
							)}
						</MDBCol>
					</MDBRow>
			</>
		);
	}
}
export default withTranslation()(ViewUnitStatus);