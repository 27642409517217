/* Find Deals screen */

import React, { Component, Suspense } from "react";

import axios from "../../axios";

import "../../brands/common/css/common.scss";

import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";

import _ from "lodash";


let SelectPlanComponent;

let Logo;

let BrandName;

export const SUB_PAGES = brandsData.forEach(
	({ name, url, component_directory_name, brand_directory_name, custom_component_directory_name, main_logo }) => {
		if (url.includes(window.location.hostname)) {
			import("../../brands/" + brand_directory_name + "/css/style.scss");
			SelectPlanComponent = React.lazy(() =>
				import(
					"../../brands/" +
					custom_component_directory_name +
						"/components/CreateAccount/SelectPlan"
				)
			);
			Logo = "/" + brand_directory_name + "/" + main_logo;
			BrandName = name;
		}
	}
);

class SelectPlan extends Component {

	constructor(props) {
		super(props);
		this.state = {
			planList: [],
			loading: false
		}
	}

	componentDidMount(){
		this.getPlans();
	}

	getPlans = () =>{
		this.setState({
			loading: true
		});
		let body;
		if(sessionStorage.getItem("unit")!==null){
			body = {
				"UnitID_internal": sessionStorage.getItem("unit"),
				"ShoppingCartEnabled": true
			};
		}else{
			body = {
				"VenueID": sessionStorage.getItem("venueID"),
				"ShoppingCartEnabled": true
			};
		}
		console.log(sessionStorage.getItem("unit"))
		let filteredServicePlans = [];
		axios
			.post("/networkoperatorconsole/shoppingcart/listServicePlans", body, {
				headers: {},
			})
			.then((response) => {
				
				for(var i=0; i<response.data.AvailableServicePlans.length; i++){
						filteredServicePlans.push(response.data.AvailableServicePlans[i])
				}
				let sortedServicePlans = _(filteredServicePlans)
					.map(function (v, k) {
							return _.merge({}, v, { key: k });
					})
					.sortBy("BillingCyclePrice.USD").reverse() // sort by name
					.value();
				this.setState({
					planList : sortedServicePlans
				});
				this.setState({
					loading: false
				});
			})
			.catch((error) => {
				this.setState({
					loading: false
				});
				this.setState({ error: true });
			});

	}

	selectedPlan = (servicePlanKey, servicePlanID, stripePriceID, servicePlanName, billingCyclePrice, billingCycleRecurrence, fixedBillingDate, trialCycles) => {
  sessionStorage.setItem("servicePlanKey", servicePlanKey);
		sessionStorage.setItem("servicePlanID", servicePlanID);
		sessionStorage.setItem("stripePriceID", stripePriceID);
		sessionStorage.setItem("servicePlanName", servicePlanName);
		sessionStorage.setItem("billingCyclePrice", billingCyclePrice);
		sessionStorage.setItem("billingCycleRecurrence", billingCycleRecurrence);
		sessionStorage.setItem("fixedBillingDate", fixedBillingDate);
		sessionStorage.setItem("trialCycles", trialCycles)
	};

	render () {

		return (
			<>
				<Suspense fallback={<div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>}>
				<SelectPlanComponent
									planList = {this.state.planList}
									loading = {this.state.loading}
									selectedPlan = {this.selectedPlan}
						logo={Logo}
						brand={BrandName}
								/>
				<Footer/>
				</Suspense>
			</>
		);
	}
}

export default SelectPlan;