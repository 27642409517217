import React, { Component } from "react";
import { MDBBtn, MDBSpinner, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, toast } from "mdbreact";
import UpcomingExpirationsTable from "../../../components/VenueMgmt/UpcomingExpirations/Datatables/UpcomingExpirationsTable";
import ModifyGuest from "../../../components/VenueMgmt/Forms/ModifyGuest/ModifyGuest";
import axios from "../../../axios";
import { withTranslation } from "react-i18next";
class UpcomingExpirations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
    };
  }
  toggleConfirmExpiration = (key) => {
    this.setState({
      toggleConfirm: true,
      modal: !this.state.modal,
      confirmName: key.SubscriberGivenName + " " + key.SubscriberFamilyName,
      confirmEmail: key.SubscriberEmail,
      confirmEndDate: Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(key["EndDateTimeUTC.ISO8601"])),
      confirmKey: key,
    });
  };
  confirmExpiration = (key) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: key.SubscriberEmail,
      "StartDateTimeUTC.ISO8601": key["StartDateTimeUTC.ISO8601"],
      "EndDateTimeUTC.ISO8601": key["EndDateTimeUTC.ISO8601"],
      VenueID: this.props.venueID,
      UnitID_internal: key.UnitID_internal,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    axios
      .post("/venuemgmtconsole/subscribers/confirmSubscriptionEnd", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Confirmed to expire", {
            closeButton: false,
          });
          this.setState({
            modal: !this.state.modal,
          });
          this.props.updateName(this.props.venueName, this.props.venueID);
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
        }
      );
  };
  toggleModifyResident = () => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
  };
  modifyResident = (key) => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
    this.setState({
      SubscriberGivenName: key.SubscriberGivenName,
      SubscriberFamilyName: key.SubscriberFamilyName,
      SubscriberEmail: key.SubscriberEmail,
      SubscriberPhoneNumber: key["SubscriberPhoneNumber.E164"],
      contactpref_email: key.contactpref_email,
      contactpref_sms: key.contactpref_sms,
      SubscriberLocale: key.SubscriberLocale,
      UnitFloor: key.UnitFloor,
      UnitBuilding: key.UnitBuilding,
      StartDateTime: key["StartDateTimeUTC.ISO8601"],
      EndDateTime: key["EndDateTimeUTC.ISO8601"],
      UnitID: key.UnitID,
      ssid: key.ssid,
      UnitID_internal: key.UnitID_internal,
      vlan: key.vlan,
      realpageSyncDate: key["RealPageSyncDateTimeUTC.ISO8601"],
      subscriberManagedviaSSO: key["SubscriberManagedviaSSO"],
      StripeClientSecret: key["StripeClientSecret"]
    });
  };
  submitModify = (
    email,
    firstName,
    lastName,
    phoneNumber,
    language,
    contactpref_email,
    contactpref_sms,
    recentResidents,
    dateStart,
    oldDateStart,
    dateEnd,
    oldDateEnd,
    venueID,
    unitID,
    building,
    floor,
    isUpdated,
    isUpdatedStartDate,
    isUpdatedEndDate,
    unitID_internal
  ) => {
    this.setState({ loading: true });
    this.setState({ errorFound: false });
    let bodyDateStart = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString().replace(/:00.\d+Z$/g, "Z"),
      "NewStartDateTimeUTC.ISO8601": new Date(dateStart).toISOString().replace(/:00.\d+Z$/g, "Z"),
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    let bodyDateEnd = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      "NewEndDateTimeUTC.ISO8601": dateEnd!==undefined?new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z"):undefined,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    let bodyUpdatePII = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      contactpref_email: true,
      contactpref_sms: contactpref_sms,
      VenueID: venueID,
    };
    Promise.all([
      new Date(dateStart) > new Date() && isUpdatedStartDate === true
        ? axios.post("/venuemgmtconsole/subscribers/resetSubscriptionStartDate", bodyDateStart, { headers: {} })
        : 
        console.log("🚀 ~ file: ServicesEndingSoonGuests.js:164 ~ UpcomingExpirations ~ bodyDateStart", bodyDateStart),
      dateEnd !== undefined && isUpdatedEndDate === true
        ? axios.post("/venuemgmtconsole/subscribers/resetSubscriptionEndDate", bodyDateEnd, { headers: {} })
        : 
        console.log("🚀 ~ file: ServicesEndingSoonGuests.js:168 ~ UpcomingExpirations ~ bodyDateEnd", bodyDateEnd),
      isUpdated === true ? axios.post("/venuemgmtconsole/subscribers/updateSubscriberPII", bodyUpdatePII, { headers: {} }) : 
      console.log("🚀 ~ file: ServicesEndingSoonGuests.js:170 ~ UpcomingExpirations ~ bodyUpdatePII", bodyUpdatePII)
    ])
      .then(async ([res1, res2, res3]) => {
        toast.success("Updated Resident!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.props.updateName(this.props.venueName, this.props.venueID);
        this.setState({ loading: false });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.setState({ loading: false });
      });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  submitDelete = (email, venueID, unitID, building, floor, recentResidents, oldDateStart, unitID_internal) => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      endImmediately: true,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    axios
      .post("/venuemgmtconsole/subscribers/resetSubscriptionEndDate", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Deleted Resident!", {
            closeButton: false,
          });
          this.toggleModifyResident();
          this.props.updateName(this.props.venueName, this.props.venueID);
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleModifyResident();
        }
      );
  };
  render() {
    if (this.state.postMessage1) {
      var h = document.getElementById("overlayConfirm");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage1: "" });
      }, 6000);
    }
    const { t } = this.props;
    return (
      <>
        {this.state.toggleConfirm ? (
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>Confirm Expiration</MDBModalHeader>
            <MDBModalBody>
              <p>Name: {this.state.confirmName}</p>
              <p>Email: {this.state.confirmEmail}</p>
              <p>Expiring: {this.state.confirmEndDate}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={() => this.confirmExpiration(this.state.confirmKey)}>
                Confirm
              </MDBBtn>
              <MDBBtn color="danger" onClick={this.toggle}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        ) : null}
        <MDBModal isOpen={this.state.modalModifyResident} toggle={this.toggle} size="lg" className="form" centered>
          <ModifyGuest
            toggleModifyResident={this.toggleModifyResident}
            firstName={this.state.SubscriberGivenName}
            lastName={this.state.SubscriberFamilyName}
            email={this.state.SubscriberEmail}
            phoneNumber={this.state.SubscriberPhoneNumber}
            receivetext={this.state.contactpref_email}
            receivephone={this.state.contactpref_sms}
            language={this.state.SubscriberLocale}
            floor={this.state.UnitFloor}
            building={this.state.UnitBuilding}
            startDate={this.state.StartDateTime}
            endDate={this.state.EndDateTime}
            unitID={this.state.UnitID}
            submitModify={this.submitModify}
            venueID={this.props.venueID}
            updateName={this.props.updateName}
            loading={this.state.loading}
            SSID={this.state.ssid}
            unitID_internal={this.state.UnitID_internal}
            submitDelete={this.submitDelete}
            vlan={this.state.vlan}
            realpageSyncDate={this.state.realpageSyncDate}
            subscriberManagedviaSSO={this.state.subscriberManagedviaSSO}
            StripeClientSecret={this.state.StripeClientSecret}
          />
        </MDBModal>
        <div id="overlayConfirm">
          <div id="overlayMessage" class="overlayMessage">
            {this.state.postMessage1}
          </div>
        </div>
        <MDBModalHeader toggle={this.props.toggleServicesEndingSoon}>
          <h4>{t("residentnav.servicesending")}</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <h2>{t("upcomingexpirations.title")}</h2>
          <div className="expandCollapse">
            <p onClick={() => this.setState({ active: !this.state.active })}>{this.state.active ? t("recentservice.showless") : t("recentservice.showmore")}</p>
          </div>
          {this.props.expirations !== undefined ? (
            <div className={this.state.active ? "upcomingExpirationsExpanded" : "upcomingExpirations"}>
              <UpcomingExpirationsTable
                upcomingexpirations={this.props.expirations}
                toggleConfirmExpiration={this.toggleConfirmExpiration}
                modifyResident={this.modifyResident}
              />
            </div>
          ) : (
            <MDBSpinner small />
          )}
        </MDBModalBody>
      </>
    );
  }
}
export default withTranslation()(UpcomingExpirations);