import * as React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };
  const handleMouseLeave = () => {
    setShowFullCell(false);
  };
  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }
    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);
  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.formattedValue || ''} width={displayTextWidth(params.formattedValue, ".9rem Proxima Nova Rg")} />
  );
}
function displayTextWidth(text, font) {
  let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width + 25;
}
export default function Basic(props) {
  let userAttributes;
  let i = 0;
  let columns = [];
  let rows = [];
  if (props.allrecentresidents !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.allrecentresidents)) {
      if (props.allrecentresidents[key]["EndDateTimeUTC.ISO8601"] !== "") {
        userAttributes.push({
          id: key,
          unitID: props.allrecentresidents[key]["UnitID"],
          building: props.allrecentresidents[key]["UnitBuilding"],
          floor: props.allrecentresidents[key]["UnitFloor"],
          name: props.allrecentresidents[key]["SubscriberGivenName"] + " " + props.allrecentresidents[key]["SubscriberFamilyName"],
          email: props.allrecentresidents[key]["SubscriberEmail"],
          status: props.allrecentresidents[key]["Status"],
          activated: props.allrecentresidents[key]["StartDateTimeUTC.ISO8601"].toString(),
          expiring: props.allrecentresidents[key]["EndDateTimeUTC.ISO8601"].toString()
        });
      } else {
        userAttributes.push({
          id: key,
          unitID: props.allrecentresidents[key]["UnitID"],
          building: props.allrecentresidents[key]["UnitBuilding"],
          floor: props.allrecentresidents[key]["UnitFloor"],
          name: props.allrecentresidents[key]["SubscriberGivenName"] + " " + props.allrecentresidents[key]["SubscriberFamilyName"],
          email: props.allrecentresidents[key]["SubscriberEmail"],
          status: props.allrecentresidents[key]["Status"],
          activated: props.allrecentresidents[key]["StartDateTimeUTC.ISO8601"].toString(),
          expiring: "",
        });
        i = i + 1;
      }
    }
  }
  columns = [
    {
      headerName: "Unit ID",
      field: "unitID",
      flex: 0.04,
      minWidth: 20,
      renderCell: renderCellExpand
    },
    {
      headerName: "Building",
      field: "building",
      flex: 0.04,
      minWidth: 20,
      renderCell: renderCellExpand
    },
    {
      headerName: "Floor",
      field: "floor",
      flex: 0.04,
      minWidth: 20,
      renderCell: renderCellExpand
    },
    {
      headerName: "Name",
      field: "name",
      flex: 0.1,
      renderCell: renderCellExpand
    },
    {
      headerName: "Email",
      field: "email",
      flex: 0.1,
      renderCell: renderCellExpand
    },
    {
      headerName: "Status",
      field: "status",
      flex: 0.075,
      renderCell: renderCellExpand
    },
    {
      headerName: "Activated",
      field: "activated",
      minWidth: 50,
      flex: 0.1,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: renderCellExpand
    },
    {
      headerName: "Expiring",
      field: "expiring",
      minWidth: 50,
      flex: 0.1,
            type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: renderCellExpand
    }
  ];
  rows = userAttributes;
  return (
    <div style={{ height: 500 }}>
      <DataGrid
        rows={rows}
        columns={columns}
                onRowClick={(id) => props.modifyResident(props.allrecentresidents[id.row["id"]])}
        getRowHeight={({ id, densityFactor }) => {
          return 40 * densityFactor;
        }}
                hideFooterPagination={false}
        hideFooter={false}
                sx={{ '--DataGrid-overlayHeight': '300px' }}
      />
    </div>
  );
}