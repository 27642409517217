/* Find Deals screen */

import React, { Component, Suspense } from "react";

import axios from "../../axios";

import queryString from "query-string";

import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";


let CreateAccount2Component;

let Logo;

let BrandName;

export const SUB_PAGES = brandsData.forEach(
	({ name, url, component_directory_name, brand_directory_name, custom_component_directory_name, main_logo }) => {
		if (url.includes(window.location.hostname)) {
			import("../../brands/" + brand_directory_name + "/css/style.scss");
			CreateAccount2Component = React.lazy(() =>
				import(
					"../../brands/" +
					custom_component_directory_name +
						"/components/CreateAccount/CreateAccountP2"
				)
			);
			Logo = "/" + brand_directory_name + "/" + main_logo;
			BrandName = name;
		}
	}
);

const parsed = queryString.parse(window.location.search);

class CreateAccount1 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tandc: false,
			modalTandC: false,
			loadingTandC: false
		};
	}

	componentDidMount(){

		this.getTandC();
	}

	getTandC = () => {
		let locale;
		if (parsed["locale"]) {
            locale = parsed["locale"];
        } else {
            if (
                sessionStorage.getItem("i18nextLng") === "en_us" ||
                sessionStorage.getItem("i18nextLng") === "en" ||
                sessionStorage.getItem("i18nextLng") === "en-US"
            ) {
                locale = "en-us";
            } else {
                locale = "en-us";
            }
        }
        let data = {
            locale: locale,
        };
        this.setState({ loadingTandC: true });
        axios
            .post("/subscriberconsole/viewTandC", data, {
                headers: {},
            })
            .then((response) => {
                this.setState({ tandctext: response.data.TandC });

                this.setState({ loadingTandC: false });
            })
            .catch((error) => {
                this.setState({ loadingTandC: false });
            });
	}

	toggleCheckboxValueTandC = () => {
		this.setState({ tandc: !this.state.tandc });
	};

	toggleTandC = () => {
		this.setState({
			modalTandC: !this.state.modalTandC,
		});
	};

	render () {

		return (
			<>
				<Suspense fallback={<div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>}>
					<CreateAccount2Component
									toggleCheckboxValueTandC = {this.toggleCheckboxValueTandC}
									toggleTandC = {this.toggleTandC}
									tandc = {this.state.tandc}
									tandctext = {this.state.tandctext}
									modalTandC = {this.state.modalTandC}
									loadingTandC = {this.state.loadingTandC}
									logo={Logo}
									brand={BrandName}
								/>
					<Footer />
				</Suspense>
			</>
		);
	}
}

export default CreateAccount1;