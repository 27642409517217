import React, { Component } from "react";

import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBRow,
	MDBCol
} from "mdbreact";

import ViewTicketsData from "../../../components/SubscriberMgmt/Support/Datatables/ViewTicketsData";

import axios from "../../../axios";

import parse from "html-react-parser";

import { withTranslation } from "react-i18next";




class ManageDevices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalManageDevices: false,
			notes: [],
		};
	}

	deviceDetails = (device) => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
			deviceDetails: device,
		});
	};

	toggleDeviceDetailsClose = () => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
		});
	};

	toggleTicket = (id) => {
		this.setState({
			modalTicketDetails: !this.state.modalTicketDetails,
		});
		let firstKeyID = Object.keys(this.props.keys[0][this.props.venueName[0]])[1];
		let firstPolicyID = Object.keys(this.props.keys[0][this.props.venueName[0]][firstKeyID]["VenueUnitPolicies"]);
		let venueID = this.props.allData["KeysInKeyPools"][this.props.venueName[0]][firstKeyID]["VenueUnitPolicies"][firstPolicyID[0]]["VenueID"];
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: venueID,
			TicketID: id,
		};
		axios
			.post("/subscriberconsole/getTickets", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					ticketID: id,
					notes: response.data.notes,
				});
			})
			.catch((error) => {
				this.setState({ error: true });
			});
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MDBModal isOpen={this.state.modalTicketDetails} toggle={this.toggleTicket} size="lg" className="smallModal" centered>
					<MDBModalHeader toggle={this.toggleTicket}>
						<h4>
							{t("viewtickets.detailstitle")}: {this.state.ticketID}
						</h4>
					</MDBModalHeader>
					<MDBModalBody>
						{this.state.notes.map((value, index) => {
							return (
								<div>
									<MDBRow className="mb-2">
										<MDBCol>
											<p>
												<strong>{t("viewtickets.description")}:</strong>
											</p>
											{this.state.notes[index].text !== undefined ? parse(this.state.notes[index].text) : ""}
										</MDBCol>
									</MDBRow>
									<MDBRow className="mb-2">
										<MDBCol>
											<p>
												<strong>{t("viewtickets.datecreated")}:</strong>&nbsp;
												{this.state.notes[index].dateCreated !== undefined
													? Intl.DateTimeFormat("en-US", {
															year: "numeric",
															month: "numeric",
															day: "numeric",
															hour: "numeric",
															minute: "numeric",
													  }).format(new Date(this.state.notes[index].dateCreated))
													: ""}
											</p>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol>
											<p>
												<strong>{t("viewtickets.resolved")}:</strong>&nbsp;
												{this.state.notes[index].resolutionFlag === true ? "Yes" : "No"}
											</p>
										</MDBCol>
									</MDBRow>
									{index + 1 !== this.state.notes.length ? <hr /> : ""}
								</div>
							);
						})}
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="danger" onClick={this.toggleTicket}>
							Ok
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModalHeader toggle={this.props.toggleViewTickets}>
					<h4>{t("viewtickets.title")}</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<ViewTicketsData tickets={this.props.tickets} toggleTicket={this.toggleTicket} />
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(ManageDevices);