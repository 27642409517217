import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader } from "mdbreact";

import { withTranslation } from "react-i18next";

class ResendActivation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: "",
		};
	}

	submitHandler = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";
		this.props.submitHandlerResendActivation(this.state.email);
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleResendActivation}>
					<h4>{t("residentnav.resendactivation")}</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" onSubmit={this.submitHandler} noValidate>
						<label htmlFor="defaultFormLoginEmailEx" className="black-text">
							{t("addnewresident.email")}
						</label>
						<input
							label="Email *"
							required
							className="form-control"
							id="email"
							onChange={(event) => this.setState({ email: event.target.value })}
							value={this.state.email}
						/>
						<div className="invalid-feedback">Please provide a valid email.</div>
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.loading === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									t("residentnav.resendactivation")
								)}
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(ResendActivation);