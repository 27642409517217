import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
  [datatable] = React.useState("");
  if (props.subscriberList !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.subscriberList)) {
      userAttributes.push({
        subscriber: props.subscriberList[key],
      });
      i = i + 1;
    }
  }

  [datatable] = React.useState({
    columns: [
      {
        label: "Subscriber ID",
        field: "subscriber",
      },
    ],
    rows: userAttributes,
  });
  return (
    <MDBDataTableV5
      responsive
      hover
      data={datatable}
      searchBottom={false}
      paging={false}
      className="servicePlans"
      info={false}
      noRecordsFoundLabel={t("recentservice.nomatches")}
    />
  );
}