import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { GridCsvExportOptions } from '@mui/x-data-grid';
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: 'venueTransactionData',
            delimiter: ',',
            utf8WithBom: true,
            fields: [
              'subEndDate',
              'paymentSuccessDate',
              'name',
              'email',
              'phoneNumber',
              'servicePlanName',
              'servicePlanDescription',
              'priceAdjustment',
              'billingCyclePrice',
              'venuePriceAdjustment',
              'amountReceived'
            ]
          }} />
      </GridToolbarContainer>
    </Box>
  );
}
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };
  const handleMouseLeave = () => {
    setShowFullCell(false);
  };
  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }
    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);
  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});
function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={displayTextWidth(params.value, ".9rem Proxima Nova Rg")} />
  );
}
function displayTextWidth(text, font) {
  let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width + 25;
}
export default function Basic(props) {
  let userAttributes;
  let i = 0;
  let columns = [];
  let rows = [];
  userAttributes = [];
  if (props.transactions !== undefined) {
    for (const key of Object.keys(props.transactions)) {
      let Address2 = props.transactions[key]['address2'] !== "" ? props.transactions[key]['address2'] + "\n\r" : ""
      let receiptLink;
      userAttributes.push({
        id: key,
        servicePlan: { planName: props.transactions[key]["ServicePlanName"], planDescription: props.transactions[key]["ServicePlanDescription"] },
        servicePlanName: props.transactions[key]["ServicePlanName"],
        servicePlanDescription: props.transactions[key]["ServicePlanDescription"],
        name: props.transactions[key]["GivenName"] + " " + props.transactions[key]["FamilyName"],
        price: props.transactions[key]["Currency"] + props.transactions[key]["BillingCyclePrice.USD"] / 100,
        qty: props.transactions[key]["BillingCycleRecurrence"],
        amountPaid: props.transactions[key]["AmountReceived.USD"] === "undefined" ? props.transactions[key]["Currency"] + props.transactions[key]["AmountReceived.USD"] / 100 : "",
        subStartDate:
          props.transactions[key]["dateAddedUTC.ISO8601"] !== ""
            ? Intl.DateTimeFormat("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(props.transactions[key]["dateAddedUTC.ISO8601"]))
            : "",
        subEndDate:
          props.transactions[key]["InvoiceDateTimeUTC.ISO8601"] !== ""
            ? Intl.DateTimeFormat("default", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(props.transactions[key]["InvoiceDateTimeUTC.ISO8601"]))
            : "",
        paymentSuccessDate:
          props.transactions[key]["PaymentSuccessDateTimeUTC.ISO8601"] !== ""
            ? Intl.DateTimeFormat("default", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(props.transactions[key]["PaymentSuccessDateTimeUTC.ISO8601"]))
            : "",
        venueName: props.transactions[key]['VenueName'],
        venueAddress: props.transactions[key]['address1'] + "\n" + Address2 + props.transactions[key]['city'] + ", " + props.transactions[key]['state'] + " " + props.transactions[key]['postalcode'],
        email: props.transactions[key]["EmailAddress"],
        phoneNumber: props.transactions[key]["PhoneNumber.E164"],
        priceAdjustment: props.transactions[key]["CurrencySymbol"] + props.transactions[key]["VenuePriceAdjustment"] / 100,
        billingCyclePrice: props.transactions[key]["AmountReceived." + props.transactions[key]["Currency"]] !== "undefined" ? props.transactions[key]["CurrencySymbol"] + (props.transactions[key]["BillingCyclePrice." + props.transactions[key]["Currency"]] / 100) : "",
        venuePriceAdjustment: props.transactions[key]["CurrencySymbol"] + props.transactions[key]["VenuePriceAdjustment"] / 100,
        amountReceived: props.transactions[key]["AmountReceived." + props.transactions[key]["Currency"]] !== "undefined" ? props.transactions[key]["CurrencySymbol"] + (props.transactions[key]["AmountReceived." + props.transactions[key]["Currency"]] / 100) : "",
        receipt: { receiptURL: props.transactions[key]['StripeRefundReceiptURL'], receiptPrice: props.transactions[key]["AmountReceived." + props.transactions[key]["Currency"]] }
      })
      i = i + 1;
    }
  }
  columns = [
    {
      headerName: "Invoice Date",
      field: "subEndDate",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Payment Success Date",
      field: "paymentSuccessDate",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      minWidth: 120,
      renderCell: renderCellExpand
    },
    {
      headerName: "Service Plan",
      field: "servicePlan",
      minWidth: 200,
      renderCell: (params) => (
        <GridCellExpand value={<div>
          {params.value.planName}
          <br />
          {params.value.planDescription}
        </div> || ''} width={displayTextWidth(params.value, ".9rem Proxima Nova Rg")} />
      )
    },
    {
      headerName: "Service Plan Name",
      field: "servicePlanName",
      minWidth: 120,
      renderCell: renderCellExpand
    },
    {
      headerName: "Service Plan Description",
      field: "servicePlanDescription",
      minWidth: 120,
      renderCell: renderCellExpand
    },
    {
      headerName: "Price Adjustment",
      field: "priceAdjustment",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Billing Cycle Price",
      field: "billingCyclePrice",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Venue Price Adjustment",
      field: "venuePriceAdjustment",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Amount Received",
      field: "amountReceived",
      minWidth: 150,
      renderCell: renderCellExpand
    },
    {
      headerName: "Receipt Link",
      field: "receipt",
      minWidth: 80,
      renderCell: (params) => (
        params.value.receiptURL !== "" ?
          <div>
            <a href={params.value.receiptURL} target="_blank" rel="noreferrer">Click Here</a>
          </div> : ""
      )
    }
  ];
  rows = userAttributes
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={({ id, densityFactor }) => {
          return 70 * densityFactor;
        }}
        sx={{
          overflowX: 'scroll',
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
            cursor: "default"
          }
        }}
        slots={{ toolbar: QuickSearchToolbar }}
        hideFooterPagination={false}
        hideFooter={false}
        rowSelection={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              servicePlanName: false,
              servicePlanDescription: false,
            },
          },
        }}
      />
    </div>
  );
}