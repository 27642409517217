import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];
  if (props.administratorsData !== undefined) {
    for (const key of Object.keys(props.administratorsData)) {
      userAttributes.push({
        id: key,
        email: Object.keys(props.administratorsData[key]).toString(),
        type: Object.values(props.administratorsData[key]),
      });
      i = i + 1;
    }
  }
  columns = [
    {
      headerName: "Email Address",
      field: "email",
      minWidth: 400,
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 360,
    },
    {
      headerName: "Action",
      field: "receipt",
      minWidth: 120,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => props.toggleDisable(params.row.email)}
            rel="noreferrer"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  rows = userAttributes;
  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooterPagination={true}
        hideFooter={true}
        getRowHeight={({ id, densityFactor }) => {
          return 50 * densityFactor;
        }}
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </div>
  );
}
