import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];
  if (
    props.staff !== undefined
    // &&
    // props.venueListUtilization !== undefined
  ) {
    // console.log(props.unitData)
    for (const key of Object.keys(props.staff)) {
      //  if (
      //   props.venueListUtilization[props.venueList[key]["VenueID"]] !==
      //   undefined
      // ) {
      if (props.staff !== undefined) {
        userAttributes.push({
          id: key,
          email: Object.keys(props.staff[key]),
          type: Object.values(props.staff[key]),
        });
        i = i + 1;
      }
    }
  }
  columns = [
    {
      headerName: "Email",
      field: "email",
      minWidth: 350,
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 350,
    },
    {
     headerName: "Action",
     field: "action",
     minWidth: 160,
     renderCell: (params) => {
       return (
         <>
           <Button
             onClick={(e) =>
              props.toggleDisable(params.row.email)
             }
             // onClick={(e) => (console.log(params["id"]))}
             variant="contained"
             className="expirationAction"
           >
             Delete
           </Button>
         </>
       );
     },
    }
  ];
  rows = userAttributes;
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooterPagination={false}
        hideFooter={false}
        getRowHeight={({ id, densityFactor }) => {
          return 60 * densityFactor;
        }}
        onRowClick={(id) =>
          props.modifyVenue(id.row["venueName"], id.row["id"])
        }
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </div>
  );
}
