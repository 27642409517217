import React, { Component } from "react";

import {
	MDBModalBody,
	MDBModalHeader,
	MDBRow,
	MDBCol
} from "mdbreact";

class EquipmentErrorLog extends Component {

    state = {
		equipmentErrorLog: this.props.equipmentErrorLog
	};

	render() {
		console.log(this.props)
		return (
<>
<MDBModalHeader toggle={this.props.togglEquipmentErrorLog}>
          <h4>Equipment Error Log</h4>
        </MDBModalHeader>
        <MDBModalBody>
								<MDBRow>
									<MDBCol col="12">
									{this.props.equipmentErrorLog !== undefined ? (
								// console.log(this.state.subscriberLog)
								<div>
									{
										this.props.equipmentErrorLog.map((value, index) => (
											<div className="mt-2 mb-2" key={index}>
												{
													Object.entries(value).map(


														(
															(value2, index2) =>
															(
																value2[0] !== "event" ?
																	<><p className="mb-0"><strong>{value2[0]}: </strong>{value2[1]}</p></>
																	:
																	Object.entries(value2[1]).map(
																		(
																			(value3, index3) =>
																			(
																				<><p className="mb-0"><strong>{value3[0]}: </strong>{value3[1]}</p></>
																			)
																		)
																	)
															)
														)


													)
												}
												<hr />
											</div>

										)

										)

									}



								</div>
							) : (
								<div
									className="spinner-border spinner-border-sm"
									role="status"
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
									</MDBCol>

								</MDBRow>
                                </MDBModalBody>
                            </>
                            );
                        }
                    }

export default EquipmentErrorLog;