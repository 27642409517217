import React, { Component } from "react";

import {
	MDBIcon,
	MDBCard,
	MDBCardBody
} from "mdbreact";

import Data from "../../../components/SubscriberMgmt/CumulativeBandwidth/Datachart/datachartDashboard";

import { withTranslation } from "react-i18next";

class CumulativeBandwidth extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div class="keyInformation">
					<div className="ctaButtonNoAction">
						<h2>
							<MDBIcon icon="wifi" />
							{t("cumulativebandwidth.title")}
						</h2>
					</div>
					<MDBCard>
						<MDBCardBody>
							<Data selectedKeyID={this.props.keyID} />
						</MDBCardBody>
					</MDBCard>
				</div>
			</>
		);
	}
}

export default withTranslation()(CumulativeBandwidth);