import React, { Component } from "react";

import {
	MDBBtn,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBRow,
	MDBCol,
	toast
} from "mdbreact";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";




class PropertyInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			PrimaryContactName: this.props.venueData["PrimaryContactName"],
			PrimaryContactGivenName: this.props.venueData["PrimaryContactGivenName"],
			PrimaryContactFamilyName: this.props.venueData["PrimaryContactFamilyName"],
			PrimaryContactEmailAddress: this.props.venueData["PrimaryContactEmailAddress"],
			PrimaryContactPhoneNumber: this.props.venueData["PrimaryContactPhoneNumber.E164"],
			DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess: this.props.venueData["DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"],
			DefaultServicePlanID_LocalWirelessAccess: this.props.venueData["DefaultServicePlanID_LocalWirelessAccess"],
			loading: false,
		};
	}
	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	submitHandler = () => {
		this.setState({ loading: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: this.props.venueID,
			PrimaryContactGivenName: this.state.PrimaryContactGivenName,
			PrimaryContactFamilyName: this.state.PrimaryContactFamilyName,
			PrimaryContactEmailAddress: this.state.PrimaryContactEmailAddress,
			"PrimaryContactPhoneNumber.E164": this.state.PrimaryContactPhoneNumber,
			UserTimeZone: new Date().getTimezoneOffset(),
		};
		axios
			.post("/venuemgmtconsole/venues/configureVenue", body, {
				headers: {},
			})
			.then((response) => {
				toast.success("Property Info Updated!", {
					closeButton: false,
				});
				this.props.updateName(this.props.venueName, this.props.venueID);
				this.setState({ loading: false });
				this.props.togglePropertyInfo();
			})
			.catch((error) => {
				this.setState({ error: true });
			});
	};

	render() {
		let DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess = this.props.venueData["AvailableServicePlans"].find(item => item.ServicePlanID === this.state.DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess);
		let DefaultServicePlanID_LocalWirelessAccess = this.props.venueData["AvailableServicePlans"].find(item => item.ServicePlanID === this.state.DefaultServicePlanID_LocalWirelessAccess);
		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.togglePropertyInfo}>
					<h4>{t("propertyinfo.title")}</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>Property Name</strong>
							</p>
							<p>{this.props.venueData["VenueName"]}</p>
						</MDBCol>
						<MDBCol col="6"></MDBCol>
					</MDBRow>
					<br />
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>Address 1</strong>
							</p>
							<p>{this.props.venueData["AddressLine1"]}</p>
						</MDBCol>
						<MDBCol col="6">
							<p>
								<strong>Address 2</strong>
							</p>
							<p>{this.props.venueData["AddressLine2"]}</p>
						</MDBCol>
					</MDBRow>
					<br />
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>City</strong>
							</p>
							<p>{this.props.venueData["City"]}</p>
						</MDBCol>
						<MDBCol col="6">
							<p>
								<strong>State</strong>
							</p>
							<p>{this.props.venueData["State"]}</p>
						</MDBCol>
					</MDBRow>
					<br />
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>Postal</strong>
							</p>
							<p>{this.props.venueData["PostalCode"]}</p>
						</MDBCol>
						<MDBCol col="6">
							<p>
								<strong>Country</strong>
							</p>
							<p>{this.props.venueData["Country.ISO3166A3"]}</p>
						</MDBCol>
					</MDBRow>
					<br />
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>Primary Contact - First Name</strong>
							</p>
							<p>
								<input name="PrimaryContactGivenName" value={this.state.PrimaryContactGivenName} className="form-control" onChange={this.changeHandler} />
							</p>
						</MDBCol>
						<MDBCol col="6">
							<p>
								<strong>Primary Contact - Last Name</strong>
							</p>
							<p>
								<input name="PrimaryContactFamilyName" value={this.state.PrimaryContactFamilyName} className="form-control" onChange={this.changeHandler} />
							</p>
						</MDBCol>
					</MDBRow>
					<br />
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>Primary Contact Email Address</strong>
							</p>
							<p>
								<input
									name="PrimaryContactEmailAddress"
									value={this.state.PrimaryContactEmailAddress}
									className="form-control"
									onChange={this.changeHandler}
								/>
							</p>
						</MDBCol>
						<MDBCol col="6">
							<p>
								<strong>Primary Contact Phone Number</strong>
							</p>
							<p>
								<input
									name="PrimaryContactPhoneNumber"
									value={this.state.PrimaryContactPhoneNumber}
									className="form-control"
									onChange={this.changeHandler}
								/>
							</p>
						</MDBCol>
					</MDBRow>
					<br />
					<MDBRow>
						<MDBCol col="6">
							<p>
								<strong>Default Service Plan for Residential Units</strong>
							</p>
							<p>
								{DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess !== undefined ? DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess.ServicePlanName : ""}
							</p>
						</MDBCol>
						<MDBCol col="6">
							<p>
								<strong>Default Service Plan for Guest Subscriptions</strong>
							</p>
							<p>
								{DefaultServicePlanID_LocalWirelessAccess !== undefined ? DefaultServicePlanID_LocalWirelessAccess.ServicePlanName : ""}
							</p>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn color="primary" onClick={this.props.togglePropertyInfo}>
						Cancel
					</MDBBtn>
					<MDBBtn color="primary" onClick={this.submitHandler}>
						{this.state.loading === true ? (
							<div className="spinner-border spinner-border-sm" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						) : (
							"Ok"
						)}
					</MDBBtn>
				</MDBModalFooter>
			</>
		);
	}
}

export default withTranslation()(PropertyInfo);