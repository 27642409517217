import React, { Component } from "react";

import {
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBModalFooter
} from "mdbreact";

import ViewOpenTicketsTable from "../../../components/VenueMgmt/Support/Datatables/ViewOpenTicketsTable";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";




class ViewOpenTickets extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalTicketDetails: false,
			notes: [],
		};
	}

	toggleTicket = (id) => {
		this.setState({
			modalTicketDetails: !this.state.modalTicketDetails,
		});

		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: this.props.venueID,
			TicketID: id,
		};
		axios
			.post("/venuemgmtconsole/support/getTickets", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					ticketID: id,
					notes: response.data.notes,
				});
			})
			.catch((error) => {
				this.setState({ error: true });
			});
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MDBModal isOpen={this.state.modalTicketDetails} toggle={this.toggleTicket} size="lg" className="smallModal" centered>
					<MDBModalHeader toggle={this.toggleTicket}>
						<h4>Ticket Details - Ticket ID: {this.state.ticketID}</h4>
					</MDBModalHeader>
					<MDBModalBody>
						{this.state.notes.map((value, index) => {
							return (
								<div>
									<MDBRow className="mb-2">
										<MDBCol>
											<p>
												<strong>{t("viewtickets.datecreated")}:</strong>&nbsp;
												{this.state.notes[index].dateCreated !== undefined
													? Intl.DateTimeFormat("en-US", {
															year: "numeric",
															month: "numeric",
															day: "numeric",
															hour: "numeric",
															minute: "numeric",
													  }).format(new Date(this.state.notes[index].dateCreated))
													: ""}
											</p>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol>
											<p>
												<strong>{t("viewtickets.resolved")}:</strong>&nbsp;
												{this.state.notes[index].resolutionFlag === true ? "Yes" : "No"}
											</p>
										</MDBCol>
									</MDBRow>
									{index + 1 !== this.state.notes.length ? <hr /> : ""}
								</div>
							);
						})}
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="danger" onClick={this.toggleTicket}>
							Ok
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModalHeader toggle={this.props.toggleOpenTickets}>
					<h4>Open Tickets</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<MDBRow>
						<MDBCol col="7">
							<div className="allrecentresidentsExpanded">
								<ViewOpenTicketsTable tickets={this.props.tickets} toggleTicket={this.toggleTicket} />
							</div>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(ViewOpenTickets);