import React, { Component } from "react";
import {
  MDBBtn,
  MDBSpinner,
  MDBModal,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBRow,
  MDBCol,
  toast,
  MDBTooltip,
  MDBIcon
} from "mdbreact";
import VenueServicePlansTable from "../../../components/NetworkMgmt/Venues/Datatables/VenueServicePlansTable";
import AddServicePlanToVenue from "../../../components/NetworkMgmt/Forms/AddServicePlanToVenue/AddServicePlanToVenue";
import { withTranslation } from "react-i18next";
import axios from "../../../axios";
import SelectSearch, { fuzzySearch } from "react-select-search";
class ViewVenueServicePlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
      modalAddServicePlan: false,
      servicePlans: this.props.servicePlans,
      selectDefaultServicePlanResident: this.props.selectDefaultServicePlanResident,
      selectDefaultServicePlanWireless: this.props.selectDefaultServicePlanWireless,
      disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.fromWizard && prevState.servicePlans !== this.props.servicePlans) {
     
      var servicePlanArray = [];
      this.setState({
        servicePlans: this.props.servicePlans
      })
      let optionTemplateServicePlans = [];
      for (var i = 0; i < this.props.servicePlans.length; i++) {
        optionTemplateServicePlans.push(
          {
            value: this.props.servicePlans[i]["ServicePlanID"],
            name: this.props.servicePlans[i]["ServicePlanName"]
          }
        );
      }
      servicePlanArray.push(
        <MDBSelect
          id="selectServicePlan"
          className="form-control"
          getValue={this.handleSelectServicePlan}
          disabled={this.state.disabled}
        >
          <MDBSelectInput className="selectInput selectServicePlan" selected="Choose the Service Plan to Add" />
          <MDBSelectOptions search>{optionTemplateServicePlans}</MDBSelectOptions>
        </MDBSelect>
      );
      this.setState({
        servicePlanForm: servicePlanArray,
        optionTemplateServicePlans: optionTemplateServicePlans,
        loading_prop_serviceplans: false,
        selectDefaultServicePlanResident: this.props.selectDefaultServicePlanResident
      });
    }
    else if (this.props.servicePlans !== prevProps.servicePlans && this.props.fromWizard !== true && this.props.servicePlans !== undefined) {
      // eslint-disable-next-line no-redeclare
      var servicePlanArray = [];
      this.setState({
        servicePlans: this.props.servicePlans
      })
      let optionTemplateServicePlans = [];
      // eslint-disable-next-line no-redeclare
      for (var i = 0; i < this.props.servicePlans.length; i++) {
        optionTemplateServicePlans.push(
          {
            value: this.props.servicePlans[i]["ServicePlanID"],
            name: this.props.servicePlans[i]["ServicePlanName"]
          }
        );
      }
      servicePlanArray.push(
        <MDBSelect id="selectServicePlan" className="form-control" getValue={this.handleSelectServicePlan}>
          <MDBSelectInput className="selectInput selectServicePlan" selected="Choose the Service Plan to Add" />
          <MDBSelectOptions search>{optionTemplateServicePlans}</MDBSelectOptions>
        </MDBSelect>
      );
      this.setState({
        servicePlanForm: servicePlanArray,
        optionTemplateServicePlans: optionTemplateServicePlans,
        loading_prop_serviceplans: false,
        selectDefaultServicePlanResident: this.props.selectDefaultServicePlanResident
      });
    }
  }
  toggleAddServicePlan = () => {
    this.setState({
      modalAddServicePlan: !this.state.modalAddServicePlan,
    });
  };
  modifyVenue = (key) => {
    this.setState({
      modalModifyVenue: !this.state.modalModifyVenue,
    });
    this.setState({
      venueID: key,
    });
  };
  handleSelectDefaultServicePlanResident = (value) => {
    this.setState({
      selectGroup: value,
      loading: true
    });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess: value,
    };
    axios
      .post("/networkoperatorconsole/venues/configureVenue", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Default Service Plan for Residential Unit Set!", {
            closeButton: false,
          });
          this.setState({ loading: false })
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.setState({ loading: false })
        }
      );
  };
  handleSelectDefaultServicePlanWireless = (value) => {
    this.setState({
      selectGroup: value,
      loading: true
    });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      DefaultServicePlanID_LocalWirelessAccess: value,
    }
    axios
      .post("/networkoperatorconsole/venues/configureVenue", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Default Service Plan for Guest Subscription Set!", {
            closeButton: false,
          });
          this.setState({ loading: false })
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.setState({ loading: false })
        }
      );
  };
  submitAddServicePlan = (servicePlanID) => {
    this.setState({ add_serviceplan: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      SharedSecret: this.props.sharedSecret,
      ServicePlanID: servicePlanID,
    };
    axios
      .post("/networkoperatorconsole/venues/enableServicePlan", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Added Service Plan to Venue!", {
            closeButton: false,
          });
          this.toggleAddServicePlan();
          this.props.updateName(this.props.venueName, this.props.venueID);
          this.props.getVenueDetails(this.props.venueID);
          this.setState({ add_serviceplan: false });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleAddServicePlan();
          this.setState({ add_serviceplan: false });
        }
      );
  };
  render() {
    if (this.state.postMessage) {
      var h = document.getElementById("overlayResidentManagementRecent");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage: "" });
      }, 6000);
    }
    return (
      <>
        {this.state.disable_serviceplans === true || this.state.loading === true ? (
          <div id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <MDBModal isOpen={this.state.modalAddServicePlan} toggle={this.toggle} size="fluid" className="form" centered>
          <AddServicePlanToVenue
            toggleAddServicePlan={this.toggleAddServicePlan}
            venueName={this.props.venueName}
            submitAddServicePlan={this.submitAddServicePlan}
            add_serviceplan={this.state.add_serviceplan}
          />
        </MDBModal>
        {this.props.servicePlans !== undefined ? (
          <>
            <div className="servicePlans">
              <MDBRow>
                <MDBCol col="6">
                  <label
                    htmlFor="defaultFormLoginEmailEx"
                    className="black-text"
                  >
                    Default Service Plan: Residential Subscriptions
                  </label>
                  <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                    <MDBIcon far icon="question-circle" />
                    <span>
                      The service plan a subscriber will be placed on if no service plan is selected when they are added
                    </span>
                  </MDBTooltip>
                  <SelectSearch
                    id="selectDefaultServicePlanResident"
                    search={true}
                    filterOptions={fuzzySearch}
                    options={
                      this.state
                        .optionTemplateServicePlans
                    }
                    inputId="groupArray"
                    classNamePrefix="group-select"
                    onChange={
                      this.handleSelectDefaultServicePlanResident
                    }
                    value={
                      this.props.selectDefaultServicePlanResident
                    }
                    placeholder="Select..."
                    disabled={this.state.disabled}
                  />
                </MDBCol>
                <MDBCol col="6">
                  <label
                    htmlFor="defaultFormLoginEmailEx"
                    className="black-text"
                  >
                    Default Service Plan: Guest Subscription
                  </label>
                  <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                    <MDBIcon far icon="question-circle" />
                    <span>
                      The service plan a guest subscriber will be placed on
                    </span>
                  </MDBTooltip>
                  <SelectSearch
                    id="selectDefaultServicePlanWireless"
                    search={true}
                    filterOptions={fuzzySearch}
                    options={
                      this.state
                        .optionTemplateServicePlans
                    }
                    inputId="groupArray"
                    classNamePrefix="group-select"
                    onChange={
                      this.handleSelectDefaultServicePlanWireless
                    }
                    value={
                      this.props.selectDefaultServicePlanWireless
                    }
                    placeholder="Select..."
                    disabled={this.state.disabled}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              {this.props.servicePlans !== undefined ? (
                <>
                  <div className="servicePlans">
                    <VenueServicePlansTable
                      servicePlans={this.props.servicePlans}
                      refreshed={this.props.refreshed}
                      disablePlanConfirm={this.props.disablePlanConfirm}
                    />
                  </div>
                </>
              ) : (
                <MDBSpinner small />
              )}
            </div>
          </>
        ) : (
          <MDBSpinner small />
        )}
        <MDBBtn
          onClick={this.toggleAddServicePlan}
          className="btn-right btn-danger"
          disabled={this.state.disabled}
        >
          Add Service Plan
        </MDBBtn>
      </>
    );
  }
}
export default withTranslation()(ViewVenueServicePlans);