import { Component } from "react";

import { MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import PendingGatewayVLANDisableTable from "../../../components/NetworkMgmt/Support/Datatables/PendingGatewayVLANDisableTable";
class EquipmentErrorLog extends Component {

    state = {
		pendingGatewayVLANDisable: this.props.pendingGatewayVLANDisable
	};

	render() {
		return (

<div className="logList">
<MDBModalHeader toggle={this.props.togglelistPendingGatewayVLANDisable}>
          <h4>Pending Gateway VLAN Disable</h4>
        </MDBModalHeader>
        <MDBModalBody>
								<MDBRow>
									<MDBCol col="12">
										{this.state.pendingGatewayVLANDisable.length !== 0 ?
											<PendingGatewayVLANDisableTable
                                                pendingGatewayVLANDisable={this.props.pendingGatewayVLANDisable}
                                            />
											:
											<div className="spinner-border spinner-border-sm" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										}
									</MDBCol>

								</MDBRow>
                                </MDBModalBody>
							</div>
                            
                            );
                        }
                    }

export default EquipmentErrorLog;