/* Find Deals screen */

import React, { Component, Suspense } from "react";

import axios from "../../axios";

import "../../brands/common/css/common.scss";

import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";


let PaymentSuccessComponent;

let Logo;

let BrandName;

let PhoneNumber;

export const SUB_PAGES = brandsData.forEach(
  ({ name, url, phone_number, component_directory_name, brand_directory_name, custom_component_directory_name, main_logo }) => {
    if (url.includes(window.location.hostname)) {
      import("../../brands/" + brand_directory_name + "/css/style.scss");
      PaymentSuccessComponent = React.lazy(() =>
        import(
          "../../brands/" +
          custom_component_directory_name +
          "/components/CreateAccount/PaymentSuccess"
        )
      );
      Logo = "/" + brand_directory_name + "/" + main_logo;
      BrandName = name;
      PhoneNumber = phone_number;
    }
  }
);
class PaymentSuccess extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      email: undefined,
      optionTemplateUnit: undefined,
      unit: undefined,
      tandc: false,
      dateStart: null,
      disableButton: "disabled",
      message: undefined
    };
  }

  componentDidMount() {
    this.getReceipt();
  }

  getReceipt = () => {
    const body = {
      "StripeClientSecret": sessionStorage.getItem("clientSecret")
    }
    axios
      .post("/networkoperatorconsole/shoppingcart/getStripeReceiptURL", body, {
        headers: {},
      })
      .then((response) => {
        this.setState({
          receiptURL: response.data.StripeReceiptURL
        })
      })
      .catch((error) => {
        this.setState({
          error: true
        });
      });
  }

  render() {
    return (
      <>
        {this.state.message !== undefined ? (
          <div className="overlay" style={{ display: "block" }}>
            <div class="overlayMessage">
              {this.state.message}
              <div className="nextButton">
                <br />
                <a
                  className="btn Ripple-parent btn-default"
                  href="/"
                  rel="noreferrer"
                  onClick={this.handleAnchorClick}
                >
                  Return to Login
                </a>
              </div>
            </div>

          </div>
        ) : ""}
        <Suspense fallback={<div
          className="overlay"
          id="overlay"
          style={{ display: "block" }}
        >
          <div className="overlayMessage">
            <div
              className="spinner-border spinner-border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>}>
          <PaymentSuccessComponent
            receiptURL={this.state.receiptURL}
            logo={Logo}
            brand={BrandName}
            phone={PhoneNumber}
          />
          <Footer />
        </Suspense>
      </>
    );
  }
}

export default PaymentSuccess;