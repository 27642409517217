import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
  [datatable] = React.useState("");
  if (props.tickets !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.tickets)) {
      userAttributes.push({
        id: key,
        status: props.tickets[key]["status"],
        contact: props.tickets[key]["contactName"],
        dateEntered: Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }).format(new Date(props.tickets[key]["dateEntered"])),
        lastUpdated: Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }).format(new Date(props.tickets[key]["lastUpdated"])),
        summary: props.tickets[key]["summary"],
        unitID: props.tickets[key]["UnitID_support"],
        type: Object.values(props.tickets[key]),
        clickEvent: () => props.toggleTicket(key),
      });
      i = i + 1;
    }
  }

  [datatable] = React.useState({
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 200,
      },
      {
        label: t("viewtickets.status"),
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: t("viewtickets.summary"),
        field: "summary",
        sort: "asc",
        width: 200,
      },
      {
        label: t("viewtickets.dateentered"),
        field: "dateEntered",
        sort: "asc",
        width: 200,
      },
      {
        label: t("viewtickets.lastupdated"),
        field: "lastUpdated",
        sort: "asc",
        width: 200,
      },
      {
        label: t("viewtickets.contact"),
        field: "contact",
        sort: "asc",
        width: 200,
      },
      {
        label: t("viewtickets.unitid"),
        field: "unitID",
        sort: "asc",
        width: 200,
      },
    ],
    rows: userAttributes,
  });
  return (
    <MDBDataTableV5
      responsive
      hover
      data={datatable}
      searchBottom={false}
      paging={false}
      className="modifyStaff"
      info={false}
      noRecordsFoundLabel={t("recentservice.nomatches")}
    />
  );
}