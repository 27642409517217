import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];
  if (
   props.venueList !== undefined 
   // &&
   // props.venueListUtilization !== undefined
  ) {
    // console.log(props.unitData)
    for (const key of Object.keys(
      props.venueList
    )) {
    //  if (
    //   props.venueListUtilization[props.venueList[key]["VenueID"]] !==
    //   undefined
    // ) {
     if (
      props.venueList!==
      undefined
    ) {
        userAttributes.push({
         id: key,
          venueName: props.venueList[key]["VenueName"],
          venueID: props.venueList[key]["VenueID"],
          address:
            props.venueList[key]["AddressLine1"],
          address2: props.venueList[key]["AddressLine2"],
          city: props.venueList[key]["City"],
          state: props.venueList[key]["State"],
          postalcode: props.venueList[key]["PostalCode"],
          unitCount:
            props.venueList[key]["DailyReport"][
              "UnitCount_GlobalWirelessPlusLocalWiredAccess"
            ] +
            props.venueList[key]["DailyReport"][
              "UnitCount_LocalWirelessAccess"
            ] +
            props.venueList[key]["DailyReport"][
              "UnitCount_GlobalWirelessRoaming"
            ],
          // policyCount:
          //   props.venueListUtilization[props.venueList[key]["VenueID"]][
          //     "PolicyCount_GlobalWirelessPlusLocalWiredAccess"
          //   ] +
          //   props.venueListUtilization[props.venueList[key]["VenueID"]][
          //     "PolicyCount_LocalWirelessAccess"
          //   ],
          subscriberCount:
            props.venueList[key]["DailyReport"][
              "PolicyCount_GlobalWirelessPlusLocalWiredAccess"
            ] + 
            props.venueList[key]["DailyReport"][
             "PolicyCount_LocalWirelessAccess"
           ],
          deviceCount:
            props.venueList[key]["DailyReport"][
              "OnlineDeviceCount"
            ],
          // status:
          //   props.venueListUtilization[props.venueList[key]["VenueID"]][
          //     "currentOperatorStatus"
          //   ] === "Operational" ? (
          //     <div className="active-circle"></div>
          //   ) : (
          //     <div className="inactive-circle"></div>
          //   )
        });
        i = i + 1;
    }
   }
  }
  columns = [
    {
      headerName: "Venue",
      field: "venueName",
      // minWidth: 120,
      flex: 0.2,
    },
    {
      headerName: "Address",
      field: "address",
      flex: 0.2,
      renderCell: (params) => (
        // console.log(params)
        <div>
          <div>{params.row.address}</div>
          <div>{params.row.address2}</div>
          <div>
            {params.row.city}, {params.row.state} {params.row.postalcode}
          </div>
        </div>
      ),
    },
    {
      headerName: "Unit Count",
      field: "unitCount",
      // minWidth: 120,
      flex: 0.2,
    },
    {
      headerName: "Subscriber Count",
      field: "subscriberCount",
      // minWidth: 120,
      flex: 0.2,
    },
    {
      headerName: "Device Count",
      field: "deviceCount",
      // minWidth: 120,
      flex: 0.2,
    },
  ];
  rows = userAttributes;
  return (
    <div style={{ height: 220, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooterPagination={false}
        hideFooter={false}
        getRowHeight={({ id, densityFactor }) => {
          return 60 * densityFactor;
        }}
        onRowClick={(id) =>
          props.modifyVenue(
            id.row["venueName"],
            id.row["id"]
          )
        }
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </div>
  );
}
