import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
  datatable = ({
   columns: columns,
   rows: userAttributes,
 });
 console.log(props.transactions)
  if (props.transactions !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.transactions)) {
      userAttributes.push({
        servicePlan: <>{props.transactions[key]["ServicePlanName"]}<br/>{props.transactions[key]["ServicePlanDescription"]}</>,

        subEndDate:
          props.transactions[key]["InvoiceDateTimeUTC.ISO8601"] !== ""
            ? Intl.DateTimeFormat("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
              }).format(new Date(props.transactions[key]["InvoiceDateTimeUTC.ISO8601"]))
            : "",
        paymentSuccessDate:
            props.transactions[key]["PaymentSuccessDateTimeUTC.ISO8601"] !== ""
              ? Intl.DateTimeFormat("en-CA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(props.transactions[key]["PaymentSuccessDateTimeUTC.ISO8601"]))
              : "",
        priceAdjustment: props.transactions[key]["CurrencySymbol"]+props.transactions[key]["VenuePriceAdjustment"]/100,
        billingCyclePrice: props.transactions[key]["CurrencySymbol"]+(props.transactions[key]["BillingCyclePrice."+props.transactions[key]["Currency"]]/100).toFixed(2),
        venuePriceAdjustment: props.transactions[key]["CurrencySymbol"]+props.transactions[key]["VenuePriceAdjustment"]/100,
        amountReceived: props.transactions[key]["AmountReceived."+props.transactions[key]["Currency"]] !== "undefined" ? props.transactions[key]["CurrencySymbol"]+(props.transactions[key]["AmountReceived."+props.transactions[key]["Currency"]]/100).toFixed(2) : "",
        receiptLink: <>{props.transactions[key]['StripeReceiptURL']!==""?<a href={"mailto:"+props.transactions[key]['EmailAddress']+"?subject="+props.transactions[key]['VenueName']+" invoice link&body=Reciept Link -"+props.transactions[key]['StripeReceiptURL']+""} className="noButton" target="_blank" rel="noreferrer">Email Receipt</a>:""}</>,  
        refundLink: <>{props.transactions[key]["AmountReceived."+props.transactions[key]["Currency"]]>0?<a  href="#link" onClick={() => {props.toggleRefund(0, props.transactions[key]["AmountReceived."+props.transactions[key]["Currency"]] - props.transactions[key]["AmountRefunded."+props.transactions[key]["Currency"]], props.transactions[key]["CurrencySymbol"], props.transactions[key]["Currency"], props.transactions[key]["InvoiceDateTimeUTC.ISO8601"], props.transactions[key]["BillingCycleHours"], props.transactions[key]["StripeClientSecret"], props.stripeClientParentSecret, props.transactions[key]["AmountRefunded."+props.transactions[key]["Currency"]])}}>Refund</a>:""}</>,  
        amountRefunded: props.transactions[key]["CurrencySymbol"]+props.transactions[key]["AmountRefunded."+props.transactions[key]["Currency"]]/100
      });
      i = i + 1;
    }
  }
  console.log(userAttributes)
  let columns;
  if(window.location.pathname === "/networkdashboard"){
   datatable = ({
    columns : [
      {
          label: "Invoice Date",
          field: "subEndDate",
        }, 
        {
          label: "Payment Success Date",
          field: "paymentSuccessDate",
        },
      {
      label: "Service Plan",
      field: "servicePlan",
    },
    {
      label: "Service Plan Price",
      field: "billingCyclePrice",
    },
    {
      label: "Amount Received",
      field: "amountReceived",
    },
    {
      label: "Amount Refunded",
      field: "amountRefunded",
    },
    {
      label: "Refund Link",
      field: "refundLink",
    }
    
  ],
  rows: userAttributes,})
  }else{
   datatable = ({
    columns : [
      {
          label: "Invoice Date",
          field: "subEndDate",
        }, 
        {
          label: "Payment Success Date",
          field: "paymentSuccessDate",
        },
      {
      label: "Service Plan",
      field: "servicePlan",
    },
    {
      label: "Service Plan Price",
      field: "billingCyclePrice",
    },
    {
      label: "Amount Received",
      field: "amountReceived",
    },
    {
      label: "Amount Refunded",
      field: "amountRefunded",
    }
    
  ],
  rows: userAttributes,})
  }
  
  return (
    <MDBDataTableV5
      responsive
      hover
      data={datatable}
      searchBottom={false}
      paging={false}
      className="modifyStaff"
      info={false}
      noRecordsFoundLabel={t("recentservice.nomatches")}
    />
  );
}