import React, { Component } from "react";

class Logout extends Component {

constructor(props) {
          super(props);
          this.state = {
               message: "You are now being redirected back to your Dish account",
          };
     }

componentDidMount() {
          this.logout();
     }
logout = () => {
     if (this.state.message!=="") {
        var h = document.getElementById("overlay");
        h.style.display = "block";
        setTimeout(function () {
            h.style.display = "block";
        }, 3000);
        setTimeout(() => {
            h.style.display = "none";
            window.location.href = 'https://my-tqa.dish.com/fiber/account/profile'; 
            this.setState({
               message: ""
            });
        }, 3000);
    }
}


render() {
     return (
               <div id="overlay" style={{ display: "block" }}>
                    <div class="overlayMessage">{this.state.message}</div>
               </div>
     )
}
};

export default Logout;