import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
function isOverflown(element) {
 return (
   element.scrollHeight > element.clientHeight ||
   element.scrollWidth > element.clientWidth
 );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
 const { width, value } = props;
 const wrapper = React.useRef(null);
 const cellDiv = React.useRef(null);
 const cellValue = React.useRef(null);
 const [anchorEl, setAnchorEl] = React.useState(null);
 const [showFullCell, setShowFullCell] = React.useState(false);
 const [showPopper, setShowPopper] = React.useState(false);
 const handleMouseEnter = () => {
   const isCurrentlyOverflown = isOverflown(cellValue.current);
   setShowPopper(isCurrentlyOverflown);
   setAnchorEl(cellDiv.current);
   setShowFullCell(true);
 };
 const handleMouseLeave = () => {
   setShowFullCell(false);
 };
 React.useEffect(() => {
   if (!showFullCell) {
     return undefined;
   }
   function handleKeyDown(nativeEvent) {
     // IE11, Edge (prior to using Bink?) use 'Esc'
     if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
       setShowFullCell(false);
     }
   }
   document.addEventListener('keydown', handleKeyDown);
   return () => {
     document.removeEventListener('keydown', handleKeyDown);
   };
 }, [setShowFullCell, showFullCell]);
 return (
   <Box
     ref={wrapper}
     onMouseEnter={handleMouseEnter}
     onMouseLeave={handleMouseLeave}
     sx={{
       alignItems: 'center',
       lineHeight: '24px',
       width: '100%',
       height: '100%',
       position: 'relative',
       display: 'flex',
     }}
   >
     <Box
       ref={cellDiv}
       sx={{
         height: '100%',
         width,
         display: 'block',
         position: 'absolute',
         top: 0,
       }}
     />
     <Box
       ref={cellValue}
       sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
     >
       {value}
     </Box>
     {showPopper && (
       <Popper
         open={showFullCell && anchorEl !== null}
         anchorEl={anchorEl}
         style={{ width, marginLeft: -17 }}
       >
         <Paper
           elevation={1}
           style={{ minHeight: wrapper.current.offsetHeight - 3 }}
         >
           <Typography variant="body2" style={{ padding: 8 }}>
             {value}
           </Typography>
         </Paper>
       </Popper>
     )}
   </Box>
 );
});
function renderCellExpand(params) {
 return (
   <GridCellExpand value={params.formattedValue || ''} width={displayTextWidth(params.formattedValue, ".9rem Proxima Nova Rg")} />
 );
}
function displayTextWidth(text, font) {
 let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
 let context = canvas.getContext("2d");
 context.font = font;
 let metrics = context.measureText(text);
 return metrics.width + 30;
}
export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];

 const dateFormatter = new Intl.DateTimeFormat("en-US");
  if (
    props.keys !== undefined
    // &&
    // props.venueListUtilization !== undefined
  ) {
   // console.log(props.keys)
   for (const key of Object.keys(props.keys[0])){
    let keyid = key;
    let keyNetwork;
      // console.log(Object.keys(props.keys[0][key]))
      
      for (const keyName of Object.keys(props.keys[0][key])){
       // console.log(keyName)
       if(keyName==="ssid"){
        keyNetwork = props.keys[0][key][keyName]
       }
       if(keyName!=="ssid" && keyName!=="DisplayOrder"){
      // let keytag = props.keys[0][key][keyName]['KeyTag']
      // let keypsk = props.keys[0][key][keyName]['psk']
      // let keynetwork = props.keys[0][key][keyName]['network']
      // let keyselectednetworkindex = props.keys[0][key][keyName]['selectedNetworkIndex']
      // let iswpa2 = props.keys[0][key][keyName]['wpa2enterprise_auth']
      // let devices = props.keys[0][key][keyName]['Devices']
      // console.log(keytag)
      // console.log(props.keys[0][key][keyName]['KeyTag'])
      //   i = i + 1;
      userAttributes.push({
       id: keyName,
       keyselectednetworkindex: i,
       status: props.keys[0][key][keyName]['currentState'],
       profile: props.keys[0][key][keyName]['KeyTag'],
       network: keyNetwork,
       activated: props.keys[0][key][keyName]['dateAddedUTC.ISO8601']!==undefined?Intl.DateTimeFormat('en-US',{
                                              month: 'numeric',
                                              day: 'numeric',
                                              year: 'numeric',
                                            }).format(new Date(props.keys[0][key][keyName]['dateAddedUTC.ISO8601'])):"",
       psk: props.keys[0][key][keyName]['psk'],
       iswpa2: props.keys[0][key][keyName]['wpa2enterprise_auth'],
       devices: props.keys[0][key][keyName]['Devices'],
       venueUnitPolicies: props.keys[0][key][keyName]['VenueUnitPolicies']
       //  expiring: "",
       // clickEvent: ()=>props.toggleManageKey(true, keyid, keytag, keypsk, keynetwork, keyselectednetworkindex, iswpa2, devices)

  })
  // console.log(userAttributes)
      }
      
     }

    i = i + 1;
      //  if (
      //   props.venueListUtilization[props.venueList[key]["VenueID"]] !==
      //   undefined
      // ) {
      // if (props.venueUnitPolicyInfo !== undefined) {
      //   userAttributes.push({
      //     id: key,
      //     profileName: props.profileName,
      //     networkName: props.networkName,
      //     venueName: props.venueUnitPolicyInfo[key]["VenueName"],
      //     unitName: props.venueUnitPolicyInfo[key]["UnitID"],
      //     buildingName: props.venueUnitPolicyInfo[key]["UnitBuilding"],
      //     floorName: props.venueUnitPolicyInfo[key]["UnitFloor"],
      //     psk: props.psk
      //   });
      
      // }
    }
  }
  columns = [
    {
      headerName: "Status",
      field: "status",
      // minWidth: 120,
      flex: 0.2,
      renderCell: (params) => (
       params.row.status==="Active"?<div className="active-circle"></div>:<div className="inactive-circle"></div>
      )
    },
    {
     headerName: "Profile",
     field: "profile",
     // minWidth: 120,
     flex: 0.2,
     
    },
    {
      headerName: "Network",
      field: "network",
      flex: 0.2,
      minWidth: 200,
      
    },
    {
     headerName: "Activated",
     field: "activated",
     flex: 0.2,
     minWidth: 200
    }
    // {
    //   headerName: "Unit",
    //   field: "unitID",
    //   // minWidth: 120,
    //   flex: 0.2,
    //   renderCell: (params) => (
    //    <div>
    //       <div>Unit: {params.row.unitName}</div>
    //       <div>Building: {params.row.buildingName}</div>
    //       <div>
    //         Floor: {params.row.floorName}
    //       </div>
    //     </div>
    //  ),
    // },
    // {
    //   headerName: "PSK",
    //   field: "psk",
    //   // minWidth: 120,
    //   flex: 0.2,
    // },
  ];
  rows = userAttributes;
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooterPagination={false}
        hideFooter={false}
        getRowHeight={({ id, densityFactor }) => {
          return 60 * densityFactor;
        }}
        slots={{ toolbar: QuickSearchToolbar }}
        disableRowSelectionOnClick={true}
        onRowClick={(id) =>
         props.toggleManageKey(
          true, 
          id.row["keyselectednetworkindex"],
          id.row["profile"],
          id.row["psk"],
          id.row["network"],
          id.row["keyselectednetworkindex"],
          id.row["iswpa2"],
          id.row["devices"],
          id.row["venueUnitPolicies"]
         )
       }
      />
    </div>
  );
}
