import React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];
  if (props.supportedLocalesData !== undefined) {
    for (const key of Object.keys(props.supportedLocalesData)) {
      userAttributes.push({
        id: key,
        locale: props.supportedLocalesData[key],
        localeValue: key
      })
      i = i + 1;
    }
  }
    columns= [
      {
        headerName: "Locale",
        field: 'locale',
        minWidth: 120,
        flex: 0.5
      },
      {
        headerName: "Value",
        field: 'localeValue',
        minWidth: 120,
        flex: 0.5
      }
    ];
    rows = userAttributes
  return (
    <div style={{ height: 200, width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      hideFooterPagination={true}
      hideFooter={true}
      getRowHeight={({ id, densityFactor }) => {
        return 50 * densityFactor;
      }}
    />
  </div>
  );
}