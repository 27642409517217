import React, { Component } from "react";

import {
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBRow,
	MDBCol,
	MDBSpinner,
} from "mdbreact";

import ManageDevicesData from "../../../components/SubscriberMgmt/ManageDevices/Datatables/ManageDevicesData";

import DeviceDetails from "../../../components/SubscriberMgmt/DeviceInformation/DeviceDetails";

import { withTranslation } from "react-i18next";

class ManageDevices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalManageDevices: false,
		};
	}

	deviceDetails = (device) => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
			deviceDetails: device,
		});
	};

	toggleDeviceDetailsClose = () => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
		});
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MDBModal isOpen={this.state.modalDeviceDetails} toggle={this.toggle} size="lg" className="form">
					<DeviceDetails
						toggleDeviceDetails={this.toggleDeviceDetailsClose}
						deviceDetails={this.state.deviceDetails}
						listKeys={this.props.listKeys}
					/>
				</MDBModal>
				<MDBModalHeader toggle={this.props.toggleManageDevices}>
					<h4>{t("managedevices.title")}</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<MDBRow className="pb-3">
						<MDBCol sm="6" lg="3">
							<p>
								{t("managedevices.devicesonline")} <span className="greenText">{this.props.deviceCountOnline}</span>
							</p>
						</MDBCol>
						<MDBCol sm="6" lg="3">
							<p>
								{t("managedevices.devicesoffline")} <span className="redText">{this.props.deviceCountOffline}</span>
							</p>
						</MDBCol>
					</MDBRow>
					{this.props.devices.length !== 0 ? (
						<>
							<ManageDevicesData deviceData={this.props.devices} deviceDetails={this.deviceDetails} />
						</>
					) : (
						<MDBSpinner small />
					)}
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(ManageDevices);