import React, { Component } from "react";

import {
	toast
} from "mdbreact";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";

import ModifySMS from "../../../components/NetworkMgmt/Forms/ModifySMS/ModifySMS";


class OperatorInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sms: [],
			logoURL: undefined,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
		};
	}

	componentDidMount() {
		this.getSMSInfo();
	}

	getSMSInfo = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		this.setState({
			loading_info: true,
			operatorInfo: undefined,
		});
		axios
			.post("/networkoperatorconsole/equipment/listSMSTemplates", body, {
				headers: {},
			})
			.then(
				(response) => {
					
					this.setState({
						allSMSData: response.data.SMSConfiguration,
						dynamicVariables: response.data.SMSConfiguration["DynamicVariables"]
					})
					this.setState({logoURL: response.data["LogoPNG.URL"]});
					this.setState({ loading_info: false });
					
				},
				(error) => {
					this.setState({ loading_info: false });
				}
			);
	};

	submitModifySMS = (
			language, 
			type, 
			userType,
			smsSubject, 
			smsMessage, 
			logoURL,
			dynamicVariables,
			brandColor,
			smsMainTemplate,
			smsPreheader,
			smsTitle,
			smsHeader,
			smsActionButton,
			smsAdditionalInfo,
			smsTemplate,
			smsNetworkName,
			smsWifiPassword,
			smsSubscriptionStart,
			smsSubscriptionEnd
		) => {
		this.setState({ modify_sms: true });
		let body = {
			"AccessToken": sessionStorage.getItem("AccessToken"),
			
				"LogoPNG.URL": logoURL,
				"SMSConfiguration": {
				"brandcolor": brandColor,
				[language]: {  
				[type]: { 
				[userType]: {
				"Subject": smsSubject,
				"preheader": smsPreheader,
				"title": smsTitle,
				"serviceinfo":{ 
				"template": smsTemplate,
				"networkname": smsNetworkName,
				"wifipassword": smsWifiPassword,
				"subscriptionstart": smsSubscriptionStart,
				"subscriptionend": smsSubscriptionEnd},
				"header": smsHeader,
				"message": smsMessage.replace(/'/g, "\\'").replace(/&amp;/g, "&"),
				"actionbutton": smsActionButton,
				"additional_instructions": smsAdditionalInfo
				},
				"Template": smsMainTemplate
				}
			}
		}
		};
		console.log(body);
		Promise.all([
			axios.post("/networkoperatorconsole/equipment/configureNetworkOperator", body, {
			  headers: {},
			}),
		  ])
			.then
				(async ([res1]) => {
					toast.success("Modified SMS!", {
						closeButton: false,
					});
					this.props.toggleSMSInfo();
					this.setState({ modify_sms: false });
				})
				.catch((error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.setState({ modify_sms: false });
				}
			);
	};

	render() {
		return (
			<>
				{this.state.loading_info === true ? (
					<div className="overlay" id="overlay" style={{ display: "block" }}>
						<div className="overlayMessage">
							<div className="spinner-border spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<ModifySMS
					logoURL={this.state.logoURL}
					allSMSData={this.state.allSMSData}
					brandColor={this.state.brandColor}
					dynamicVariables={this.state.dynamicVariables}
					toggleSMSInfo={this.props.toggleSMSInfo}
					submitModifySMS={this.submitModifySMS}
					modify_sms={this.state.modify_sms}
				/>
			</>
		);
	}
}

export default withTranslation()(OperatorInfo);