import React, { Component } from "react";

import axios from "../../../axios";

import { MDBIcon, MDBCard, MDBCardBody, MDBModal } from "mdbreact";

import Carousel from "react-multi-carousel";

import { CustomLeftArrow, CustomRightArrow } from "../../../components/SubscriberMgmt/DeviceInformation/CustomArrows";

import ManageDevices from "../../../components/SubscriberMgmt/ManageDevices/ManageDevices";

import DeviceDetails from "../../../components/SubscriberMgmt/DeviceInformation/DeviceDetails";

import "react-multi-carousel/lib/styles.css";

import { withTranslation } from "react-i18next";




class DeviceInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalManageDevices: false,
			devices: this.props.devices,
			deviceCountOffline: this.props.deviceCountOffline,
			deviceCountOnline: this.props.deviceCountOnline,
		};
	}

	toggleManageDevices = () => {
		this.setState({
			modalManageDevices: !this.state.modalManageDevices,
		});
	};

	toggleDeviceDetails = (device, controllerID) => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
			deviceDetails: device,
			controllerID: controllerID
		});
	};

	toggleDeviceDetailsClose = () => {
		this.setState({
			modalDeviceDetails: !this.state.modalDeviceDetails,
		});
	};

	listKeys = () => {
		this.setState({ loading: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};

		let bodySessions = [];
		let iterationSessions = 0;
		let updatedSessions = [];

		let venueList = [];
		let keys;

		axios
			.post("/subscriberconsole/listKeys", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					devices: [],
				});
				this.setState({ keys: [] });
				keys = response.data.KeysInKeyPools;
				venueList = Object.keys(response.data.KeysInKeyPools);
				this.setState({ venueList: venueList });
				this.setState({ keys: [keys] });
				this.setState({ venueName: this.state.venueList });
				if (this.state.venueName.length !== 0) {
					for (let i = 0; i < Object.keys(this.state.keys[0][this.state.venueName[0]]).length; i++) {
						
						if(Object.keys(this.state.keys[0][this.state.venueName[0]])[i] !== "DisplayOrder"){
							if(Object.keys(this.state.keys[0][this.state.venueName[0]])[i] !== "ssid"){
							this.setState({ selectedKeyID: Object.keys(this.state.keys[0][this.state.venueName[0]])[i] });
							break;
							}
						}
					}
				}
				this.setState({
					devices: response.data.AllDevices,
					deviceCountOffline: response.data.DeviceCountOffline,
					deviceCountOnline: response.data.DeviceCountOnline,
					messages: response.data.messages_from_venues,
					allData: response.data,
				});

				let keyNameArray = [];
				let keyNameList = [];
				let keyNameListArray = [];
				let keyNameListArrayAll = [];
				var obj = {};
				var obj_all = {};
				for (let i = 0; i < Object.keys(this.state.keys[0]).length; i++) {
					keyNameArray.push(Object.keys(this.state.keys[0][this.state.venueName[i]]));
					keyNameList.push();
					obj[i] = {};
					for (let j = 0; j < keyNameArray[i].length; j++) {
						if (this.state.keys[0][this.state.venueName[i]][keyNameArray[i][j]]["KeyTag"] !== undefined) {
							obj[i][keyNameArray[i][j]] = 0;
							obj[i][keyNameArray[i][j]] = this.state.keys[0][this.state.venueName[i]][keyNameArray[i][j]]["KeyTag"];
							obj_all[keyNameArray[i][j]] = this.state.keys[0][this.state.venueName[i]][keyNameArray[i][j]];
							obj_all[keyNameArray[i][j]].network = this.state.keys[0][this.state.venueName[i]]["ssid"];
							obj_all[keyNameArray[i][j]].selectedNetworkIndex = i;
						}
					}
				}
				keyNameListArrayAll.push(obj_all);
				keyNameListArray.push(obj);
				this.setState({ keyNameListArray: keyNameListArray });
				this.setState({ keyNameListArrayAll: keyNameListArrayAll });
				this.setState({ keyNameArray: keyNameArray });
				const sessionList = response.data.KeysInKeyPools;
				let SessionListID = null;

				bodySessions.push(sessionList);
				bodySessions.forEach((val) => {
					SessionListID = Object.keys(bodySessions[0]);
				});
				if (this.state.SessionList !== []) {
					this.setState({ SessionList: [] });
				}
				SessionListID.forEach((val, index) => {
					updatedSessions = bodySessions.map((sessionList) => {
						return {
							...sessionList[SessionListID[iterationSessions]],
						};
					});
					if (updatedSessions[0] !== undefined) {
						this.setState({
							SessionList: [...this.state.SessionList, updatedSessions],
						});
						iterationSessions = iterationSessions + 1;
					}
				});
				if (this.state.keys !== undefined) {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				this.listKeys();
				this.setState({ loading: false });
				this.setState({ error: error });
			});
	};

	renderDeviceIcon(param) {
		switch (param) {
			case "NetworkDevice":
				return "mobile-alt";
			case "Laptop":
				return "laptop";
			case "Unknown":
				return "question";
			default:
				return "mobile-alt";
		}
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<MDBModal isOpen={this.state.modalManageDevices} toggle={this.toggle} size="fluid" className="form">
					<ManageDevices
						toggleManageDevices={this.toggleManageDevices}
						deviceCountOffline={this.state.deviceCountOffline}
						deviceCountOnline={this.state.deviceCountOnline}
						devices={this.state.devices}
						listKeys={this.listKeys}
					/>
				</MDBModal>
				<MDBModal isOpen={this.state.modalDeviceDetails} toggle={this.toggle} size="lg" className="form">
					<DeviceDetails toggleDeviceDetails={this.toggleDeviceDetailsClose} deviceDetails={this.state.deviceDetails} listKeys={this.listKeys} controllerID={this.state.controllerID} />
				</MDBModal>
				<div class="keyInformation">
					<div className="ctaButton" onClick={this.toggleManageDevices}>
						<h2>
							<MDBIcon icon="desktop" /> {t("devices.title")}
						</h2>
					</div>
					{Object.keys(this.props.devices).length > 0 ? (
						<MDBCard>
							<MDBCardBody className="deviceCardBody1" style={{ flex: "unset" }}>
								<Carousel
									additionalTransfrom={0}
									arrows
									autoPlaySpeed={3000}
									centerMode={true}
									className=""
									containerClass=""
									customLeftArrow={<CustomLeftArrow />}
									customRightArrow={<CustomRightArrow />}
									dotListClass=""
									draggable
									focusOnSelect={false}
									itemClass="device"
									keyBoardControl
									minimumTouchDrag={80}
									renderButtonGroupOutside={false}
									renderDotsOutside={false}
									partialVisible={false}
									responsive={{
										desktop: {
											breakpoint: {
												max: 3000,
												min: 1024,
											},
											items: 4,
											partialVisibilityGutter: 100,
										},
										mobile: {
											breakpoint: {
												max: 464,
												min: 0,
											},
											items: 1,
											partialVisibilityGutter: 30,
										},
										tablet: {
											breakpoint: {
												max: 1024,
												min: 464,
											},
											items: 2,
											partialVisibilityGutter: 30,
										},
									}}
									showDots={false}
									sliderClass="deviceSlider"
									slidesToSlide={1}
									swipeable
								>
									{Object.keys(this.state.devices).map((oneKey, i) => {
										return (
											<div>
												<div className="deviceCardDashboard" onClick={(e) => this.toggleDeviceDetails(this.state.devices[oneKey], oneKey)}>
													<MDBIcon icon={this.renderDeviceIcon(this.state.devices[oneKey]["DeviceType"])} />
													<div
														className={
															this.state.devices[oneKey]["currentlyOnline"] === true
																? "deviceStatusConnected"
																: "deviceStatusDisconnected"
														}
													></div>
													<p>{this.state.devices[oneKey]["UserDefinedHostName"]}</p>
												</div>
											</div>
										);
									})}
								</Carousel>
							</MDBCardBody>
						</MDBCard>
					) : (
						<MDBCard style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
							<MDBCardBody className="messageCardBody1" style={{ flex: "unset" }}>
								<h4 style={{ color: "#BBB" }}>{t("profiledetails.nodevices")}</h4>
							</MDBCardBody>
						</MDBCard>
					)}
				</div>
			</>
		);
	}
}

export default withTranslation()(DeviceInformation);