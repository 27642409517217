import React, { Component } from "react";

import { MDBBtn, MDBRow, MDBCol, MDBSpinner, MDBModalBody, MDBModalHeader } from "mdbreact";

import axios from "../../../axios";

import { DateInput } from "semantic-ui-calendar-react";

import InvoicesTable from "../../../components/NetworkMgmt/Transactions/Datatables/InvoicesTable";


class Transactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dateStart: undefined,
			dateEnd: undefined
		};
	}

	handleChange = (event, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
	};

	findTransactions = (event) => {
		event.preventDefault();
		this.setState({loading:true});
		
		if(this.state.dateStart!==undefined && this.state.dateEnd!==undefined){
		let body = {
			"AccessToken": sessionStorage.getItem("AccessToken"),
    		"MinInvoiceDateTimeUTC.ISO8601": new Date(this.state.dateStart).toISOString(),
    		"MaxInvoiceDateTimeUTC.ISO8601": new Date(this.state.dateEnd).toISOString()
		}
		axios
			.post("/networkoperatorconsole/shoppingcart/listPayments", body, {
				headers: {},
			})
			.then(
				(response) => {
					this.setState({ 
						transactions: response.data.Payments,
						totalInvoices: response.data.PaymentCount,
						hoursBilled: response.data.BillingCycleHours,
						downloadGBPS: response.data.DownloadKbps/(1000*1000),
						pricePerGBPS: response.data["PricePerDownloadGbps.USD"],
						unitLevelAdjustments: response.data.VenuePriceAdjustment
					 });
					this.setState({ loading: false });
				},
				(error) => {
					this.setState({ loading: false });
				}
			);
		}
	}

	render() {
		let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();
		return (
			<>
		    <MDBModalHeader toggle={this.props.toggleInvoicesTable}>
			
		    <h4>Search for Invoices</h4>
			</MDBModalHeader>
		    <MDBModalBody>
		    <form className="needs-validation" onSubmit={this.findTransactions} noValidate>
						<MDBRow>
							<MDBCol col="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									Start Date
								</label>
								<DateInput
									dateFormat="MM/DD/YYYY"
									name="dateStart"
									placeholder="MM/DD/YYYY"
									value={this.state.dateStart}
									iconPosition="left"
									onChange={this.handleChange}
									className="dateTimePicker"
									maxDate={new Date(tomorrow)}
								/>
							</MDBCol>
							<MDBCol col="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									End Date
								</label>
								<DateInput
									dateFormat="MM/DD/YYYY"
									name="dateEnd"
									placeholder="MM/DD/YYYY"
									value={this.state.dateEnd}
									iconPosition="left"
									onChange={this.handleChange}
									className="dateTimePicker"
									minDate={new Date(this.state.dateStart)}
									maxDate={new Date(tomorrow)}
								/>
							</MDBCol>
						</MDBRow>
						{this.state.transactions !== undefined ? (
							<>
								{this.state.loading === true ? 
									<MDBSpinner small />
									:
									<>
						<MDBRow>
							<MDBCol className="mt-3" sm="12" lg="6">
								<p>
									<strong>Total Invoices</strong>
								</p>
								<p className="breakText">
									{this.state.totalInvoices}
								</p>
							</MDBCol>
							<MDBCol className="mt-3" sm="12" lg="6">
							<p>
									<strong>Hours Billed</strong>
								</p>
								<p className="breakText">
									{this.state.hoursBilled}
								</p>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol className="mt-3" sm="12" lg="6">
								<p>
									<strong>Download (Gbps)</strong>
								</p>
								<p className="breakText">
									{this.state.downloadGBPS}
								</p>
							</MDBCol>
							<MDBCol className="mt-3" sm="12" lg="6">
							<p>
									<strong>Price per Gbps</strong>
								</p>
								<p className="breakText">
									${this.state.pricePerGBPS}
								</p>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol className="mt-3" sm="12" lg="6">
								<p>
									<strong>Unit level adjusements</strong>
								</p>
								<p className="breakText">
									{this.state.unitLevelAdjustments}
								</p>
							</MDBCol>
							<MDBCol className="mt-3" sm="12" lg="6">
							
							</MDBCol>
						</MDBRow>
						<br/>
						
						
									<InvoicesTable
										transactions={this.state.transactions}
									/>

						</>
								}
							</>
						):
						<>
						<br/>
						
									<InvoicesTable
										transactions={this.state.transactions}
									/>

						</>
						}
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								Find Transactions
							</MDBBtn>
						</div>
			</form>
			</MDBModalBody>
			</>
		)
	}
}

export default Transactions;