import React, { Component } from 'react';

import { MDBRow, MDBCol, MDBModal } from "mdbreact";

import { withTranslation } from 'react-i18next';

import ViewModifyResidents1to7Days from '../../../components/VenueMgmt/RecentResidents/ViewModifyResidents1to7Days';

import UpcomingExpirations14Days from '../../../components/VenueMgmt/UpcomingExpirations/UpcomingExpirations14Days';

class ActionSummary extends Component {
	constructor(props) {
      super(props);
      this.state = {
          modalViewModifyResident: false,
          modalServicesEndingSoon: false,
          loading: false
    	};
    }

	toggleViewModifyResident = () => {
      	this.setState({
        	modalViewModifyResident: !this.state.modalViewModifyResident
      	});
  	}

  	toggleViewModifyResident1Day = () => {
      	this.setState({
        	modalViewResident1Day: !this.state.modalViewResident1Day
      	});
  	}

  	toggleViewModifyResident7Days = () => {
      	this.setState({
        	modalViewResident7Days: !this.state.modalViewResident7Days
      	});
  	}

  	toggleServicesEndingSoon = () => {
      	this.setState({
        	modalServicesEndingSoon: !this.state.modalServicesEndingSoon
      	});
  	} 

	render(){
		const {t} = this.props;
		return(
			<>
			<MDBModal isOpen={this.state.modalViewResident1Day} toggle={this.toggle} size="fluid" className="form" centered>
	            <ViewModifyResidents1to7Days
	              toggleViewModifyResident={this.toggleViewModifyResident1Day}
	              recentResidents={this.props.recentResidents}
	              allRecentResidents = {this.props.newResidentsToday}
	              venueID = {this.props.venueID}
	              venueName={this.props.venueName}
	              updateName={this.props.updateName}
	              loading={this.state.loading}
	              venueType={this.props.venueType}
	              title="New Residents Today"
	              search="1"
	            />
        	</MDBModal>
			<MDBModal isOpen={this.state.modalViewResident7Days} toggle={this.toggle} size="fluid" className="form" centered>
	            <ViewModifyResidents1to7Days
	              toggleViewModifyResident={this.toggleViewModifyResident7Days}
	              recentResidents={this.props.recentResidents}
	              allRecentResidents = {this.props.newResidents7Days}
	              venueID = {this.props.venueID}
	              venueName={this.props.venueName}
	              updateName={this.props.updateName}
	              loading={this.state.loading}
	              venueType={this.props.venueType}
	              title="New Residents Next 7 Days"
	              search="7"
	            />
        	</MDBModal>
        	<MDBModal isOpen={this.state.modalServicesEndingSoon} toggle={this.toggle} size="fluid" className="form" centered>
            	<UpcomingExpirations14Days
	              toggleServicesEndingSoon={this.toggleServicesEndingSoon}
	              expirations={this.props.expirations}
	              venueID={this.props.venueID}
	              venueName={this.props.venueName}
	              updateName={this.props.updateName}
	              title="Expiring Within 14 Days"
	            />
        	</MDBModal>
			<div className="summaryCard">
				<h2>{t('activitysummary.title')}</h2>
				<div className="actionSummary rounded second-step">
				  	<MDBRow>
				    	<MDBCol sm="9" md="9">
				    		<a href="#actionSummary" onClick={this.toggleViewModifyResident1Day}><p>{this.props.venueType==="HOTSPOT"?"New Subscribers Today":t('activitysummary.newresidentstoday')}</p></a>
				    	</MDBCol>
				    	<MDBCol sm="9" md="3">
				    		<a href="#actionSummary" onClick={this.toggleViewModifyResident1Day}><p>{this.props.newResidentsTodayCount}</p></a>
				    	</MDBCol>
				 	</MDBRow>
				 	<MDBRow>
				    	<MDBCol md="9">
				    		<a href="#actionSummary" onClick={this.toggleViewModifyResident7Days}><p>{this.props.venueType==="HOTSPOT"?"New Subscribers Next 7 Days":t('activitysummary.newresidents7days')}</p></a>
				    	</MDBCol>
				    	<MDBCol md="3">
				    		<a href="#actionSummary" onClick={this.toggleViewModifyResident7Days}><p>{this.props.newResidents7DaysCount}</p></a>
				    	</MDBCol>
				 	</MDBRow>
				 	<MDBRow>
				    	<MDBCol md="9">
				    		<a href="#actionSummary" onClick={this.toggleServicesEndingSoon}><p>{t('activitysummary.expiring14days')}</p></a>
				    	</MDBCol>
				    	<MDBCol md="3">
				    		<a href="#actionSummary" onClick={this.toggleServicesEndingSoon}><p>{this.props.expiring}</p></a>
				    	</MDBCol>
				 	</MDBRow>
				</div>	
			</div>
			</>
		)

	}

}

export default withTranslation()(ActionSummary);