/* Component for logo */

import React from 'react';

let footerLogo;


class logo extends React.Component {

     render () {
if(this.props.darkMode==="false"){
     footerLogo="/common/SecuredByVaultBlack.png"
}    else{
          footerLogo="/common/SecuredByVaultBlue.png"
     }

    return(
      <div className="footerLogo first-step">
        <img src={footerLogo} alt="footerLogo" />
    </div>
    )
  }

}
export default logo;