import React, { Component } from "react";
import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBModal,
    toast,
    MDBProgress,
} from "mdbreact";
import CSVReader from "react-csv-reader";
import axios from "../../../../axios";

import SelectSearch, { fuzzySearch } from "react-select-search";

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    quotes: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};
class BulkUploadUnits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastProcessed: undefined,
        };
    }
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    formatzip(num) {
        var s = "00000" + num;
        return s.substr(s.length - 5);
    }
    convertCSV = async (data) => {
        this.setState({ loading_upload: true });
        this.setState({ modalError: false });
        this.setState({
            unitUnprocessed: undefined,
            buildingUnprocessed: undefined,
            floorUnprocessed: undefined,
            lastProcessed: undefined,
        });
        let dataImported = [];
        for (let i = 0; i < data.length; i++) {
                        dataImported[i] = {
                VenueID: this.props.venueID,
                UnitID: data[i]["unit_id_"].toString(),
                UnitFloor: data[i]["floor_"].toString(),
                UnitBuilding: data[i]["building_"].toString(),
                AddressLine1: data[i]["address_1_"].toString(),
                AddressLine2: data[i]["address_2"] === null ? "" : data[i]["address_2"].toString(),
                City: data[i]["city_"].toString(),
                State: data[i]["state_"].toString(),
                PostalCode: data[i]["country_"]==="USA"?this.formatzip(data[i]["postal_code_"]):data[i]["postal_code_"],
                "Country.ISO3166A3": data[i]["country_"],
                SharedSecret: this.props.sharedSecret,
                AccessToken: sessionStorage.getItem("AccessToken"),
                vlan: data[i]["vlan"],
                purpose: data[i]["purpose__wiredunit__localwirelessonly_or_globalroaming__"].toLowerCase(),
                ServicePlanID: data[i]["service_plan_id"],
                UnitID_internal: data[i]["unit_internal_id"],
                externalVenueUnitID: data[i]["unit_external_id"],
                ShoppingCartPriceAdjustment: data[i]["shopping_cart_price_adjustment"]
            };
            let unitID = data[i]["unit_id_"];
            let building = data[i]["building_"];
            let floor = data[i]["floor_"];
            await axios
                .post("/networkoperatorconsole/venues/addVenueUnits", dataImported[i], {
                    headers: {},
                })
                .then(
                    (response) => {
                        toast.success("Unit " + unitID + ", Building " + building + ", Floor " + floor + " processed", {
                            closeButton: false,
                        });
                        if(i + 1 === data.length){
                            this.props.updateName(this.state.venueName, this.props.venueID);
                        }
                    },
                    (error) => {
                                                this.setState({
                            unitUnprocessed: unitID,
                            buildingUnprocessed: building,
                            floorUnprocessed: floor,
                            lastProcessed: i + 1,
                        });
                        this.cancelUploadedFile();
                        i = data.length;
                        this.setState({ 
                            modalError: true,
                            errorMessage: error.response.data.message
                        });
                    }
                );
            this.setState({ progress: ((i + 1) / data.length) * 100 });
        }
        this.setState({ loading_upload: false });
    };
    cancelUploadedFile() {
        const randomString = Math.random().toString(36);
        this.setState({
            theInputKey: randomString,
        });
    }
    toggleError = () => {
        this.setState({
            modalError: !this.state.modalError,
        });
    };
    convertToCSV(objArray) {
        var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";
        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line !== "") line += ",";
                line += '"' + array[i][index] + '"';
            }
            str += line + "\r\n";
        }
        return str;
    }
    exportCSVFile(headersExport, items, fileTitle) {
        if (headersExport !== items[0]) {
            items.unshift(headersExport);
        }
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
        var csv = this.convertToCSV(jsonObject);
        var exportedFilename = "Unit_Upload_Template.csv";
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    render() {
        var headers = {
            unitID: "Unit ID*",
            unitBuilding: "Building*",
            unitFloor: "Floor*",
            purpose: "Purpose (wiredunit, localwirelessonly or globalroaming)*",
            AddressLine1: "Address 1*",
            SubscriberAddress2: "Address 2",
            SubscriberCity: "City*",
            SubscriberState: "State*",
            SubscriberPostalCode: "Postal Code*",
            SubscriberCountry: "Country*",
            VLAN: "VLAN",
            // ServicePlanID: "Service Plan ID",
            unitIDInternal: "Unit Internal ID",
            externalVenueUnitID: "Unit External ID",
            ShoppingCartPriceAdjustment: "Shopping Cart Price Adjustment"
        };
        var itemsFormatted = [];
        var fileTitle = sessionStorage.getItem("venueName"); // or 'my-unique-title'
        return (
            <>
                {this.state.lastProcessed > 0 ? (
                    <>
                        <MDBModal isOpen={this.state.modalError} toggle={this.toggle} size="md" className="form" centered>
                            <MDBModalHeader toggle={this.toggleError}>
                                <h4>Error Uploading Units</h4>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {this.state.lastProcessed === 1 ? (
                                    <p>
                                        Line {this.state.lastProcessed+1} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                                        {this.state.floorUnprocessed}) could not be processed. 
                                        <br/>Reason: {this.state.errorMessage}
                                        <br/>Re-upload the CSV after fixing line 2.
                                    </p>
                                ) : (
                                    <p>
                                        Line {this.state.lastProcessed+1} (Unit: {this.state.unitUnprocessed}, Building: {this.state.buildingUnprocessed}, Floor:{" "}
                                        {this.state.floorUnprocessed}) could not be processed. 
                                        <br/>Reason: {this.state.errorMessage}
                                        <br/>Re-upload the CSV after fixing line {this.state.lastProcessed + 1}{" "}
                                        and removing lines 2-{this.state.lastProcessed}.
                                    </p>
                                )}
                            </MDBModalBody>
                        </MDBModal>
                    </>
                ) : (
                    ""
                )}
                {this.state.loading_upload === true ? (
                    <div className="overlay" id="overlay" style={{ display: "block" }}>
                        <div className="overlayProgressBar">
                            <MDBProgress material value={this.state.progress} height="20px" color="danger" striped />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggleUploadUnits}>
                    <h4>Bulk Upload Units</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="venueSelector">
                        <MDBRow>
                            <MDBCol col="6" sm="12" lg="4">
                                <h5>Venue Name:</h5>
                            </MDBCol>
                            <MDBCol col="6" sm="12" lg="8">
                            <SelectSearch
                              id="selectVenue"
                              search={true}
                              filterOptions={fuzzySearch}
                              options={this.props.optionTemplateVenues}
                              isOptionSelected={true}
                              inputId="venueArray"
                              classNamePrefix="venue-select"
                              placeholder="Select..."
                              onChange={this.props.handleSelectVenue}
                              value={
                                this.props.clearDropDown === true
                                  ? ""
                                  : this.props.venueID
                              }
                              // value={this.props.venueID}
                            />
                            </MDBCol>
                        </MDBRow>
                        <br/>
                        <p>Please note, only CSV files are accepted for the upload. Fields marked with "*" in template are required.</p>
                    </div>
                    <div className="text-right submitButton" key={this.state.theInputKey || ""}>
                    <MDBBtn color="danger" onClick={() => this.exportCSVFile(headers, itemsFormatted, fileTitle)}>
                                                Download Template
                                            </MDBBtn>
                        <MDBBtn color="danger" disabled={this.props.clearDropDown}>
                            Upload Units
                            <CSVReader
                                cssClass="csv-reader-input"
                                onFileLoaded={this.convertCSV}
                                parserOptions={papaparseOptions}
                                inputId="csvVenueUnitUploadData"
                                inputStyle={{ color: "transparent" }}
                            />
                        </MDBBtn>
                        <MDBBtn color="primary" onClick={this.props.toggleUploadUnits}>
                            Close
                        </MDBBtn>
                    </div>
                </MDBModalBody>
            </>
        );
    }
}
export default BulkUploadUnits;