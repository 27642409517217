import React, { Component } from "react";

import {
	MDBBtn,
	MDBModalBody,
	MDBModalHeader,
	MDBRow,
	MDBCol
} from "mdbreact";

import { withTranslation } from "react-i18next";

class AddRadiusServer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: "",
			sms: false,
			voice: false,
			invalidPhone: null,
			loading: false,
			userFound: false,
		};
	}

	submitAddRadiusServer = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";

		if (this.state.ipAddy !== undefined && this.state.authPort !== undefined && this.state.acctPort !== undefined) {
			this.props.submitAddRadiusServer(this.state.ipAddy, this.state.authPort, this.state.acctPort);
		}
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.submitAddRadiusServer}>
					<h4>Add Radius Server</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" onSubmit={this.submitAddRadiusServer} noValidate>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									Server IP Address
								</label>
								<input required id="ipAddy" name="ipAddy" className="form-control" onChange={this.changeHandler} value={this.state.ipAddy} />
								<div className="invalid-feedback">Please provide a valid value.</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									Authentication Port
								</label>
								<input
									required
									name="authPort"
									id="authPort"
									className="form-control"
									onChange={this.changeHandler}
									value={this.state.authPort}
								/>
								<div className="invalid-feedback">Please provide a valid value.</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									Accounting Port
								</label>
								<input
									required
									className="form-control"
									name="acctPort"
									id="acctPort"
									onChange={this.changeHandler}
									value={this.state.acctPort}
								/>
								<div className="invalid-feedback">Please provide a valid value.</div>
							</MDBCol>
						</MDBRow>

						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.add_radius === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									"Add Radius Server"
								)}
							</MDBBtn>
							<MDBBtn color="primary" onClick={this.props.toggleAddRadiusServer}>
								Close
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddRadiusServer);