/* Find Deals screen */

import React, { Component, Suspense } from "react";

import axios from "../../axios";

import { isPossiblePhoneNumber } from "react-phone-number-input";

import "../../brands/common/css/common.scss";

import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";


let VerificationComponent;

let Logo;

let BrandName;

let supportPhoneNumber;

export const SUB_PAGES = brandsData.forEach(
	({ name, url, component_directory_name, brand_directory_name, custom_component_directory_name, main_logo, phone_number }) => {
		if (url.includes(window.location.hostname)) {
			import("../../brands/" + brand_directory_name + "/css/style.scss");
			VerificationComponent = React.lazy(() =>
				import(
					"../../brands/" +
                    custom_component_directory_name +
						"/components/CreateAccount/Verification"
				)
			);
			Logo = "/" + brand_directory_name + "/" + main_logo;
			BrandName = name;
            supportPhoneNumber = phone_number;
		}
	}
);

class Verification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNumber: sessionStorage.getItem("phoneNumber"),
			enterVerification: false,
            errorMessage: undefined,
            verifyingPhoneNumber: false
		};
	}

	sendSMSCode = (event) => {
		this.setState({ 
            smsCode: event.target.value
        });
        let data;
        if(event.target.value.length===4){
            this.setState({
                verifyingPhoneNumber: true
            })
            data = {
                "StripeClientSecret": sessionStorage.getItem("clientSecret"),
                "PhoneNumber.E164": sessionStorage.getItem("phoneNumber"),
                "SMSOTP": parseInt(event.target.value)
            }
            axios
            .post("/networkoperatorconsole/shoppingcart/verifyPhoneNumber", data, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    verifyingPhoneNumber: false
                })
                document.location.href="/paymentsuccess";
            })
            .catch((error) => {
                this.setState({
                    verifyingPhoneNumber: false
                })
                this.setState({
                    smsErrorMessage: "The code you entered does not match our records. Please re-enter the code."
                })
            });
        } else {
            this.setState({
                smsErrorMessage: undefined
            })
        }
        
	};

	changeHandlerPhone = (event) => {

		this.setState({ phoneNumber: event });
		var value = event
			? isPossiblePhoneNumber(event)
				? undefined
				: "Please provide a valid phone number"
			: "Phone number required";
		this.setState({ invalidPhone: value });
		sessionStorage.setItem("phoneNumber", event);
	};

    textCode = (event) => {
        event.preventDefault();
        let BillingCycleRecurrence;
        !sessionStorage.getItem("daysofservice") ? BillingCycleRecurrence = 0 : BillingCycleRecurrence = sessionStorage.getItem("daysofservice")
        let data = {
            "VenueID": sessionStorage.getItem("venueID"),
            "ServicePlanID": sessionStorage.getItem("servicePlanID"),
            "UnitID_internal": sessionStorage.getItem("unit"),
            "EmailAddress": sessionStorage.getItem("email"),
            "GivenName": sessionStorage.getItem("firstName"),
            "FamilyName": sessionStorage.getItem("lastName"),
            "PhoneNumber.E164": sessionStorage.getItem("phoneNumber"),
            "locale": "en-us",
            "contactpref_sms":sessionStorage.getItem("sms"),
            "StartDateTimeUTC.ISO8601": new Date(sessionStorage.getItem("dateStart")).toISOString(),
            "BillingCycleRecurrence":BillingCycleRecurrence
        }
        axios
        .post("/networkoperatorconsole/shoppingcart/requestSubscription", data, {
            headers: {},
        })
        .then((response) => {
            if(response.data.phone_number_verified){
                document.location.href="/paymentsuccess"
            }else{
                this.setState({
                    enterVerification: true,
                    smsCode: "",
                    smsErrorMessage: undefined
                });
                if(response.data.StripeClientSecret===undefined){
                    sessionStorage.setItem("clientSecret", response.data.SetupIntentId);
                }else{
                    sessionStorage.setItem("clientSecret", response.data.StripeClientSecret);
                }
            }
            
        })
        .catch((error) => {
            this.setState({
                errorMessage: error.response.data.message,
                smsCode: "",
                smsErrorMessage: undefined,
                enterVerification: false
            })
        });
    }

    toggle = () => {
        this.setState({
            enterVerification: !this.state.enterVerification
        });
      }

	render() {
		return (
			<>
                {this.state.errorMessage !== undefined ? (
                    <div className="overlay" style={{ display: "block" }}>
                        <div class="overlayMessage">
                        {this.state.errorMessage}
                        <div className="nextButton">
                            <br />
                            <a
                            className="btn Ripple-parent btn-default"
                            href="/"
                            rel="noreferrer"
                            onClick={this.handleAnchorClick}
                            >
                            Return to Login
                            </a>
                        </div>
                        </div>

                    </div>
                    ) : ""}
				<Suspense fallback={<div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>}>
					<VerificationComponent
                        changeHandlerPhone={this.changeHandlerPhone}
                        textCode={this.textCode}
                        enterVerification={this.state.enterVerification}
                        sendSMSCode={this.sendSMSCode}
                        smsCode={this.state.smsCode}
                        smsErrorMessage={this.state.smsErrorMessage}
                        toggle={this.toggle}
						logo={Logo}
						brand={BrandName}
                        supportPhoneNumber={supportPhoneNumber}
                        errorMessage={this.state.errorMessage}
                        verifyingPhoneNumber={this.state.verifyingPhoneNumber}
					/>
					<Footer />
				</Suspense>
			</>
		);
	}
}

export default Verification;