import { useEffect, useState } from "react";

import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBInput, MDBModalBody } from "mdbreact";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [agree, setAgree] = useState();

  const [agreeTandC, setAgreeTandC] = useState();

  const [state, setState] = useState({
    modalTandC: false,
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://gigstreem.wifikey.link/myaccount/upgrade/paymentsuccess",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  function toggleCheckboxValuePayment() {
    setAgree(!agree);
  }

  function toggleCheckboxValueTandC() {
    setAgreeTandC(!agreeTandC);
  }

  function toggleTandC() {
    setState({
      modalTandC: !state.modalTandC,
    });
  }
  return (
    <>
    <MDBModal
            isOpen={state.modalTandC}
            size="lg"
            className="form"
            centered
            overflowScroll
          >
            <MDBModalHeader toggle={toggleTandC}>
              GiGstreem Terms and Conditions
            </MDBModalHeader>
            <MDBModalBody className="faqModal" id="faq">
              <p>
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Ut pulvinar sollicitudin
                ligula, eleifend condimentum felis maximus at.
                Quisque molestie posuere tortor, non consectetur
                nisl sagittis et. In eu odio vitae ipsum
                ullamcorper cursus ut et lectus. Nulla convallis
                tortor in vestibulum porttitor. Donec auctor
                vulputate est, vitae sollicitudin neque pulvinar
                non. Sed a mauris eget lacus blandit tempor.
                Nunc a consequat tellus. Aliquam consequat quis
                purus quis scelerisque. Pellentesque ut eros in
                augue aliquam congue eget eu augue.
              </p>

              <p>
                Quisque lorem metus, mattis vel augue sed,
                dictum hendrerit dolor. Proin ac feugiat lorem,
                quis feugiat lacus. Donec suscipit lectus et
                libero euismod pharetra. Vestibulum ante ipsum
                primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Suspendisse rutrum, arcu
                eget feugiat semper, arcu nibh condimentum
                purus, sed fermentum enim elit vel nulla. Donec
                volutpat massa arcu, a efficitur felis consequat
                ac. Nullam sed orci a lorem venenatis consequat
                ac eu leo. Ut posuere elementum lobortis. Duis
                nunc nunc, accumsan eu tempus nec, molestie eget
                mi. Sed et justo non sapien imperdiet maximus
                quis dictum dui.
              </p>

              <p>
                Aenean sit amet volutpat magna. Pellentesque
                volutpat enim vel nulla sodales, id tincidunt
                diam egestas. Donec eleifend erat est, sit amet
                posuere orci lacinia ac. Suspendisse eget tempus
                tortor. Vestibulum metus sapien, accumsan vel
                nulla sed, dignissim pharetra ligula. Curabitur
                eget nibh id leo consectetur tincidunt ut ut
                dolor. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos
                himenaeos. Morbi quam risus, condimentum sed
                auctor nec, tincidunt sollicitudin sapien. Duis
                eget elementum est, scelerisque finibus erat.
                Nullam nec neque ut est vestibulum eleifend. Ut
                risus orci, sodales quis eros a, tincidunt
                lacinia dui. Donec sed nisl sit amet est
                volutpat bibendum. Sed sodales orci eget nunc
                pharetra, at consectetur ex egestas.
              </p>
              <button
                className="btn Ripple-parent btn-default nextButton"
                onClick={toggleTandC}
              >
                Close
              </button>
            </MDBModalBody>
          </MDBModal>
    <form id="payment-form" onSubmit={handleSubmit}>
      {!elements ? (
        <div id="overlay" style={{ display: "block" }}>
          <div class="overlayMessage">
            <div className="spinner-border spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <PaymentElement id="payment-element" />
          <MDBRow>
            <MDBCol col="12" className="leftAlign agree">
              <MDBInput
                label="I agree to monthly auto payment via the credit card provided above."
                type="checkbox"
                id="payment"
                name="payment"
                onChange={toggleCheckboxValuePayment}
                value={agree}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol col="12" className="leftAlign agree">
              <MDBInput
                          type="checkbox"
                          id="tandc"
                          name="tandc"
                          onChange={
                            toggleCheckboxValueTandC
                          }
                          value={agreeTandC}
                        />
                        <p className="checkboxLabel">
                          I agree to the billing{" "}
                          <button
                            onClick={
                              toggleTandC
                            }
                          >
                            Terms and Conditions
                          </button>
                        </p>
            </MDBCol>
          </MDBRow>
          <div className="backButton">
            <a className="btn Ripple-parent btn-default" href="/myaccount/upgrade">
              Back
            </a>
          </div>
          <div className="nextButton">
            <button
              disabled={isLoading || !stripe || !elements || !agree || !agreeTandC}
              id="submit"
              className="btn Ripple-parent btn-default"
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Charge My Card"
                )}
              </span>
            </button>
          </div>

          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </>
      )}
    </form>
    </>
  );
}